import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "chat",
  initialState: {
    newChatInfo: null,
    channels: [],
    broadcastChannels: [],
    newMessageCount: 0,
    isFirstTimeGetChannelsSuccess: false,
    isSendContactMessage: false,
    globalChatTab: "",
  },
  reducers: {
    setNewChatInfo(state, action) {
      state.newChatInfo = action.payload.newChatInfo;
    },
    setChannels(state, action) {
      state.channels = action.payload.channels;
    },
    setBroadcastChannels(state, action) {
      state.broadcastChannels = action.payload.broadcastChannels;
    },
    setNewMessageCount(state, action) {
      state.newMessageCount = action.payload.newMessageCount;
    },
    setIsFirstTimeGetChannelsSuccess(state, action) {
      state.isFirstTimeGetChannelsSuccess = action.payload;
    },
    setIsSendContactMessage(state, action) {
      state.isSendContactMessage = action.payload;
    },
    setGlobalChatTab(state, action) {
      state.globalChatTab = action.payload;
    },
  },
});

export const {
  setNewChatInfo: setNewChatInfoAction,
  setChannels: setChannelsAction,
  setNewMessageCount: setNewMessageCountAction,
  setIsFirstTimeGetChannelsSuccess: setIsFirstTimeGetChannelsSuccessAction,
  setIsSendContactMessage: setIsSendContactMessageAction,
  setBroadcastChannels: setBroadcastChannelsAction,
  setGlobalChatTab: setGlobalChatTabAction,
} = slice.actions;

export const setNewChatInfo = (newChatInfo) => async (dispatch) => {
  dispatch(setNewChatInfoAction({ newChatInfo }));
};

export const setReduxChannels = (channels) => async (dispatch) => {
  dispatch(setChannelsAction({ channels }));
};

export const setBroadcastChannels = (broadcastChannels) => async (dispatch) => {
  dispatch(setBroadcastChannelsAction({ broadcastChannels }));
};

export const setNewMessageCount = (newMessageCount) => async (dispatch) => {
  dispatch(setNewMessageCountAction({ newMessageCount }));
};

export const setIsFirstTimeGetChannelsSuccess =
  (isSuccess) => async (dispatch) => {
    dispatch(setIsFirstTimeGetChannelsSuccessAction(isSuccess));
  };

export const setIsSendContactMessage =
  (isSendContactMessage) => async (dispatch) => {
    dispatch(setIsSendContactMessageAction(isSendContactMessage));
  };

export const setGlobalChatTab = (tab) => async (dispatch) => {
  dispatch(setGlobalChatTabAction(tab));
};

export default slice.reducer;
