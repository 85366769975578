import {
  normalizeObjectData,
  queryObjSerialize,
  formatEmptyData,
  sanitizeEmptyArray,
} from "@utils/index";
import qs from "qs";
import { axiosInstance } from "services";
import toastr from "toastr";
import {
  MY_PRODUCT_TOAST_MESSAGE_ENUM,
  SEARCH_PRODUCES_PARAMS,
} from "@constants";

export const createDemandProduces = async (body) => {
  try {
    const normalizedBody = normalizeObjectData(body);
    const result = await axiosInstance.post("demand-produces", normalizedBody);
    if (result?.status === 201) {
      toastr.success("Demand created successfully.")
      return {
        isSuccess: true,
        data: result?.data,
      };
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.errors?.[0]?.message || error.message || error);
  }
};

export const getDetailDemandProduces = async (demandProduceId) => {
  try {
    const result = await axiosInstance.get(
      `/my-demand-produces/${demandProduceId}`
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.errors?.[0]?.message || error.message || error);
  }
};

export const getMyDemandProduces = async (queryParams) => {
  try {
    const result = await axiosInstance.get("my-demand-produces", {
      params: {
        ...queryParams,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getDemandedProducts = async (queryParams) => {
  try {
    const result = await axiosInstance.get("demand-produces", {
      params: {
        ...queryParams,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getListUserDemandProduceCountryOrigin = async (params) => {
  try {
    let result = await axiosInstance.get(
      "/my-demand-produce-origin-countries",
      {
        params: params ? params : {},
      }
    );
    if (result?.status === 200) return result?.data?.items;
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
  }
};

export const deleteDemandProduce = async (demandProduceId) => {
  try {
    const result = await axiosInstance.delete(
      `demand-produces/${demandProduceId}`
    );

    if (result?.status === 204) {
      toastr.success("You have successfully deleted your demand product!");
      return {
        isSuccess: true,
      };
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const editDemandProduce = async (demandProduceId, body) => {
  try {
    const normalizedBody = normalizeObjectData(body);

    const result = await axiosInstance.put(
      `/demand-produces/${demandProduceId}`,
      normalizedBody
    );

    if (result?.status === 200) {
      toastr.success(MY_PRODUCT_TOAST_MESSAGE_ENUM.EDIT_PRODUCE);
      return {
        isSuccess: true,
        data: result?.data,
      };
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const getListDemandProduceCountryOrigin = async (params) => {
  try {
    let result = await axiosInstance.get("/demand-produce-origin-countries", {
      params: params ? params : {},
    });
    if (result?.status === 200) return result?.data?.items;
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
  }
};

export const getDemandProducesMarket = async (queryParams) => {
  try {
    let result;
    const formatedQueryParams = formatEmptyData(queryParams);

    delete formatedQueryParams?.totalPage;
    delete formatedQueryParams?.total;

    if (
      Object.keys(queryParams).some((item) =>
        SEARCH_PRODUCES_PARAMS.includes(item)
      )
    ) {
      result = await axiosInstance.post(
        `/search-demand-produces`,
        sanitizeEmptyArray(formatedQueryParams)
      );
    } else {
      let queryStrings = queryObjSerialize(queryParams);
      result = await axiosInstance.post(`/search-demand-produces?${queryStrings}`);
    }
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const disableDemandProduce = async (demandProduceId) => {
  try {
    const result = await axiosInstance.put(
      `/demand-produces/${demandProduceId}/disable`
    );

    if (result?.status === 200) {
      return {
        isSuccess: true,
        data: result?.data,
      };
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const enableDemandProduce = async (demandProduceId) => {
  try {
    const result = await axiosInstance.put(
      `/demand-produces/${demandProduceId}/enable`
    );

    if (result?.status === 200) {
      return {
        isSuccess: true,
        data: result?.data,
      };
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const getAutoCompleteDemands = async (queryParams) => {
  try {
    const result = await axiosInstance.post(
      "autocomplete-demand-produces",
      sanitizeEmptyArray(queryParams)
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const getTopDemandProducesByCompany = async ( companyId, top ) => {
  try {
    const result = await axiosInstance.get(
      `/companies/${companyId}/top-demand-produces?top=${top}`
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};
