import { queryObjSerialize } from "@utils/index";
import { axiosInstance } from "services";
import toastr from "toastr";
import qs from "qs";
import { MY_PRODUCT_TOAST_MESSAGE_ENUM } from "@constants";

export const getProduces = async (queryParams) => {
  try {
    // let queryStrings = queryObjSerialize(queryParams);
    const result = await axiosInstance.get(
      `/companies/${queryParams.companyId}/produces`,
      {
        params: {
          ...queryParams,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      }
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getMyProduces = async (queryParams) => {
  try {
    // let queryStrings = queryObjSerialize(queryParams);
    const { companyId, ...restQueryParams } = queryParams;
    const result = await axiosInstance.get(
      `/companies/${companyId}/my-produces`,
      {
        params: {
          ...restQueryParams,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      }
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const searchUserConnections = async (queryParams) => {
  try {
    let queryStrings = queryObjSerialize(queryParams);
    const result = await axiosInstance.get(
      `/search-user-connections?${queryStrings}`
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const getMyProducesGroupedByAddress = async (queryParams) => {
  try {
    // let queryStrings = queryObjSerialize(queryParams);
    const { companyId, ...restQueryParams } = queryParams;
    const result = await axiosInstance.get(
      `/companies/${companyId}/group-address-my-produces`,
      {
        params: {
          ...restQueryParams,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      }
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const toggleProduce = async (companyId, produceId) => {
  try {
    if (!companyId || !produceId)
      throw {
        message: "Missing companyId or produceId",
      };
    const result = await axiosInstance.put(
      `/companies/${companyId}/produces/${produceId}/toggle-available`
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const addProduce = async (companyId, data) => {
  try {
    const { isDonate } = data;
    const result = await axiosInstance.post(
      `/companies/${companyId}/produces`,
      data
    );
    if (result?.status === 200) {
      if (isDonate) {
        toastr.success(MY_PRODUCT_TOAST_MESSAGE_ENUM.LIST_FOR_DONATION);
      } else {
        toastr.success(MY_PRODUCT_TOAST_MESSAGE_ENUM.CREATE_PRODUCE);
      }
      return {
        isSuccess: true,
        data: result?.data,
      };
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.errors?.[0]?.message || error.message || error);
  }
};

export const getProduce = async ({ companyId, produceId, data }) => {
  try {
    const result = await axiosInstance.get(
      `/companies/${companyId}/produces/${produceId}`
    );
    if (result?.status === 200) {
      return result.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.errors?.[0]?.message || error.message || error);
  }
};

export const editProduce = async (companyId, productId, data) => {
  try {
    const { isDonate } = data;
    const result = await axiosInstance.put(
      `/companies/${companyId}/produces/${productId}`,
      data
    );
    if (result?.status === 200) {
      if (isDonate) {
        toastr.success(MY_PRODUCT_TOAST_MESSAGE_ENUM.LIST_FOR_DONATION);
      } else {
        toastr.success(MY_PRODUCT_TOAST_MESSAGE_ENUM.EDIT_PRODUCE);
      }
      return {
        isSuccess: true,
        data: result?.data,
      };
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.errors?.[0]?.message || error.message || error);
  }
};

export const deleteProduce = async (companyId, productId) => {
  try {
    const result = await axiosInstance.delete(
      `/companies/${companyId}/produces/${productId}`
    );
    if (result?.status === 204) {
      toastr.success(MY_PRODUCT_TOAST_MESSAGE_ENUM.DELETE_PRODUCE);
      return {
        isSuccess: true,
        data: result?.data,
      };
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.errors?.[0]?.message || error.message || error);
  }
};
