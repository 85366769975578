import { INVOICE_ACTION_TYPE_ENUM } from "@constants";
import { queryObjSerialize } from "@utils/index";
import { showInvoiceToastMessage } from "@utils/toastMessage";
import { axiosInstance } from "services";
import toastr from "toastr";

export const getListBuyer = async (queryParams) => {
  try {
    let queryStrings = queryObjSerialize(queryParams);
    const result = await axiosInstance.get(`/offer-buyers?${queryStrings}`);
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const createInvoice = async (offerId, body) => {
  try {
    const result = await axiosInstance.post(
      `/offers/${offerId}/invoices`,
      body
    );
    if (result?.status === 200) {
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.errors?.[0]?.message || error.message || error);
  }
};

export const getPdfInvoices = async (offerId) => {
  try {
    const result = await axiosInstance.get(
      `/offers/${offerId}/check-pdf-invoices`
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const updateInvoice = async (offerId, invoiceId, body) => {
  try {
    const result = await axiosInstance.put(
      `/offers/${offerId}/invoices/${invoiceId}`,
      body
    );
    if (result?.status === 204) {
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.errors?.[0]?.message || error.message || error);
    return error;
  }
};

export const paidInvoice = async (offerId, invoiceId, invoice) => {
  try {
    const result = await axiosInstance.put(
      `/offers/${offerId}/invoices/${invoiceId}/paid`
    );
    if (result?.status === 204) {
      showInvoiceToastMessage(INVOICE_ACTION_TYPE_ENUM.paid, {
        invoice: invoice,
      });
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const deleteInvoice = async (offerId, invoiceId, invoice) => {
  try {
    const result = await axiosInstance.delete(
      `/offers/${offerId}/invoices/${invoiceId}`
    );
    if (result?.status === 204) {
      showInvoiceToastMessage(INVOICE_ACTION_TYPE_ENUM.delete, {
        invoice: invoice,
      });
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getPurchaseInvoices = async (queryParams) => {
  try {
    let sanitizedQueries = { ...queryParams };
    sanitizedQueries.status = sanitizedQueries.activeTab;

    if (sanitizedQueries.createdAtFrom)
      sanitizedQueries.deliveryDateFrom = sanitizedQueries.createdAtFrom;
    if (sanitizedQueries.createdAtTo)
      sanitizedQueries.deliveryDateTo = sanitizedQueries.createdAtTo;
    if (sanitizedQueries.activeTab === "ALL") delete sanitizedQueries.status;
    if (sanitizedQueries.activeTab === "UNPAID")
      sanitizedQueries.status = "ISSUED";

    delete sanitizedQueries.activeTab;
    delete sanitizedQueries.createdAtFrom;
    delete sanitizedQueries.createdAtTo;

    let queryStrings = queryObjSerialize(sanitizedQueries);
    const result = await axiosInstance.post(`/purchase-invoices`, {
      ...queryParams,
    });
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const exportInvoices = async (companyId, queryParams) => {
  try {
    let sanitizedQueries = { ...queryParams };
    sanitizedQueries.status = sanitizedQueries.activeTab;

    if (sanitizedQueries.createdAtFrom)
      sanitizedQueries.deliveryDateFrom = sanitizedQueries.createdAtFrom;
    if (sanitizedQueries.createdAtTo)
      sanitizedQueries.deliveryDateTo = sanitizedQueries.createdAtTo;
    if (sanitizedQueries.activeTab === "ALL") delete sanitizedQueries.status;

    delete sanitizedQueries.activeTab;
    delete sanitizedQueries.createdAtFrom;
    delete sanitizedQueries.createdAtTo;
    delete sanitizedQueries.limit;
    delete sanitizedQueries.page;
    delete sanitizedQueries.total;
    delete sanitizedQueries.totalPage;
    delete sanitizedQueries.order;

    let queryStrings = queryObjSerialize(sanitizedQueries);

    const response = await axiosInstance.get(
      `/companies/${companyId}/export-invoices?${queryStrings}`,
      {
        responseType: "arraybuffer",
      }
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const forwardInvoice = async (invoiceId, email) => {
  try {
    const data = {
      invoiceId: invoiceId,
      to: email,
    };

    const { ...rest } = data;
    const response = await axiosInstance.post("/invoices/email", { ...rest });

    if (response) {
      toastr.success("Invoice sent this email successfully.");
      return response.data;
    }
  } catch (error) {
    toastr.error(error?.message || error);
    console.error(error);
  }
};

export const createCreditNote = async (invoiceId, amount) => {
  try {
    const data = {
      invoiceId: invoiceId,
      amount: amount,
    };

    const { ...rest } = data;
    const response = await axiosInstance.post("/credit-notes/create", {
      ...rest,
    });

    if (response) {
      toastr.success("Credit note is created successfully.");
      return response.data;
    }
  } catch (error) {
    toastr.error(error?.message || error);
    console.error(error);
  }
};

export const deleteCreditNote = async (invoiceId) => {
  try {
    const response = await axiosInstance.post("/credit-notes/remove", {
      invoiceId,
    });

    if (response) {
      toastr.success("Credit note is deleted successfully.");
      return response.data;
    }
  } catch (error) {
    toastr.error(error?.message || error);
    console.error(error);
  }
};

export const uploadPremadeInvoice = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axiosInstance.post(
      "/premade-invoices/upload-an-invoice",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response) {
      toastr.success("Invoice is uploaded successfully.");
      return response.data;
    }
  } catch (error) {
    toastr.error(error?.message || error);
    console.error(error);
  }
};
