import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  InputAdornment,
  TextField,
  IconButton,
  DialogActions,
  withStyles,
  Typography,
  Button,
  CircularProgress,
  FormLabel,
} from "@material-ui/core";
import { FruProButton, FruProEmptyData } from "@components";
import Slider from "@mui/material/Slider";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { updateTextPost } from "@services/amityEndPoints";
import { marksSlider } from "./constants";
import TextAreaEditor from "./TextAreaEditor";
import { stateToHTML } from "draft-js-export-html";
import { EditorState } from "draft-js";

const PrettoSlider = withStyles({
  root: {
    color: "#52af77",
    height: 2,
  },
  thumb: {
    color: "#E77228",
    background: "#E77228",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(2px)",
    height: 24,
    width: 24,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },

  valueLabel: {
    left: "calc(50% - 16px)",
    color: "#E77228",
    fontSize: "10px",
  },
  active: {},
  track: {
    height: 2,
    color: "#E77228",
  },
  rail: {
    height: 2,
    color: "#F1F1F1",
  },
})(Slider);

const useStyles = makeStyles((theme) => ({
  sliderBox: {
    marginLeft: 35,
  },
  dialogHeader: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 40px",
  },
  dialogContent: {
    width: 560,
    height: "auto",
    overflowY: "auto",
    padding: 0,
    paddingLeft: 16,
  },
  contentRows: {
    padding: 20,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },

  dialogTitle: {
    padding: "16px",
  },
  resetBtn: {
    padding: 0,
    minWidth: "unset",
    marginLeft: 8,
  },
  nextButton: {
    padding: "12px 24px",
    fontSize: "17px !important",
    fontWeight: 500,
    color: "white",
    backgroundColor: "#E77228 !important",
    borderRadius: "20px !important",
    minWidth: "235px !important",
    maxHeight: 44,
  },
  demandNextButton: {
    padding: "12px 24px",
    fontSize: "17px !important",
    fontWeight: 500,
    color: "white",
    backgroundColor: "#E77228 !important",
    borderRadius: "20px !important",
    minWidth: "235px !important",
    maxHeight: 44,
  },
  button: {
    padding: "12px 24px",
    fontSize: "17px !important",
    fontWeight: 500,
    color: "#E77228",
    borderRadius: "20px !important",
    minWidth: "235px !important",
    maxHeight: 44,
  },
  cancelButton: {
    background: "#ffffff",
    border: `1px solid #E77228`,
    color: "#E77228",
    textTransform: "capitalize",
    marginRight: 8,
  },
  demandCancelButton: {
    background: "#ffffff",
    border: `1px solid #E77228`,
    color: "#E77228",
    textTransform: "capitalize",
    marginRight: 8,
  },
  searchContainer: {
    alignContent: "center !important",
    alignItems: "center !important",
  },
  searchButton: {
    backgroundColor: "#E77228",
    width: 65,
    height: 50,
    borderRadius: 35,
  },
  demandSearchButton: {
    backgroundColor: "#E77228",
    width: 65,
    height: 50,
    borderRadius: 35,
  },
  produceCard: {
    display: "flex",
    gap: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: 480,
    height: 65,
    borderRadius: 10,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  },
  Input: {
    borderRadius: 20,
    border: "1px solid #E77228",
    backgroundColor: "white",
  },
  textAreaInput: {
    borderRadius: 20,
    width: "500px",
    position: "relative",
    border: "1px solid #E77228",
    backgroundColor: "white",
    overflowY: "auto",
    height: 200,
    marginLeft:10,
    maxHeight: 200,
    padding: "10px",
  },
}));

const PreviewOfferPopUp = ({
  editedPostText,
  editorState,
  setEditorState,
  setEditedPostText,
  sliderTextValue,
  sliderTextTextValue,
  setSliderTextValue,
  setOfferDemandPostText,
  openUpdateTextPostPopup,
  setOpenUpdateTextPostPopup,
  setIsUpdate,
  isUpdate,
  postId,
  setPostId,
  globalFeedData,
  setGlobalFeedData,
}) => {
  const classes = useStyles();

  const user = useSelector((state) => state.auth.user);

  const editorRef = useRef(null);

  const onUpdate = async () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentAsText = currentContent.getPlainText();
    const currentContentAsHtml = stateToHTML(currentContent);
    const response = await updateTextPost(
      postId,
      user,
   currentContentAsText,
   currentContentAsHtml,
      sliderTextTextValue.label === "This post will not expire"
        ? null
        : sliderTextTextValue.label
    );

    if (response) {
      setSliderTextValue(16);
      const index = globalFeedData?.posts.findIndex(
        (item) => item?.postId === postId
      );

      let newPosts = { ...globalFeedData };

      const updatedPost = newPosts?.posts;

      updatedPost[index] = response?.posts[0];
setEditorState(EditorState.createEmpty())
      setGlobalFeedData(newPosts);
      setOpenUpdateTextPostPopup(false);
    }
  };
  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: 30, border: "1px solid #E77228" },
      }}
      scroll="body"
      open={openUpdateTextPostPopup}
      //onClose={handleCloseDialog}
      maxWidth="sm"
    >
      <Box className={classes.dialogHeader}>
        <Typography
          variant="h6"
          style={{
            color: "#E77228",
            fontWeight: 600,
          }}
          align="start"
        >
          Update Post
        </Typography>
      </Box>
      <DialogContent className={classes.dialogContent}></DialogContent>

      <Box>
      <div
          className={classes.textAreaInput}
          onClick={() => editorRef.current.focus()}
        >
          <TextAreaEditor
            editorState={editorState}
            setEditorState={setEditorState}
            editorRef={editorRef}
            placeholder="Add Text"
          />
        </div>

        <div
          className={` ${classes.sliderBox} col-12 d-flex mt-4 justify-content-start position-relative`}
        >
          <FormLabel
            style={{
              fontSize: 12,
              color: "#000000",
              position: "absolute",
            }}
          >
            How long will this post remain available?
          </FormLabel>
          <div className={"mt-4"}>
            <PrettoSlider
              aria-label="Custom marks"
              style={{ width: 350 }}
              value={sliderTextValue}
              onChange={(e) => setSliderTextValue(e.target.value)}
              defaultValue={16}
              max={16}
              step={null}
              marks={marksSlider}
            />
          </div>
          <span style={{ fontSize: 10, marginRight: 10 }}>
            {sliderTextTextValue?.label}
          </span>
        </div>

        <DialogActions style={{ marginRight: 25 }}>
          <Button
            onClick={() => setOpenUpdateTextPostPopup(false)}
            className={clsx(classes.button, classes.cancelButton)}
            color="primary"
          >
            Cancel
          </Button>
          <FruProButton
            onClick={onUpdate}
            disabled={!editorState}
            className={clsx(classes.nextButton)}
            color="primary"

            //disabled={!selecteds?.length || shareLoading}
          >
            Update Post
          </FruProButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default PreviewOfferPopUp;
