import React from "react";
import { CircularProgress, Backdrop } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

export default function LoadingIndicator({ loading, loadingText }) {
  const useStyles = makeStyles((theme) =>
    createStyles({
      backdrop: {
        display: "flex",
        flexDirection: "column",
        gap: 5,
        alignItems: "center",
        zIndex: 9999999999999,
        backgroundColor: "rgba(255, 255, 255, 0.5)",
      },
    })
  );

  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress />
      {loadingText ? <span>{loadingText}</span> : null}
    </Backdrop>
  );
}
