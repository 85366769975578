import { Button, withStyles, makeStyles, FormLabel } from "@material-ui/core";
import React from "react";
import { Modal } from "react-bootstrap";
import Slider from "@mui/material/Slider";
import { marksSlider } from "./constants";

const useStyles = makeStyles({
  sliderBox: {
    marginLeft: 35,
  },
  shareButton: {
    width: "100%",
    backgroundColor: "#e77228",
    border: "none",
    color: "white",
    borderRadius: 20,
  },
  closeButton: {
    backgroundColor: "#e77228",
    border: "1px solid #e77228",
    width: "100%",
    color: "white",
    borderRadius: 20,
    "&:hover": {
      backgroundColor: "#e77228",
      border: "1px solid #e77228",
      width: "100%",
      color: "white",
      borderRadius: 20,
    },
  },
  customLikeModal: {
    borderRadius: "30px",
    border: "1px solid #E77228",
  },
});

const PrettoSlider = withStyles({
  root: {
    color: "#52af77",
    height: 2,
  },
  thumb: {
    color: "#E77228",
    background: "#E77228",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(2px)",
    height: 24,
    width: 24,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },

  valueLabel: {
    left: "calc(50% - 16px)",
    color: "#E77228",
    fontSize: "10px",
  },
  active: {},
  track: {
    height: 2,
    color: "#E77228",
  },
  rail: {
    height: 2,
    color: "#F1F1F1",
  },
})(Slider);

const WaitForApprovalModal = ({ openModal, setOpenModal }) => {
  const classes = useStyles();
  return (
    <Modal
      contentClassName={classes.customLikeModal}
      show={openModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
   
      <Modal.Body>
        <span>
          Your company is pending to be verified as buyer. Please wait until you
          become a verified buyer
        </span>
      </Modal.Body>
      <div className="container p-3">
        <div className="row d-flex justify-content-end">
          <div className="col-6 ">
            <Button
              className={classes.closeButton}
              onClick={() => setOpenModal(false)}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WaitForApprovalModal;
