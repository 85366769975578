import React, { useState, useEffect } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useSelector, useDispatch, Provider } from "react-redux";

import moment from "moment";
import { product } from "platform";
import CompanyNotRegisteredModal from "@components/CompanyNotRegisteredModal";
const DemandPostItem = ({
  setRenderType,
  demandCompany,
  setIsPressedOfferPostMakeOffer,
  onNavigateLogin,
  setMakeOfferBuyerCompanyId,
  demand,
  index,
  isExpired,
  length,
  setOpenOfferDemandPostDialog,
}) => {
  const useStyles = makeStyles((theme) => ({
    postsContent: {
      margin: "0 48px !important ",
    },
    sectionHeader: {
      padding: "20px 16px",
      borderRadius: "22px 22px 0 0",
      marginBottom: 12,
    },
    sectionTitle: {
      fontSize: 25,
      fontWeight: "bold",
    },
    sectionBody: {
      width: "100%",
      position: "relative",
      "& th:first-child, td:first-child": {
        paddingLeft: 0,
      },
      "& .custom-header": {
        "& th:first-child": {
          paddingLeft: 16,
        },
      },
      "& .detail-description": {
        paddingLeft: 16,
        "& p": {
          fontSize: 18,
        },
      },
    },
    textContent: {
      fontSize: 18,
    },
    absoluteTag: {
      width: "100%",
      position: "relative",
      top: "50%",
      height: 0,
      padding: "0 12px",
      color: "white",
      fontSize: 15,
      height: 30,
      zIndex: 1,
      borderRadius: "3px 3px 3px 0",
      borderBottom: "30px solid rgba(52,102,87,0.8)",
      borderRight: "15px solid transparent",
      "& div": {
        paddingTop: 4,
      },
    },
    triangleDown: {
      position: "absolute",
      top: "calc(50% + 15px)",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderWidth: "0 4px 6px 0",
      borderColor: `transparent #346657 transparent transparent`,
    },
    rotate180: {
      transform: "rotate(-180deg)",
    },
    contactBtn: {
      margin: "0 !important",
      width: 125,
    },
    customLabel: {
      fontSize: 18,
      fontWeight: "bold",
    },
    headerText: {
      fontWeight: 700,
      fontSize: 16,
    },
    demandContent: {
      marginTop: 20,
      display: "flex !important",
      justifyContent: "flex-start !important",
      flexDirection: "row",
    },
    demandContainer: {
      marginLeft: 100,
      display: "flex",
      alignContent: "center",
      flexWrap: "wrap",
      textAlign: "center",
      justifyContent: "space-between",
      gap: 50,
    },
    demandContainer2: {},
    demandText: {
      fontSize: 17,
      fontWeight: 600,
      color: "#727477",
    },
    demandContentText: {
      fontSize: 15,
      fontWeight: 500,
      color: "#151B22",
      textOverflow: "ellipsis",
      wordWrap: "break-word",
    },
    contactBtn: {
      margin: "0 !important",
      width: 125,
    },
    description: {
      marginTop: 30,
      marginLeft: 260,
      alignItems: "center",
      display: "flex",
    },
    descriptionText: { fontSize: 17, fontWeight: 600, color: "#727477" },
    descriptionContentText: {
      fontSize: 15,
      fontWeight: 500,
      color: "#151B22",
      marginLeft: 5,
    },
    nameCatText: {
      color: "#000",
      fontWeight: 600,
      fontSize: 16,
    },
    nameCatTextWithMargin: {
      color: "#000",
      marginLeft: 5,
      fontWeight: 600,
      fontSize: 16,
    },
    timeLine: {
      textOverflow: "ellipsis",
      wordWrap: "break-word",
      marginLeft: 5,
      color: "#ABABAB",
      fontWeight: 400,
      fontSize: 16,
    },
    location: {
      maxWidth: "75%",
      textOverflow: "ellipsis",
      wordWrap: "break-word",
      marginLeft: 5,
      color: "#ABABAB",
      fontWeight: 400,
      fontSize: 16,
    },
    demandIconBox: {
      gap: 175,
    },
    makeOfferInactive: {
      position: "absolute",
      top: 10,
      right: 0,
      display: " flex",
      borderRadius: 10,
      color: "white",
      cursor: "pointer",
      background: "#737373",
      width: "164px",
      height: "35px",
      fontWeight: 600,
      padding: "6px 0px",
      justifyContent: "center",
      alignItems: "center",
    },
    makeOffer: {
      position: "absolute",
      top: 10,
      right: 0,
      display: " flex",
      borderRadius: 10,
      color: "white",
      cursor: "pointer",
      background: "#69CDAE",
      width: "164px",
      height: "35px",
      fontWeight: 600,
      padding: "6px 0px",
      justifyContent: "center",
      alignItems: "center",
    },
  }));
  const user = useSelector((state) => state?.auth?.user);

  const [openCompanyNotRegistered, setOpenCompanyNotRegistered] =
    useState(false);
  const classes = useStyles();

  const onPressMakeOffer = () => {
    setIsPressedOfferPostMakeOffer(false);
    setRenderType("yes");
    setMakeOfferBuyerCompanyId(demandCompany);
    setOpenOfferDemandPostDialog(true);
  };

  return (
    <>
      <Box className={classes.sectionBody}>
        {/* <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <img
            style={{ width: 50, height: 50, borderRadius: "100%" }}
            src={demand?.user?.photo?.url}
          />
          <Typography className={classes.headerText}>
            {demand?.companyName}
          </Typography>
        </Box> */}

        <Box className={classes.demandContent}>
          <Box>
            <img
              style={{ width: 120, height: 120, borderRadius: 20 }}
              src={demand?.photos?.[0]?.thumbUrl}
            />
          </Box>

          <Box display="flex" flexDirection="column">
            <Box>
              <Box
                paddingLeft="20px"
                display="flex"
                flexDirection="row"
                gap="5px"
              >
                <Typography className={classes.nameCatText}>
                  {demand.name} {demand.name ? "/" : null}{" "}
                </Typography>
                <Typography className={classes.nameCatTextWithMargin}>
                  {demand.category} {demand.devOrCol ? "/" : null}
                </Typography>
                <Typography className={classes.nameCatTextWithMargin}>
                  {demand.devOrCol}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-start"
              className={classes.demandIconBox}
              marginTop="15px"
              marginLeft="16px"
            >
              <Box display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row" alignItems="center">
                  <img src="/static/svg/Date_range_post_demand.svg" />
                  <span className={classes.timeLine}>
                    {moment(demand.timeline.from).format("DD/MM/YYYY")} -{" "}
                    {moment(demand.timeline.from).format("DD/MM/YYYY")}
                  </span>
                </Box>
                {demand?.location?.city?.name ||
                demand?.location?.state?.name ||
                demand?.location?.country?.name ? (
                  <Box
                    display="flex"
                    marginTop="10px"
                    flexDirection="row"
                    alignItems="center"
                  >
                    {" "}
                    <img src="/static/svg/Address Managment_post_demand.svg" />
                    <span className={classes.location}>
                      {demand?.location?.city?.name},
                      {demand?.location?.state.name},
                      {demand?.location?.country.name}
                      {}
                    </span>
                  </Box>
                ) : null}
              </Box>
              <Box display="flex" flexDirection="column">
                {demand?.countryOfOrigin?.name ? (
                  <Box display="flex" flexDirection="row">
                    {" "}
                    <img src="/static/svg/Pin_light_post_demand.svg" />
                    <span className={classes.timeLine}>
                      {demand?.countryOfOrigin?.name}
                    </span>
                  </Box>
                ) : null}
                {demand.quantity || demand.unit?.name ? (
                  <Box
                    display="flex"
                    marginTop="10px"
                    flexDirection="row"
                    alignItems="center"
                  >
                    {" "}
                    <img src="/static/svg/Chart_light_post_demand.svg" />
                    <span className={classes.timeLine}>
                      {demand.quantity} {demand.unit?.name}
                    </span>
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Box>
        {demand.description ? (
          <Box marginTop="25px" fontWeight="400" marginLeft="10px">
            {demand.description}
          </Box>
        ) : null}

        {user?.companyId !== demand?.company && index == 0 ? (
          <Box
            onClick={() =>
              !user?.id
                ? onNavigateLogin()
                : user?.id && !user?.companyId
                ? setOpenCompanyNotRegistered(true)
                : !isExpired && onPressMakeOffer()
            }
            className={
              isExpired ? classes.makeOfferInactive : classes.makeOffer
            }
          >
            Make an offer
          </Box>
        ) : null}
      </Box>
      <CompanyNotRegisteredModal
        setOpenModal={setOpenCompanyNotRegistered}
        openModal={openCompanyNotRegistered}
      />
    </>
  );
};

export default DemandPostItem;
