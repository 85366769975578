import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  TextField,
  DialogActions,
  Typography,
  Button,
  withStyles,
  FormLabel,
} from "@material-ui/core";
import { marks, marksSlider } from "./constants";
import { FruProButton, FruProEmptyData } from "@components";

import Slider from "@mui/material/Slider";
import toastr from "toastr";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { sendConsigmentOffer } from "@services/offer";
import { set } from "lodash";
import LoadingIndicator from "@components/LoadingIndicator";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
  },
  dialogContent: {
    width: "100%",
    height: "auto",
    overflowY: "auto",
    padding: "20px 0px",
  },
  contentRows: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },

  dialogTitle: {
    padding: "16px",
  },
  resetBtn: {
    padding: 0,
    minWidth: "unset",
    marginLeft: 8,
  },
  nextButton: {
    padding: "12px 24px",
    fontSize: "17px !important",
    fontWeight: 500,
    color: "white",
    backgroundColor: "#E77228 !important",
    borderRadius: "20px !important",
    minWidth: "235px !important",
    maxHeight: 44,
  },
  demandNextButton: {
    padding: "12px 24px",
    fontSize: "17px !important",
    fontWeight: 500,
    color: "white",
    backgroundColor: "#E77228 !important",
    borderRadius: "20px !important",
    minWidth: "235px !important",
    maxHeight: 44,
  },
  button: {
    padding: "12px 24px",
    fontSize: "17px !important",
    fontWeight: 500,
    color: "#E77228",
    borderRadius: "20px !important",
    minWidth: "235px !important",
    maxHeight: 44,
  },
  cancelButton: {
    background: "#ffffff",
    border: `1px solid #E77228`,
    color: "#E77228",
    textTransform: "capitalize",
    marginRight: 8,
  },
  demandCancelButton: {
    background: "#ffffff",
    border: `1px solid #E77228`,
    color: "#E77228",
    textTransform: "capitalize",
    marginRight: 8,
  },
  searchContainer: {
    alignContent: "center !important",
    alignItems: "center !important",
  },
  searchButton: {
    backgroundColor: "#E77228",
    width: 65,
    height: 50,
    borderRadius: 35,
  },
  demandSearchButton: {
    backgroundColor: "#E77228",
    width: 65,
    height: 50,
    borderRadius: 35,
  },
  produceCard: {
    display: "flex",
    gap: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: 75,
    borderRadius: 10,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    padding: 10,
  },
  Input: {
    borderRadius: 20,
    border: "1px solid #E77228",
    backgroundColor: "white",
  },
  sliderBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%!important",
    marginTop: 20,
    gap: 5,
  },
  customControlledField: {
    width: 120,
    background: "transparent",
    "& .MuiInputBase-root": {
      borderRadius: 20,

      "&::before": {
        borderColor: theme.palette.default.main,
      },
    },
    "& .MuiInputBase-input": {
      padding: 7,
    },
    "& .MuiInputLabel-root": {
      fontWeight: 400,
    },
  },
  infoBox: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: 30,
    borderRadius: 20,
    width: 75,
    background: "#F8F8F8",
  },
}));

function valuetext(value) {
  return `${value}°C`;
}

const ConsigmentsOfferPopUp = ({
  setOpenPreviewOfferPopup,
  openPreviewOfferPopup,
  text,
  companyId,
}) => {
  const [fruits, setFruits] = useState([]);

  useEffect(() => {
    /*    const lines = text.split("\n");
    const fruitList = lines
      .filter((line) => line.startsWith("*"))
      .map((line) => {
        const parts = line.split(" ");
        const piece = parseInt(parts[1].substring(1));
        const name = parts[2];
        const quantity = parseInt(parts[3].split("x")[0]);
        const pack = parts[3].split("x")[1];
        return { piece, name, quantity, pack };
      });

    setFruits(fruitList); */
  }, [text]);

  const classes = useStyles();

  const user = useSelector((state) => state.auth.user);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isError, setIsError] = useState(false);

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 30,
          border: "1px solid #E77228",
          padding: "10px 30px",
          width: "100%",
        },
      }}
      open={openPreviewOfferPopup}
      //onClose={handleCloseDialog}
    >
      <Box className={classes.dialogHeader}>
        <Typography
          variant="h6"
          style={{
            color: "#E77228",
            fontWeight: 600,
          }}
          align="start"
        >
          Make an Offer
        </Typography>

        <img
          onClick={() => {
            setOpenPreviewOfferPopup(false);
          }}
          style={{ cursor: "pointer" }}
          src="/static/svg/Close_round.svg"
        />
      </Box>
      <DialogContent className={classes.dialogContent}>
        {text?.map((fruit) => (
          <ProduceCard fruit={fruit} companyId={companyId} />
        ))}
      </DialogContent>

      <DialogActions
        style={{
          padding: 0,
          margin: 0,
          width: "100%",
          justifyContent: "center",
          marginTop: 20,
          marginBottom: 10,
        }}
      >
        <Button
          onClick={() => {
            setOpenPreviewOfferPopup(false);
          }}
          className={clsx(classes.button, classes.cancelButton)}
          color="primary"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConsigmentsOfferPopUp;

const ProduceCard = ({ fruit, companyId }) => {
  const classes = useStyles();
  const [price, setPrice] = useState();
  const [quantity, setQuantity] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const sendConsigment = async () => {
    setIsLoading(true);
    let res = await sendConsigmentOffer(
      fruit?.produce,
      quantity,
      price,
      companyId
    );
    setIsLoading(false);
  };

  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0) {
      setQuantity(value);
    } else {
      setQuantity(0);
    }
  };
  const handlePriceChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0) {
      setPrice(value);
    } else {
      setPrice(0);
    }
  };

  return (
    <div className={classes.contentRows}>
      <div className="d-flex flex-row gap-2">
        {" "}
        <Box className={classes.produceCard}>
          <Box style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <Box>
              <Typography style={{ fontSize: 16, fontWeight: 400 }}>
                {fruit.produce}
              </Typography>
              {fruit?.currency && (
                <Typography style={{ fontSize: 16, fontWeight: 400 }}>
                  {fruit.currency}
                </Typography>
              )}
            </Box>
          </Box>
          <div className="d-flex flex-row gap-3">
            <div
              onClick={() => {}}
              className="d-flex gap-2 flex-column"
              style={{
                display: "flex",
                gap: 5,
                justifyContent: "space-between",
              }}
            >
              <input
                placeholder="quantity"
                type="number"
                value={quantity}
                onChange={handleQuantityChange}
                max={fruit?.amount}
                style={{
                  width: 100,
                  borderRadius: 20,
                  border: "1px solid #E77228",
                  backgroundColor: "white",
                  height: 30,
                  padding: 5,
                }}
              />
              <input
                value={price}
                onChange={handlePriceChange}
                placeholder="price"
                type="number"
                style={{
                  width: 100,
                  borderRadius: 20,
                  border: "1px solid #E77228",
                  backgroundColor: "white",
                  height: 30,
                  padding: 5,
                }}
              />
            </div>
          </div>
        </Box>
        <LoadingIndicator loading={isLoading} />
        <div
          style={{
            height: "75px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "2px",
          }}
        >
          <button
            style={{
              width: 150,
              height: "100%",

              borderRadius: 20,
              backgroundColor: "#E77228",
              border: "none",
              color: "white",
            }}
            disabled={isLoading}
            onClick={() => sendConsigment()}
          >
            Send Mail
          </button>
        </div>
      </div>
    </div>
  );
};
