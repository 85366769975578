import React from "react";
import { Grid, TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";

export default function FruProControllerTextField(props) {
  const {
    
    control,
    name,
    size,
    fixedSize = {},
    validate,
    id,
    ref,
    inputRef,
    defaultValue,
    className,
    disableUnderline = true,
    placeholder = "",
    renderValue,
    ...other
  } = props;
  let sizeProps = {
    xs: 12,
  };
  if (!size) {
    sizeProps.xs = true;
  } else {
    sizeProps.md = size * 2 > 8 ? 12 : size * 2 > 6 ? 6 : size * 2;
    sizeProps.lg = size;
  }

  sizeProps = Object.keys(fixedSize).length > 0 ? { ...fixedSize } : sizeProps;

  return (
    <Grid item className={className} {...sizeProps}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
        
            fullWidth
            inputRef={inputRef}
            autoComplete="off"
            ref={ref}
            id={id}
            {...field}
            {...other}
            defaultValue={defaultValue || ""}
            placeholder={placeholder}
            onChange={(e) => {
              let handleChange = field.onChange;
              if (props.handleChange) {
                handleChange = (data) => {
                  props.handleChange(data);
                  field.onChange(data);
                };
              }
              if (validate) {
                validate(e)
                  ? handleChange(e)
                  : handleChange({
                      target: {
                        value: field.value,
                      },
                    });
              } else handleChange(e);
            }}
            value={
              ["string", "number"].includes(typeof field.value)
                ? field.value
                : renderValue
                ? renderValue(field.value)
                : JSON.stringify(field.value)
            }
            InputLabelProps={
              props.InputLabelProps || {
                shrink: true,
                style: {
                  marginBottom: ".5rem",
                },
              }
            }
            InputProps={
              props.InputProps || {
                disableUnderline: disableUnderline,
                style: {
                  borderRadius: "20px",
                },
              }
            }
            SelectProps={
              props.SelectProps || {
                displayEmpty: true,
              }
            }
          />
        )}
      />
    </Grid>
  );
}
