import { createSlice } from "@reduxjs/toolkit";
import { getCartInfo, setCurrentUser } from "@services/cart";

export const slice = createSlice({
  name: "cart",
  initialState: {
    cartList: []
  },
  reducers: {
    getCartList(state, action) {
      state.cartList = action.payload;
    }
  },
});

export const { getCartList } = slice.actions;
export const getCart = (user) => async (dispatch) => {
  try {
    setCurrentUser(user);
    const response = await getCartInfo();
    const productByIdObj = response?.product?.byId || {}
    let cartItemList = [];
    for (let id in productByIdObj) {
      cartItemList.unshift(productByIdObj[id]);
    }
    let sortedList = cartItemList.sort((a, b) => (
      new Date(b.createdAt) - new Date(a.createdAt)
    ))
    dispatch(getCartList(sortedList));
  } catch (e) {
    console.log(e)
  }
};

export default slice.reducer;
