import { axiosInstance } from "services";
import { queryObjSerialize } from "@utils/index";
import toastr from "toastr";
import { uploadFile } from "@services/common";
import { showOfferToastMessage } from "@utils/toastMessage";
import { OFFER_ACTION_TYPE_ENUM } from "@constants";
import { handleAPIErrors } from "@utils/handleAPIErrors";

export const addToOfferFromDetails = async (offerProduce, deliveryDate) => {
  const body = {
    offers: [
      {
        produces: [offerProduce],
        deliveryDate,
      },
    ],
  };
  try {
    const result = await axiosInstance.post("/offers", body);
    if (result?.status === 204) {
      toastr.success("Offer is created successfully.");
      showOfferToastMessage(OFFER_ACTION_TYPE_ENUM.create);
      return {
        isSuccess: true,
        data: result?.data,
      };
    } else if (result?.status === 200) {
      toastr.success("Offer is created successfully.");
      return {
        isSuccess: true,
        data: result?.data,
      };
    }
  } catch (error) {
    handleAPIErrors(error);
    toastr;
  }
};

export const addToOfferFromCart = async (offers) => {
  const body = {
    offers: offers,
  };
  try {
    const result = await axiosInstance.post("/offers", body);
    if (result?.status === 204) {
      showOfferToastMessage(OFFER_ACTION_TYPE_ENUM.create);
      return {
        isSuccess: true,
        data: result?.data,
      };
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.errors?.[0]?.message || error.message || error);
  }
};
export const addToOfferFromPost = async (data) => {
  try {
    const result = await axiosInstance.post("/offers", data);
    if (result) {
      toastr.success("Offer is created successfully.");
      return result;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.errors?.[0]?.message || error.message || error);
  }
};

export const addOfferUnBoardingCompany = async (data) => {
  try {
    const response = await axiosInstance.post("/onboarding/create-request", {
      ...data,
    });

    if (response) {
      toastr.success("Offer created successfully.");
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.errors?.[0]?.message || error.message || error);
  }
};
export const getOfferList = async (queryParams) => {
  try {
    let sanitizedQueries = { ...queryParams };
    let isBuyer = sanitizedQueries.baseTab === "buy";

    if (sanitizedQueries.activeTab === "active")
      sanitizedQueries.isActive = true;
    else if (sanitizedQueries.activeTab === "closed")
      sanitizedQueries.isClosed = true;
    else sanitizedQueries.status = "SETTLEMENT";

    delete sanitizedQueries.activeTab;
    delete sanitizedQueries.baseTab;

    let queryStrings = queryObjSerialize(sanitizedQueries);
    const result = await axiosInstance.get(
      `/${isBuyer ? "buyer-offers" : "seller-offers"}?${queryStrings}`
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getBuyerOffersList = async (filterData,page) => {
  try {
    // let sanitizedQueries = { ...queryParams };
    // const isInvoiceTab = queryParams?.activeTab === "invoice";

    // if (sanitizedQueries.activeTab === "active")
    //   if (sanitizedQueries.type === "ALLOCATING") {
    //     sanitizedQueries.status = "SETTLEMENT";
    //   } else {
    //     sanitizedQueries.isActive = true;
    //   }
    // else if (sanitizedQueries.activeTab === "closed")
    //   sanitizedQueries.isClosed = true;
    // else if (sanitizedQueries.activeTab === "settlement")
    //   sanitizedQueries.status = "SETTLEMENT";
    // else sanitizedQueries.status = "";

    // delete sanitizedQueries.activeTab;

    // let queryStrings = queryObjSerialize(sanitizedQueries);
    let result = {};

    result = await axiosInstance.post(`/buyer-offers?page=${page}&limit=${10}`, {
      ...filterData,
    });
    // if (isInvoiceTab) {
    //   result = await axiosInstance.get(
    //     `/invoice-generated-buyer-offers?${queryStrings}`
    //   );
    // } else {
    //   result = await axiosInstance.post(`/buyer-offers`, {
    //     ...filterData,
    //   });
    // }

    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getSellerOffersList = async (filterData,page) => {
  try {
    // let sanitizedQueries = { ...queryParams };
    // const isInvoiceTab = queryParams?.activeTab === "invoice";

    // if (sanitizedQueries.activeTab === "active") {
    //   if (sanitizedQueries.type === "ALLOCATING") {
    //     sanitizedQueries.status = "SETTLEMENT";
    //   } else {
    //     sanitizedQueries.isActive = true;
    //   }
    // } else if (sanitizedQueries.activeTab === "closed")
    //   sanitizedQueries.isClosed = true;
    // else if (sanitizedQueries.activeTab === "settlement")
    //   sanitizedQueries.status = "SETTLEMENT";
    // else sanitizedQueries.status = "";

    // delete sanitizedQueries.activeTab;

    // let queryStrings = queryObjSerialize(sanitizedQueries);

    let result = {};

    result = await axiosInstance.post(`/seller-offers?page=${page}&&limit=${10}`, { ...filterData });

    // if (isInvoiceTab) {
    //   result = await axiosInstance.get(
    //     `/invoice-generated-seller-offers?${queryStrings}`
    //   );
    // } else {
    //   result = await axiosInstance.post(`/seller-offers`, { ...filterData });
    // }

    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getDetailOffer = async (offerId) => {
  try {
    const result = await axiosInstance.get(`/offers/${offerId}`);
    if (result?.status === 200) return result?.data;
  } catch (error) {
    handleAPIErrors(error);
  }
};

export const getActivitiesOffer = async (offerId) => {
  try {
    const result = await axiosInstance.get(`/offers/${offerId}/activities`);
    if (result?.status === 200) return result?.data;
  } catch (error) {
    // console.error(error);
    // toastr.error(error.message || error);
    // throw error;
  }
};

export const updateOffer = async (offerId, body, offerCode) => {
  try {
    const result = await axiosInstance.put(`/offers/${offerId}`, body);
    if (result?.status === 200) {
      body.deliveryDate
        ? toastr.success(
            "You have successfully changed the estimated delivery date of the order!"
          )
        : showOfferToastMessage(OFFER_ACTION_TYPE_ENUM.otherCounter, offerCode);
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const updateAllocatingOffer = async (offerId, body, offerCode) => {
  try {
    const result = await axiosInstance.put(
      `/allocating-offers/${offerId}`,
      body
    );
    if (result?.status === 200) {
      showOfferToastMessage(OFFER_ACTION_TYPE_ENUM.otherCounter, offerCode);
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const acceptOffer = async (offerId, offerCode) => {
  try {
    const result = await axiosInstance.put(`/offers/${offerId}/accept`);
    if (result?.status === 200) {
      showOfferToastMessage(OFFER_ACTION_TYPE_ENUM.otherAccept, offerCode);
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const deliverOffer = async (offerId, offerCode) => {
  try {
    const result = await axiosInstance.put(`/offers/${offerId}/deliver`);
    if (result?.status === 204) {
      showOfferToastMessage(OFFER_ACTION_TYPE_ENUM.delivery, offerCode);
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const rejectOffer = async (offerId, body, offerCode) => {
  try {
    const result = await axiosInstance.put(`/offers/${offerId}/reject`, body);
    if (result?.status === 200) {
      showOfferToastMessage(OFFER_ACTION_TYPE_ENUM.otherReject, offerCode);
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const cancelOffer = async (offerId, offerCode) => {
  try {
    const result = await axiosInstance.put(`/offers/${offerId}/cancel`);
    if (result?.status === 204) {
      showOfferToastMessage(OFFER_ACTION_TYPE_ENUM.otherCancel, offerCode);
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getReceptions = async (offerId) => {
  try {
    const result = await axiosInstance.get(`/offers/${offerId}/receptions`);
    if (result?.status === 200) return result?.data;
  } catch (error) {
    // console.error(error);
    // toastr.error(error.message || error);
    // throw error;
  }
};

export const createReception = async (offerId, body) => {
  try {
    const result = await axiosInstance.post(
      `/offers/${offerId}/receptions`,
      body
    );
    if (result?.status === 200) {
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    const field = error?.errors[0]?.field;

    if (field) {
      toastr.error("There are duplicate produces");
    } else {
      toastr.error(error?.errors[0]?.message || error.message);
    }

    throw error;
  }
};

export const receiveOffer = async (offerId, offerCode, arrival) => {
  try {
    const result = await axiosInstance.put(`/offers/${offerId}/receive`);
    if (result?.status === 204) {
      let message = "";
      if (arrival) {
        showOfferToastMessage(OFFER_ACTION_TYPE_ENUM.submitArrival);
      } else {
        showOfferToastMessage(OFFER_ACTION_TYPE_ENUM.receive, offerCode);
      }
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const settlementOffer = async (offerId, offerCode) => {
  try {
    const result = await axiosInstance.put(`/offers/${offerId}/settlement`);
    if (result?.status === 204) {
      toastr.success("Successfully submitted arrival report");
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const completeOffer = async (offerId, offerCode) => {
  try {
    const result = await axiosInstance.put(`/offers/${offerId}/complete`);
    if (result?.status === 204) {
      showOfferToastMessage(OFFER_ACTION_TYPE_ENUM.complete, offerCode);
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getOfferComments = async (offerId) => {
  try {
    const result = await axiosInstance.get(`/offers/${offerId}/comments`);
    if (result?.status === 200) return result?.data;
  } catch (error) {
    // console.error(error);
    // console.log(error.status);
    // toastr.error(error.message || error);
    // throw error;
  }
};

export const createComment = async (offerId, payload) => {
  try {
    let file;
    if (payload?.type === "FILE") {
      file = await uploadFile(payload?.file, "offer");
      file.mimeType = payload?.file?.type;
      showOfferToastMessage(OFFER_ACTION_TYPE_ENUM.uploadFile);
    }
    const result = await axiosInstance.post(`/offers/${offerId}/comments`, {
      ...payload,
      file: file || payload?.file,
    });
    if (result?.status === 200) {
      showOfferToastMessage(OFFER_ACTION_TYPE_ENUM.createComment);
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getSellerOffers = async (queryParams) => {
  try {
    let queryStrings = queryObjSerialize(queryParams);
    const result = await axiosInstance.post(`/seller-offers?${queryStrings}`);
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getInvoiceList = async (queryParams) => {
  try {
    let sanitizedQueries = { ...queryParams };
    sanitizedQueries.status = sanitizedQueries.activeTab;

    if (sanitizedQueries.createdAtFrom)
      sanitizedQueries.deliveryDateFrom = sanitizedQueries.createdAtFrom;
    if (sanitizedQueries.createdAtTo)
      sanitizedQueries.deliveryDateTo = sanitizedQueries.createdAtTo;
    if (sanitizedQueries.activeTab === "ALL") delete sanitizedQueries.status;

    delete sanitizedQueries.activeTab;
    delete sanitizedQueries.createdAtFrom;
    delete sanitizedQueries.createdAtTo;

    let queryStrings = queryObjSerialize(sanitizedQueries);
    const result = await axiosInstance.post(`/invoices`, {
      ...queryParams,
    });
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getDetailInvoice = async (offerId, invoiceId) => {
  try {
    const result = await axiosInstance.get(
      `/offers/${offerId}/invoices/${invoiceId}`
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getInvoicesByOffer = async (offerId, type) => {
  try {
    const result = await axiosInstance.get(
      window.location.pathname.includes("/purchases/surplus-orders")
        ? `/offers/${offerId}/invoices?isSurplusOrder=true`
        : `/offers/${offerId}/invoices`
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const acceptInvoice = async (offerId, invoiceId) => {
  try {
    const result = await axiosInstance.put(
      `/offers/${offerId}/invoices/${invoiceId}/accept`
    );
    if (result?.status === 200) {
      toastr.success("Accept invoice successfully");
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const reviseInvoice = async (offerId, invoiceId) => {
  try {
    const result = await axiosInstance.put(
      `/offers/${offerId}/invoices/${invoiceId}/revise`
    );
    if (result?.status === 200) {
      toastr.success("Revise invoice successfully");
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const sendInvoice = async (offerId, invoiceId) => {
  try {
    const result = await axiosInstance.put(
      `/offers/${offerId}/invoices/${invoiceId}/send`
    );
    if (result?.status === 200) {
      toastr.success("Send invoice successfully");
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const updateReception = async (offerId, body, offerCode) => {
  try {
    const result = await axiosInstance.put(
      `/offers/${offerId}/receptions`,
      body
    );
    if (result?.status === 200) {
      showOfferToastMessage(OFFER_ACTION_TYPE_ENUM.editArrival, offerCode);
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const deleteReceptionProduce = async (
  offerId,
  receptionId,
  body,
  offerCode
) => {
  try {
    const result = await axiosInstance.delete(
      `/offers/${offerId}/receptions/${receptionId}`,
      body
    );
    if (result?.status === 204) {
      showOfferToastMessage(OFFER_ACTION_TYPE_ENUM.editArrival, offerCode);
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.errors?.[0]?.message || error.message || error);
  }
};

export const updateSeller = async (offerId, body) => {
  try {
    const result = await axiosInstance.put(`/offers/${offerId}/sellers`, body);
    if (result?.status === 200) {
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getTotalOfferBuyer = async (queryParams) => {
  try {
    let sanitizedQueries = { ...queryParams };

    if (sanitizedQueries.activeTab === "active")
      sanitizedQueries.isActive = true;
    else if (sanitizedQueries.activeTab === "closed")
      sanitizedQueries.isClosed = true;
    else if (sanitizedQueries.activeTab === "settlement")
      sanitizedQueries.status = "SETTLEMENT";
    else sanitizedQueries.status = "";

    delete sanitizedQueries.activeTab;
    delete sanitizedQueries.page;
    delete sanitizedQueries.limit;
    delete sanitizedQueries.totalPage;
    delete sanitizedQueries.total;

    let queryStrings = queryObjSerialize(sanitizedQueries);

    const result = await axiosInstance.get(
      `/total-offer-buyer?${queryStrings}`
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getTotalOfferSeller = async (queryParams) => {
  try {
    let sanitizedQueries = { ...queryParams };

    if (sanitizedQueries.activeTab === "active")
      sanitizedQueries.isActive = true;
    else if (sanitizedQueries.activeTab === "closed")
      sanitizedQueries.isClosed = true;
    else if (sanitizedQueries.activeTab === "settlement")
      sanitizedQueries.status = "SETTLEMENT";
    else sanitizedQueries.status = "";

    delete sanitizedQueries.activeTab;
    delete sanitizedQueries.page;
    delete sanitizedQueries.limit;
    delete sanitizedQueries.totalPage;
    delete sanitizedQueries.total;

    let queryStrings = queryObjSerialize(sanitizedQueries);

    const result = await axiosInstance.get(
      `/total-offer-seller?${queryStrings}`
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getTotalOffer = async (queryParams) => {
  try {
    let sanitizedQueries = { ...queryParams };
    let isBuyer = sanitizedQueries.baseTab === "buy";

    if (sanitizedQueries.activeTab === "active")
      sanitizedQueries.isActive = true;
    else if (sanitizedQueries.activeTab === "closed")
      sanitizedQueries.isClosed = true;
    else if (sanitizedQueries.activeTab === "settlement")
      sanitizedQueries.status = "SETTLEMENT";
    else sanitizedQueries.status = "";

    delete sanitizedQueries.activeTab;
    delete sanitizedQueries.baseTab;
    delete sanitizedQueries.page;
    delete sanitizedQueries.limit;
    delete sanitizedQueries.totalPage;
    delete sanitizedQueries.total;

    let queryStrings = queryObjSerialize(sanitizedQueries);

    const result = await axiosInstance.get(
      `/${isBuyer ? "total-offer-buyer" : "total-offer-seller"}?${queryStrings}`
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const removeAllReceptions = async (offerId) => {
  try {
    const result = await axiosInstance.delete(
      `/offers/${offerId}/bulk-delete-receptions`
    );
    if (result?.status === 204) {
      // showOfferToastMessage(OFFER_ACTION_TYPE_ENUM.delivery, offerCode);
      return result?.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const createFixedOffer = async (body) => {
  try {
    const response = await axiosInstance.post("/fixed-offers", body);

    if (response?.status === 204 || response?.status === 200) {
      showOfferToastMessage(OFFER_ACTION_TYPE_ENUM.create);
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const getOrderConfirmationLink = async (offerId) => {
  try {
    const response = await axiosInstance.get(
      `/offers/confirmation-file/${offerId}`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    toastr.error(error.message);
  }
};

export const createOfferDiscount = async (offerId, offerAmount) => {
  try {
    const response = await axiosInstance.put("/reconciliation", {
      id: offerId,
      amount: offerAmount,
    });

    if (response) {
      toastr.success("Reconcilation amount applied successfuly");
      return response.data;
    }
  } catch (error) {
    toastr.error(error.message);
  }
};

export const updateReferenceNumber = async (offerId, refs) => {
  try {
    const response = await axiosInstance.post("/buyer-seller-ref", {
      offerId,
      refs,
    });

    if (response) {
      toastr.success("Reference Number is updated successfully.");
      return response.data;
    }
  } catch (error) {
    toastr.error(error.message);
  }
};

export const markAsPaid = async (offerId, invoiceId) => {
  try {
    const response = await axiosInstance.put(`/offers/${offerId}/invoices/${invoiceId}/complete`);

    if (response) {
      toastr.success("Invoice Marked as paid successfully.");
      return response.data;
    }
  } catch (error) {
    toastr.error(error.message);
  }
};

export const assignOfferWithRefCode = async (refCode) => {
  try {
    const response = await axiosInstance.post("/onboarding/ref-code-assign", {
      refCode: refCode,
    });

    if (response) {
      return response.data;
    }
  } catch (error) {
    toastr.error(error.message);
  }
};
export const sendConsigmentOffer = async (
  name,
  price,
  quantity,
  sellerCompanyId
) => {
  try {
    const response = await axiosInstance.post("/order-demand", {
      produces: [
        {
          name: name,
          price: price,
          quantity: quantity,
        },
      ],
      sellerCompanyId: sellerCompanyId,
    });

    if (response && response.status === 200) {
      toastr.success("Offer is created successfully.");
      return response.data;
    }
  } catch (error) {
    toastr.error(error.message);
  }
};

export const editProduceItemOffer = async (data, offerId) => {
  try {
    const response = await axiosInstance.post(
      `/offer-produces/update/${offerId}`,
      {
        ...data,
      }
    );

    if (response) {
      toastr.success("Produce is updated successfully.");
      return response.data;
    }
  } catch (error) {
    toastr.error(error.message);
  }
};

export const getPartialPayment = async (offerId) => {
  try {
    const response = await axiosInstance.get(
      `/get-partial-payments/${offerId}`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    toastr.error(error.message);
  }
};

export const updateInvoiceDate = async (data) => {
  try {
    const response = await axiosInstance.put(
      `/invoices/update-invoice-date`,{...data}
    );

    if (response) {
      toastr.success("Invoice date updated successfully.")
      return response.data;
    }
  } catch (error) {
    toastr.error(error.message);
  }
};
