import { firebase } from "./firebase";
import toastr from "toastr";
import moment from "moment";
import {
  uniqArray,
  getCurrentDayTime,
  getDefaultImageUrl,
  getSubProductTypeDefaultImages,
  getThumbImageUrl,
} from "@utils/index";

let currentUserId = "";
let currentUser;

const CollectionPrexfix = "";
// const CollectionPrexfix =
//   process.env.NODE_ENV === "development" ? "FE-dev-" : "";

export function setCurrentUser(user) {
  currentUserId = user?.id.toString();
  currentUser = user;
}

export async function getCartInfo(userId) {
  try {
    let url = `${CollectionPrexfix}carts`;
    let firestore = firebase.firestore;
    const dataSnap = await firestore()
      .collection(url)
      .doc(userId || currentUserId)
      .get();
    return dataSnap.data();
  } catch (e) {
    console.log(e);
  }
}

export async function addProduceToCart(produce, userId, onlySyncProduce) {
  try {
    let url = `${CollectionPrexfix}carts`;
    let firestore = firebase.firestore;
    let createdAt = getCurrentDayTime();
    console;
    let produceInfo = {
      id: produce.id,
      name: produce.name,
      quantity: produce.quantity,
      minQuantity: produce.minQuantity,
      price: produce.price,
      originPrice: produce.price,
      // description: produce.description,
      currency: produce?.currency,
      unit: produce?.unit,
      photo: produce.photos.length
        ? getThumbImageUrl(produce?.photos[0])
        : getDefaultImageUrl(produce?.photos[0]),

      owners: produce?.owners,
      createdAt: createdAt,
      deliveryDate: produce.deliveryDate,
    };

    let data = {};
    let cartData = await getCartInfo(userId);

    if (onlySyncProduce) {
      // update product
      const formatProduce = {
        byId: {
          ...(cartData?.product?.byId || {}),
          [produceInfo.id]: {
            ...produceInfo,
          },
        },
      };

      return await firestore()
        .collection(url)
        .doc(userId)
        .set({ product: formatProduce }, { merge: true });
    }

    let { user, company } = produce;

    if (!user) return toastr.error(`This produce doesn't have seller info`);
    else if (!company)
      return toastr.error(`This produce doesn't have store info`);

    let sellerInfo = {
      id: user,
    };

    let storeInfo = {
      id: company.id,
      name: company.companyName,
      logo: company?.photo?.thumbUrl || company?.logo?.url || "",
    };

    // update store
    const formatStore = {
      byId: {
        ...(cartData?.store?.byId || {}),
        [storeInfo.id]: {
          ...storeInfo,
          sellerIds: uniqArray([
            ...(cartData?.store?.byId[storeInfo.id]?.sellerIds || []),
            sellerInfo.id,
          ]),
        },
      },
      allIds: uniqArray([...(cartData?.store?.allIds || []), storeInfo.id]),
    };
    data = { ...data, store: formatStore };

    // update seller
    const formatSeller = {
      byId: {
        ...(cartData?.seller?.byId || {}),
        [sellerInfo.id]: {
          ...sellerInfo,
          productIds: uniqArray([
            ...(cartData?.seller?.byId[sellerInfo.id]?.productIds || []),
            produceInfo.id,
          ]),
        },
      },
    };
    data = { ...data, seller: formatSeller };

    // update product
    const formatProduce = {
      byId: {
        ...(cartData?.product?.byId || {}),
        [produceInfo.id]: {
          ...produceInfo,
        },
      },
    };
    data = { ...data, product: formatProduce };

    return await firestore()
      .collection(url)
      .doc(userId)
      .set({ ...data }, { merge: true });
  } catch (e) {
    console.error(e);
  }
}

export async function removeCartItem(ids = []) {
  if (!ids) return;
  let productIds = uniqArray(ids);

  let url = `${CollectionPrexfix}carts`;
  let firestore = firebase.firestore;
  let cartData = await getCartInfo(currentUserId);

  if (!cartData) return;

  productIds.forEach((id) => {
    delete cartData?.product?.byId[id];
  });

  Object.keys(cartData?.seller?.byId).forEach((key) => {
    cartData.seller.byId[key].productIds = (
      cartData?.seller?.byId[key]?.productIds || []
    ).filter((id) => !productIds.includes(id));
    if (cartData?.seller?.byId[key]?.productIds.length === 0)
      delete cartData?.seller?.byId[key];
  });

  Object.keys(cartData?.store?.byId).forEach((key) => {
    cartData.store.byId[key].sellerIds = (
      cartData.store.byId[key].sellerIds || []
    ).filter((id) => cartData.seller.byId[id]);
    if (cartData?.store?.byId[key]?.sellerIds.length === 0)
      delete cartData?.store?.byId[key];
  });

  // clean store allIds
  if (cartData?.store?.allIds)
    cartData.store.allIds = cartData.store.allIds.filter(
      (id) => cartData.store.byId[id]
    );

  await firestore()
    .collection(url)
    .doc(currentUserId)
    .set({ ...cartData });
  return cartData;
}

export async function changeAllCartItemDeliveryDate(
  userId,
  deliveryDate,
  companyId
) {
  try {
    let url = `${CollectionPrexfix}carts`;

    let firestore = firebase.firestore;
    const formattedDeliveryDate = deliveryDate
      ? moment(deliveryDate).format("DD/MM/YYYY")
      : "";

    const dataSnap = await firestore()
      .collection(url)
      .doc(userId || currentUserId)
      .get();

    const docData = dataSnap.data();

    const keys = Object.keys(docData.product.byId);

    keys.forEach((key) => {
      // if includes docData.product.byId[key].owners array companyId key

      const owners = docData.product.byId[key].owners;

      owners.forEach((element) => {
        if (element.companyId === companyId) {
          docData.product.byId[key].deliveryDate = formattedDeliveryDate;
        }
      });
    });

    // set with new data

    await firestore()
      .collection("carts")

      .doc(userId)

      .set({ product: docData.product }, { merge: true });

    toastr.success("Delivery Date updated successfully");
  } catch (e) {
    console.log(e);
  }
}

// export async function getCartInfo(userId) {
//   try {
//     let url = `${CollectionPrexfix}carts`;
//     let firestore = firebase.firestore;
//     const dataSnap = await firestore()
//       .collection(url)
//       .doc(userId || currentUserId)
//       .get();
//     return dataSnap.data();
//   } catch (e) {
//     console.log(e);
//   }
// }
