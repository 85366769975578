import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { formatEmptyData, queryObjSerialize } from "@utils/index";
import { ARRAY_PARAMS } from "@constants";

// queryParams should inclue pageIndex, pageSize..
export const useTableFetch = ({
  type,
  initialQueryParams,
  fetchData,
  data: propData = [],
  isPaging = false,
  isSort = false,
  disabled = false,
  onChangeShallowQuery,
  isPreventFetchData,
}) => {
  const [data, setData] = useState(propData);
  const [currentMetaData, setCurrentMetaData] = useState();
  const [queryParams, setQueryParams] = useState({
    ...(isPaging
      ? {
          page: 0,
          limit: 24,
        }
      : null),
    ...(isSort
      ? {
          order: "",
        }
      : null),
    ...initialQueryParams,
  });

  const [loading, setLoading] = useState(!disabled ? true : false);
  const [error, setError] = useState();

  const resetQueryParams = (custom = {}) => {
    let queryParams = {
      ...(isPaging
        ? {
            page: 0,
            limit: 24,
          }
        : null),
      ...(isSort
        ? {
            order: "",
          }
        : null),
      ...initialQueryParams,
      ...custom,
    };
    queryParams = formatEmptyData(queryParams);
    setQueryParams(queryParams);
  };

  const debounceLoadData = useCallback(
    debounce((queryParams) => {
      setLoading(true);
      fetchData(queryParams)
        .then(({ items, metaData }) => {
          setLoading(false);
          if (!type) {
            setData(items);
          } else {
            const data = items.map((item) => ({
              ...item.user,
              requestId: item.id,
            }));
            setData(data);
          }
          setCurrentMetaData({
            ...metaData,
          });
          setQueryParams((queryParams) => ({
            ...queryParams,
            ...metaData,
          }));
        })
        .catch((err) => {
          setLoading(false);
          setError(err);
        });
    }, 500),
    []
  );

  useEffect(() => {
    if (disabled) return;
    if (!currentMetaData && !isPreventFetchData) {
      debounceLoadData(queryParams);
      onChangeShallowQuery && onChangeShallowQuery(queryParams);
    }
    setCurrentMetaData(null);
  }, [queryParams]);

  return {
    data,
    setData,
    queryParams,
    setQueryParams,
    resetQueryParams,
    loading,
    error,
  };
};

const privateQuery = [
  "limit",
  "total",
  "totalPage",
  "categories",
  "countryOrigin",
  "countryCode",
  "countryStates",
  "countryCities",
  "locationCountry",
  "locationState",
  "locationCity",
  "companyId",
  "userIds",
  "status",
  "priceIndicationTo",
  "priceIndicationFrom",
  "createdAtFrom",
  "createdAtTo",
  "activeTab",
  "baseTab",
  "actionName",
  "prevTab",
  "isPopular",
  "subProductTypeIds",
  "countryState",
  "countryCity",
  "quantityFrom",
  "quantityTo",
  "companyName",
  "companySpecialityIds",
  "companyTypeIds",
  "timelineFrom",
  "timelineTo",
  "chatbox",
  "isHidePrice",
  "type",
  "types",
  "principalPlaceCountryName",
];

const cleanArrayParams = (arrayParam, query) => {
  let newQuery = { ...query };
  const newArrayParam = arrayParam?.slice(0, arrayParam?.length - 2);

  if (newQuery[arrayParam]) {
    let value = [];

    if (Array.isArray(newQuery[arrayParam])) {
      value = newQuery[arrayParam];
    } else {
      value = [newQuery[arrayParam]];
    }

    delete newQuery[arrayParam];

    newQuery = {
      ...newQuery,
      [newArrayParam]: value,
    };
  }

  return newQuery;
};

const cleanSpecialParams = (query) => {
  let newQuery = { ...query };

  if (newQuery["productTypeIds"]) {
    newQuery = {
      ...newQuery,
      productTypeIds: query.productTypeIds.split(","),
    };
  }

  ARRAY_PARAMS?.forEach((item) => {
    newQuery = cleanArrayParams(item, newQuery);
  });

  return newQuery;
};

export const useShallowRouting = () => {
  const router = useRouter();
  const [shallowQuery, setShallowQuery] = useState(
    cleanSpecialParams(router.query)
  );

  const onChangeShallowQuery = (shallowQuery) => {
    let query = { ...shallowQuery };
    privateQuery.forEach((key) => delete query[key]);
    let queryStrings = queryObjSerialize(query);
    router.push(`${router.pathname}?${queryStrings}`, undefined, {
      shallow: true,
    });
  };

  return [router, shallowQuery, onChangeShallowQuery];
};

export const useGeoLocation = () => {
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { latitude: "", longitude: "" },
  });

  const onSuccess = (location) => {
    setLocation({
      loaded: true,
      coordinates: {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
      },
    });
  };

  const onError = (error) => {
    setLocation({
      loaded: true,
      error: {
        code: error.code,
        message: error.message,
      },
    });
  };

  useEffect(() => {
    if (!("geolocation" in navigator)) {
      onError({
        code: 0,
        message: "Geolocation not supported",
      });
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError);
  }, []);

  return location;
};

export const useDetectScrolledToBottom = (node) => {
  const [isBottom, setIsBottom] = useState(false);

  const handleScroll = useCallback(() => {
    const { scrollTop, scrollHeight, clientHeight } = node.current;
    if (Math.round(scrollTop + clientHeight) === scrollHeight) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  }, [node.current]);

  useEffect(() => {
    if (node.current) {
      node.current.addEventListener("scroll", handleScroll);
      return () => node.current.removeEventListener("scroll", handleScroll);
    }
  }, [node.current, handleScroll]);
  return { isBottom };
};
