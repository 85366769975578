import toastr from "toastr";
import { axiosInstance } from "services";
export const infoService = {
  async getSpecialities() {
    const { data } = await axiosInstance.get("/info/specialities");
    return data;
  },
  async getStates(code) {
    const { data } = await axiosInstance.get(
      `/info/states?countryCode=${code}`
    );
    return data;
  },
  async getCompanyTypes() {
    const { data } = await axiosInstance.get("/info/company");
    return data;
  },
};

export const getProductTypes = async () => {
  try {
    let result = await axiosInstance.get(`/product-types`);
    if (result?.status === 200) return result?.data;
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
  }
};

export const getBrands = async () => {
  try {
    let result = await axiosInstance.get(`/brands`);
    if (result?.status === 200) return result?.data;
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
  }
};

export const getCurrencies = async ({ token, params }) => {
  try {
    let result = await axiosInstance.get(`/currencies`, {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
      params: params || {},
    });
    if (result?.status === 200) return result?.data;
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
  }
};

export const getProductCurrencies = async () => {
  try {
    let result = await axiosInstance.get(`/currencies`);
    if (result?.status === 200) return result?.data;
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
  }
};

export const getUnits = async () => {
  try {
    let result = await axiosInstance.get(`/units`);
    if (result?.status === 200) return result?.data;
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
  }
};

export const getCountriesGeo = async (params = null) => {
  try {
    let result = await axiosInstance.get("/countries", {
      params: params ? params : {},
    });
    if (result?.status === 200) return result?.data?.items;
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
  }
};

export const getStatesGeo = async (countryId) => {
  try {
    let result = await axiosInstance.get(`/countries/${countryId}/states`);

    if (result?.status === 200) return result?.data?.items;
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
  }
};

export const getStatesGeoWithCity = async (countryId) => {
  try {
    let result = await axiosInstance.get(
      `/countries/${countryId}/states?includes=cities`
    );

    if (result?.status === 200) return result?.data?.items;
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
  }
};

export const getCitiesGeo = async (countryId, stateId) => {
  try {
    let result = await axiosInstance.get(
      `/countries/${countryId}/states/${stateId}/cities`
    );
    if (result?.status === 200) return result?.data?.items;
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
  }
};

export const getProduceOriginCountries = async (params) => {
  try {
    let result = await axiosInstance.get("/produce-origin-countries", {
      params: params ? params : {},
    });
    if (result?.status === 200) return result?.data?.items;
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
  }
};

export const getProduceOriginCountriesCompany = async (companyId, params) => {
  try {
    let result = await axiosInstance.get(
      `/companies/${companyId}/produce-origin-countries`,
      {
        params: params ? params : {},
      }
    );
    if (result?.status === 200) return result?.data?.items;
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
  }
};

export const getUserProduceOriginCountries = async (params) => {
  try {
    let result = await axiosInstance.get("/user-produce-origin-countries", {
      params: params ? params : {},
    });
    if (result?.status === 200) return result?.data?.items;
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
  }
};

export const getCompanyProduceOriginCountries = async (params) => {
  try {
    let result = await axiosInstance.get("/company-produce-origin-countries", {
      params: params ? params : {},
    });
    if (result?.status === 200) return result?.data?.items;
  } catch (err) {
    console.error(err);
    toastr.error(err.message);
  }
};

export const getLastestVersion = async () => {
  try {
    let result = await axiosInstance.get("/info/latestVersion", {});
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.log(error);
  }
};

export const getSearchResultPhotoUrls = async (photoInfo) => {
  try {
    const response = await axiosInstance.post("/get-url", {
      photo: {
        name: photoInfo?.name,
        size: photoInfo?.size,
        path: photoInfo?.path,
        thumbPath: photoInfo?.thumbPath,
      },
    });

    if (response) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAllocationBuyers = async () => {
  try {
    const response = await axiosInstance.get(
      "/company-directories?surplus=true"
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    toastr.error(error?.message);
    console.error(error?.message);
  }
};
export const getVerifiedBuyers = async () => {
  try {
    const response = await axiosInstance.get(`/companies?limit=${9999}&&getImageUrl=${false}`);

    if (response) {
      return response.data;
    }
  } catch (error) {
    toastr.error(error?.message);
    console.error(error?.message);
  }
};

export const getSubTypesWithProductTypeId = async () => {
  try {
    const response = await axiosInstance.get(
      `/sub-product-types/with-product-type?getImageUrl=${false}`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    toastr.error(error?.message);
    console.error(error?.message);
  }
};

export const getSubTypesWithProductTypeIdAndImage = async () => {
  try {
    const response = await axiosInstance.get(
      `/sub-product-types/with-product-type?getImageUrl=${true}`
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    toastr.error(error?.message);
    console.error(error?.message);
  }
};
