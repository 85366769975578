import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  makeStyles,
  withStyles,
  Typography,
  Button,
  FormLabel,
} from "@material-ui/core";
import Modal from "react-bootstrap/Modal";
import {
  createTextPost,
  createImagePost,
  createVideoPost,
  createProduceDemandPost,
  fileUpload,
  createFilePost,
  newFileUpload,
  updateImagePost,
  videoUpload,
  updateFilePost,
  updateVideoPost,
} from "@services/amityEndPoints";
import Slider from "@mui/material/Slider";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";
import { getMyProduces } from "@services/my-store";
import { AmptitudeEvents } from "@services/amptitude_events";
import { getMyDemandProduces } from "@services/demanded";
import OfferDemandDialogWithSearch from "./components/OfferDemandDialogWithSearch";
import AddOfferDemandItem from "./components/AddOfferDemandItem";
import LoadingIndicator from "@components/LoadingIndicator";
import PreviewOfferPopUp from "./components/PreviewOfferPopUp";
import PreviewDemandPopUp from "./components/PreviewDemandPopUp";
import UpdateTextPostPopUp from "./components/UpdateTextPostPopUp";

import {
  SOCIAL_FEED_OFFER_POST,
  SOCIAL_FEED_DEMAND_POST,
  SOCIAL_FEED_TEXT_POST,
  SOCIAL_FEED_VIDEO_POST,
  SOCIAL_FEED_FILE_POST,
  SOCIAL_FEED_IMAGE_POST,
} from "@services/amptitude_contants";
import { marks, marksSlider } from "./components/constants";
import { useRouter } from "next/router";
import TextPostChooseExpireModal from "./components/TextPostChooseExpireModal";
import SelectBuyerModal from "./components/SelectBuyerModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { addOfferUnBoardingCompany, addToOfferFromPost } from "@services/offer";
import WaitForApprovalModal from "./components/WaitForApprovalModal";
import OpenLoggedOrNotVerifyModal from "./components/OpenLoggedOrNotVerifyModal";
import SelectInvoiceModal from "./components/SelectInvoiceModal";
import { Editor, EditorState, Modifier, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "draft-js/dist/Draft.css";
import TextAreaEditor from "./components/TextAreaEditor";
import CompanyNotVerifiedModal from "@components/CompanyNotVerifiedModal";
import { ValidateHTML } from "@services/common";
import toastr from "toastr";

const schema = yup.object().shape({
  selectedBuyer: yup.object(),
  produces: yup.array().of(
    yup.object().shape({
      quantity: yup.string(),
      price: yup.string(),
      priceOnRequest: yup.boolean().default(false),
    })
  ),
  companyName: yup.string(),
  email: yup.string().email("Must be a valid email"),
});

const useStyles = makeStyles((theme) => ({
  sliderBox: {
    marginLeft: 35,
  },
  postBox: {
    width: "100%",
    backgroundColor: "white",
    height: "275px",
    margin: "auto",
    boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5) !important",
    borderBottomLeftRadius: "20px !important",
    borderBottomRightRadius: "20px !important",
    boxSizing: "border-box !important",

    alignItems: "center",
  },
  postBox2: {
    width: "100%",
    backgroundColor: "white",
    padding: "10px 0px",
    margin: "auto",
    boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5) !important",
    borderRadius: "20px !important",
    boxSizing: "border-box !important",
    alignItems: "center",
    position: "relative",
  },
  postHeader: {
    margin: "10px 28px",
    fontSize: "16px",
    alignItems: "center",
  },
  textField: {
    margin: "0 28px !important",
    width: "82%",
    cursor: "text",
    height: 120,
    minHeight: 120,
    maxHeight: 120,
    overflowY: "auto",
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      opacity: "0.3",
    },
  },
  iconContainer: {
    justifyContent: "flex-end",
    marginRight: 20,
    gap: "0.5rem",
    display: "flex",
  },
  textIconCounter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  postTextContainer: {
    marginTop: 30,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "start",
  },
  imageContainer: {
    padding: "0 20px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row !important",
    height: "auto",
    width: "auto",
    gap: 20,
  },
  inputText: {
    width: "800px",
    fontSize: 18,
    height: "120px",
    border: "0.1px solid gray",
    "&:focus": {
      outline: "none",
    },
    "&:input": {
      margin: "20px 20px",
    },
  },
  textarea: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "blue",
    },
  },
  textCounter: {
    marginLeft: 28,
    display: "flex",
    gap: 10,
  },
  headerTextPostText: {
    borderRadius: "20px!important",
    display: "flex",
    backgroundColor: "white",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  AddMoreImage: {
    fontSize: 20,
    opacity: 0.3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  AddMoreImageContainer: {
    width: 135,
    marginTop: 30,
    height: 114,
    borderRadius: 10,
    border: "1px dashed #E77228",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
  },
  headerTextIconsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tabBox: {
    display: "flex",
    justifyContent: "center",
    width: "50%",
    boxSizing: "border-box",
    alignItems: "center",
    cursor: "pointer",
    color: "gray",
    height: 50,
  },
  activeTabBox: {
    display: "flex",
    justifyContent: "center",
    width: "50%",
    color: "#69CDAE",
    boxSizing: "border-box",
    alignItems: "center",
    cursor: "pointer",
    height: 50,
  },
  tabBox2: {
    display: "flex",
    justifyContent: "center",
    width: "50%",
    color: "gray",
    alignItems: "center",
    cursor: "pointer",
    height: 50,
  },
  activeTabBox2: {
    display: "flex",
    justifyContent: "center",
    width: "50%",
    color: "#E77228",
    alignItems: "center",
    cursor: "pointer",
    height: 50,
  },
  tabContainer: {
    display: "flex",
    flexDirection: "row",
  },
  sendButton: {
    borderRadius: 10,
    height: 35,
    width: 300,
    backgroundColor: "#E77228",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 15,
    cursor: "pointer",
  },
  sendButton2: {
    borderRadius: 40,
    height: 35,
    width: 100,
    backgroundColor: "#69CDAE",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 15,
    cursor: "pointer",
  },
  buyButton: {
    borderRadius: 40,
    height: 35,
    width: 200,
    backgroundColor: "#69CDAE",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 15,
    cursor: "pointer",
  },
  buyButtonInactive: {
    borderRadius: 40,
    height: 35,
    width: 200,
    backgroundColor: "#737373",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 15,
    cursor: "pointer",
  },
  sellButton: {
    borderRadius: 40,
    height: 35,
    width: 200,
    backgroundColor: "rgb(231, 114, 40)",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 15,
    cursor: "pointer",
  },
  sellButtonInactive: {
    borderRadius: 40,
    height: 35,
    width: 200,
    backgroundColor: "#737373",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 15,
    cursor: "pointer",
  },
  iconsContainer: {
    marginRight: 15,
    display: "flex",
    gap: 10,
  },
  iconsContainer2: {
    marginRight: 15,
    display: "flex",
    gap: 10,
    position: "absolute",
    right: 0,
    top: 5,
  },
  imageModal: {
    borderRadius: 30,
    boxShadow: "0px 4px 50px rgba(0, 0, 0, 0.25)",
  },
  saveButton: {
    padding: "12px 24px",
    fontSize: "17px !important",
    fontWeight: 500,
    color: "white",
    backgroundColor: "#E77228 !important",
    borderRadius: "20px !important",
    minWidth: "235px !important",
    maxHeight: 44,
  },
  cancelButton: {
    border: "1px solid #E77228",
    padding: "12px 24px",
    fontSize: "17px !important",
    fontWeight: 500,
    color: "#E77228",
    backgroundColor: "white !important",
    borderRadius: "20px !important",
    minWidth: "235px !important",
    maxHeight: 44,
  },
  Input: {
    borderRadius: 20,
    position: "relative",
    border: "1px solid #E77228",
    backgroundColor: "white",
    margin: "0px 30px",
    overflowY: "auto",
    marginRight: 65,
    height: 220,
    maxHeight: 220,
    padding: "5px 10px",
  },
  customTextField: {
    "& ::placeholder": {
      padding: "10px 10px",
    },
  },

  notRegisteredBox: {
    padding: 20,
    textAlign: "center",
    borderRadius: "30px",
    background: "rgba(255, 255, 255, 0.80)",

    boxShadow: "0px 0px 30px 0px rgba(0, 0, 0, 0.10)",
    backdropFilter: "blur(25px)",
  },
  cancelRegister: {
    cursor: "pointer",
    height: 40,
    width: "100%",
    border: "1px solid #E77228",
    background: "transparent",
    color: "#E77228",
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  continueRegister: {
    cursor: "pointer",
    height: 40,
    width: "300px",
    background: "#E77228",
    color: "white",
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  notCompleteText: {
    color: "#7C7C7C",
    fontWeight: 600,
  },
}));

const PrettoSlider = withStyles({
  root: {
    color: "#52af77",
    height: 2,
  },
  thumb: {
    color: "#E77228",
    background: "#E77228",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(2px)",
    height: 24,
    width: 24,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },

  valueLabel: {
    left: "calc(50% - 16px)",
    color: "#E77228",
    fontSize: "10px",
  },
  active: {},
  track: {
    height: 2,
    color: "#E77228",
  },
  rail: {
    height: 2,
    color: "#F1F1F1",
  },
})(Slider);

const AddpostItem = ({
  editorEditedTextState,
  setEditorEditedTextState,
  isPressedOfferPostMakeOffer,
  setIsPressedOfferPostMakeOffer,
  renderType,
  setRenderType,
  company,
  makeOfferBuyerCompanyId,
  setMakeOfferBuyerCompanyId,
  setOpenOfferDemandPostDialog,
  openOfferDemandPostDialog,
  editedOfferDemandPostText,
  setEditedOfferDemandPostText,
  editedPostText,
  setEditedPostText,
  postTextArea,
  setPostTextArea,
  editFileVideoImage,
  onNavigateLogin,
  setEditFileVideoImage,
  openImageModal,
  setOpenImageModal,
  openVideoModal,
  setOpenVideoModal,
  openFileModal,
  setOpenFileModal,
  setGlobalFeedData,
  offerDemandPostText,
  setOfferDemandPostText,
  globalFeedData,
  postText,
  setPostText,
  selectedImages,
  setSelectedImages,
  // setOpenLimitedCompanyModal,
  selectedVideos,
  isLimitedCompany,
  selectedFiles,
  setSelectedFiles,
  setSelectedVideos,
  nextPage,
  prevPage,
  getGlobalFeeds,
  setOpenPreviewDemandPopup,
  setOpenPreviewOfferPopup,
  openPreviewDemandPopup,
  openPreviewOfferPopup,
  selectedProduce,
  setSelectedProduce,
  setIsUpdate,
  isUpdate,
  postId,
  setPostId,
  openUpdateTextPostPopup,
  setOpenUpdateTextPostPopup,
  textAreaEditorState,
  setTextAreaEditorState,
  previewOfferEditorState,
  editPreviewOfferEditorState,
  editorState,
  setEditorState,
}) => {
  function valuetext(value) {
    return `${value}°C`;
  }

  const {
    control,
    watch,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      produces: [],
    },
  });

  const router = useRouter();
  const classes = useStyles();

  const watchSelectedBuyer = watch("selectedBuyer");
  const watchCompanyName = watch("companyName");
  const watchEmail = watch("email");
  const watchProduces = watch("produces");

  const [isAddNew, setIsAddNew] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const [openShareTab, setOpenShareTab] = useState(true);
  const [openPostTab, setOpenPostTab] = useState(false);

  const [openLoggedOrNotVerifyModal, setOpenLoggedOrNotVerifyModal] =
    useState(false);
  const [openWaitForApprovalModal, setOpenWaitForApprovalModal] =
    useState(false);
  const [sliderValue, setSliderValue] = useState(16);
  const [sliderTextValue, setSliderTextValue] = useState(16);
  const [sliderImageFileVideoValue, setSliderImageFileVideoValue] =
    useState(16);
  const [loading, setLoading] = useState(false);

  //const [filesData, setFilesData] = useState([]);
  //const [videosData, setVideosData] = useState([]);
  //const [imagesData, setImagesData] = useState([]);

  const [openCompanyNotVerifiedModal, setOpenCompanyNotVerifiedModal] =
    useState(false);
  const [openDemandPostDialog, setOpenDemandPostDialog] = useState(false);
  const [openSelectBuyerModal, setOpenSelectBuyerModal] = useState(false);
  const [openSelectInvoiceModal, setOpenSelectInvoiceModal] = useState(false);
  const [invoiceRenderType, setInvoiceRenderType] = useState("yes");
  const [openTextChooseExpireModal, setOpenTextChooseExpireModal] =
    useState(false);
  const [invoiceUrl, setInvoiceUrl] = useState(null);
  const [isInputActive, setInputActive] = useState(false);
  const sliderText = marks.find((item) => item.value === sliderValue);
  const sliderTextTextValue = marks.find(
    (item) => item.value === sliderTextValue
  );
  const sliderTextImageVideoFile = marks.find(
    (item) => item.value === sliderImageFileVideoValue
  );

  const onAreaEditorChange = (newEditorState) => {
    setTextAreaEditorState(newEditorState);
  };

  const editorRef = useRef(null);
  const editorAreaRef = useRef(null);

  //this effect for auto open offer demand dialog when user add new produce or demand

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
    if (editorAreaRef.current) {
      editorAreaRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const isNewProduceForPost = router?.query?.isNewProduceForPost;
    const isNewDemandForPost = router?.query?.isNewDemandForPost;
    if (isNewProduceForPost) {
      setOpenOfferDemandPostDialog(true);
    } else if (isNewDemandForPost) {
      setOpenDemandPostDialog(true);
    }
  }, []);
  //end here

  const handleCloseDialog = () => {
    setOpenSelectBuyerModal(false);
    setOpenOfferDemandPostDialog(false);
    setOpenDemandPostDialog(false);
  };

  const handleSliderChange = (e) => {
    setSliderImageFileVideoValue(e.target.value);
  };

  const onChecked = (checked, produce) => {
    if (checked) {
      setSelectedProduce((prev) =>
        [...prev].filter((curProduce) => curProduce.id !== produce.id)
      );
    } else {
      setSelectedProduce((prev) => [...prev, produce]);
    }
  };
  const addTextPost = async () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentAsText = currentContent.getPlainText();
    const currentContentAsHtml = stateToHTML(currentContent);

    // const isValidHTMl = ValidateHTML(currentContentAsHtml);

    // if (!isValidHTMl) {
    //   toastr.error("Content Link is not available,please check the link in the text.");
    // }else{
    const response = await createTextPost(
      currentContentAsText,
      currentContentAsHtml,
      user,
      sliderTextTextValue.label === "This post will not expire"
        ? null
        : sliderTextTextValue.label
    );
    setLoading(false);

    const newPost = response.posts[0];

    if (response) {
      setOpenTextChooseExpireModal(false);
      setSliderTextValue(16);
      const newGlobalFeedData = {
        ...globalFeedData,
        posts: [newPost, ...globalFeedData.posts],
      };
      getGlobalFeeds();
      setGlobalFeedData(newGlobalFeedData);
      setPostText("");
      setEditorState(EditorState.createEmpty());

      return await AmptitudeEvents("Timeline", user?.id, SOCIAL_FEED_TEXT_POST);
    }
    // }
  };
  const addVideoPost = async (videosData) => {
    setLoading(true);
    const currentContent = textAreaEditorState.getCurrentContent();
    const currentContentAsText = currentContent.getPlainText();
    const currentContentAsHtml = stateToHTML(currentContent);
    const response = await createVideoPost(
      videosData,
      currentContentAsText,
      currentContentAsHtml,
      user,
      sliderTextImageVideoFile.label === "This post will not expire"
        ? null
        : sliderTextImageVideoFile.label
    );
    setLoading(false);

    const newPost = response.posts[0];

    if (response) {
      setSliderImageFileVideoValue(16);
      const newGlobalFeedData = {
        ...globalFeedData,
        posts: [newPost, ...globalFeedData.posts],
      };
      getGlobalFeeds();

      setGlobalFeedData(newGlobalFeedData);

      setTextAreaEditorState(EditorState.createEmpty());
      setSelectedVideos([]);
      return await AmptitudeEvents(
        "Timeline",
        user?.id,
        SOCIAL_FEED_VIDEO_POST
      );
    }
  };
  const addImagePost = async (imagesData) => {
    setLoading(true);
    const currentContent = textAreaEditorState.getCurrentContent();
    const currentContentAsText = currentContent.getPlainText();
    const currentContentAsHtml = stateToHTML(currentContent);

    const response = await createImagePost(
      imagesData,
      currentContentAsText,
      currentContentAsHtml,
      user,
      sliderTextImageVideoFile.label === "This post will not expire"
        ? null
        : sliderTextImageVideoFile.label
    );
    setLoading(false);

    const newPost = response.posts[0];

    if (response) {
      setSliderImageFileVideoValue(16);
      const newGlobalFeedData = {
        ...globalFeedData,
        posts: [newPost, ...globalFeedData.posts],
      };
      getGlobalFeeds();
      setGlobalFeedData(newGlobalFeedData);

      setTextAreaEditorState(EditorState.createEmpty());
      setSelectedImages([]);
      return await AmptitudeEvents(
        "Timeline",
        user?.id,
        SOCIAL_FEED_IMAGE_POST
      );
    }
  };
  const addFilePost = async (filesData) => {
    setLoading(true);

    const currentContent = textAreaEditorState.getCurrentContent();
    const currentContentAsText = currentContent.getPlainText();
    const currentContentAsHtml = stateToHTML(currentContent);

    const response = await createFilePost(
      filesData,
      currentContentAsText,
      currentContentAsHtml,
      user,
      sliderTextImageVideoFile.label === "This post will not expire"
        ? null
        : sliderTextImageVideoFile.label
    );
    setLoading(false);

    const newPost = response.posts[0];

    if (response) {
      setSliderImageFileVideoValue(16);
      const newGlobalFeedData = {
        ...globalFeedData,
        posts: [newPost, ...globalFeedData.posts],
      };
      getGlobalFeeds();
      setGlobalFeedData(newGlobalFeedData);

      setTextAreaEditorState(EditorState.createEmpty());
      setSelectedFiles([]);
      return await AmptitudeEvents("Timeline", user?.id, SOCIAL_FEED_FILE_POST);
    }
  };

  const updatedImagePost = async (imagesData) => {
    const currentContent = textAreaEditorState.getCurrentContent();
    const currentContentAsText = currentContent.getPlainText();
    const currentContentAsHtml = stateToHTML(currentContent);
    const response = await updateImagePost(
      imagesData,
      currentContentAsText,
      currentContentAsHtml,
      user,
      postId,
      sliderTextImageVideoFile.label === "This post will not expire"
        ? null
        : sliderTextImageVideoFile.label
    );

    if (response) {
      setTextAreaEditorState(EditorState.createEmpty());
      setSliderImageFileVideoValue(16);
      const index = globalFeedData?.posts.findIndex(
        (item) => item?.postId === postId
      );

      let newPosts = { ...globalFeedData };

      const updatedPost = newPosts?.posts;

      updatedPost[index] = response?.posts[0];

      setGlobalFeedData(newPosts);

      setOpenImageModal(false);
    }
  };

  const updatedVideoPost = async (videosData) => {
    const currentContent = textAreaEditorState.getCurrentContent();
    const currentContentAsText = currentContent.getPlainText();
    const currentContentAsHtml = stateToHTML(currentContent);
    const response = await updateVideoPost(
      videosData,
      currentContentAsText,
      currentContentAsHtml,
      user,
      postId,
      sliderTextImageVideoFile.label === "This post will not expire"
        ? null
        : sliderTextImageVideoFile.label
    );

    if (response) {
      setTextAreaEditorState(EditorState.createEmpty());
      setSliderImageFileVideoValue(16);
      const index = globalFeedData?.posts.findIndex(
        (item) => item?.postId === postId
      );

      let newPosts = { ...globalFeedData };

      const updatedPost = newPosts?.posts;

      updatedPost[index] = response?.posts[0];

      setGlobalFeedData(newPosts);

      setOpenVideoModal(false);
    }
  };

  const updatedFilePost = async (filesData) => {
    const currentContent = textAreaEditorState.getCurrentContent();
    const currentContentAsText = currentContent.getPlainText();
    const currentContentAsHtml = stateToHTML(currentContent);
    const response = await updateFilePost(
      filesData,
      currentContentAsText,
      currentContentAsHtml,
      user,
      postId,
      sliderTextImageVideoFile.label === "This post will not expire"
        ? null
        : sliderTextImageVideoFile.label
    );

    if (response) {
      setTextAreaEditorState(EditorState.createEmpty());
      setSliderImageFileVideoValue(16);
      const index = globalFeedData?.posts.findIndex(
        (item) => item?.postId === postId
      );

      let newPosts = { ...globalFeedData };

      const updatedPost = newPosts?.posts;

      updatedPost[index] = response?.posts[0];

      setGlobalFeedData(newPosts);

      setOpenFileModal(false);
    }
  };

  const onSendOfferDemandPost = async (e) => {
    const currentContent = previewOfferEditorState.getCurrentContent();
    const currentContentAsText = currentContent.getPlainText();
    const currentContentAsHtml = stateToHTML(currentContent);
    setLoading(true);
    const itemPriceQuantity = selectedProduce?.map((item, index) => {
      return watchProduces?.find((produce, i) => i === index);
    });

    const newAddedPriceQuantityProduces = selectedProduce?.map(
      (item, index) => ({
        ...item,
        newQuantity: parseFloat(itemPriceQuantity?.[index]?.quantity),
        newPrice: itemPriceQuantity?.[index]?.price,
        newPriceOnRequest: itemPriceQuantity?.[index]?.priceOnRequest
          ? itemPriceQuantity?.[index]?.priceOnRequest
          : false,
      })
    );

    if (isPressedOfferPostMakeOffer) {
      const data = {
        buyerCompanyId: user?.companyId,

        offers: [
          {
            produces: newAddedPriceQuantityProduces?.map((item) => ({
              id: item?.id || item?._id,
              dealPriceIndication: parseFloat(item?.newPrice),
              quantity: parseFloat(item?.newQuantity),
            })),
          },
        ],
      };

      const newBuyerOffer = await addToOfferFromPost(data);
      setLoading(false);
      if (newBuyerOffer) {
        reset({
          selectedBuyer: {},
          produces: [],
        });
        setIsPressedOfferPostMakeOffer(false);
        setOpenDemandPostDialog(false);
        setEditedOfferDemandPostText("");
        setValue("produces", []);
        setOpenPreviewOfferPopup(false);
        setSelectedProduce([]);
        setOfferDemandPostText("");
        setOpenOfferDemandPostDialog(false);
      }
    } else {
      if (renderType === "yes") {
        if (isAddNew) {
          const data = {
            asASeller: true,

            buyerCompany: {
              companyName: watchCompanyName,
              email: watchEmail,
            },

            offers: [
              {
                produces: newAddedPriceQuantityProduces?.map((item) => ({
                  id: item?.id,
                  dealPriceIndication: parseFloat(item?.newPrice),
                  quantity: parseFloat(item?.newQuantity),
                })),
              },
            ],
          };

          const newOffer = await addOfferUnBoardingCompany(data);
          setLoading(false);

          if (newOffer) {
            reset({
              email: "",
              companyName: "",
              selectedBuyer: {},
              produces: [],
            });
            setIsAddNew(false);
            setValue("produces", []);
            setOpenPreviewOfferPopup(false);
            setOpenDemandPostDialog(false);
            setEditedOfferDemandPostText("");
            setOfferDemandPostText("");
            setOpenOfferDemandPostDialog(false);
            setSelectedProduce([]);
          }
        } else {
          const data = {
            asASeller: true,
            buyerCompanyId: watchSelectedBuyer?.id
              ? watchSelectedBuyer?.id
              : makeOfferBuyerCompanyId,
            offers: [
              {
                produces: newAddedPriceQuantityProduces?.map((item) => ({
                  id: item?.id,
                  dealPriceIndication: parseFloat(item?.newPrice),
                  quantity: parseFloat(item?.newQuantity),
                })),
              },
            ],
          };

          const newOffer = await addToOfferFromPost(data);
          setLoading(false);

          if (newOffer) {
            reset({
              selectedBuyer: {},
              produces: [],
            });
            setOpenDemandPostDialog(false);
            setEditedOfferDemandPostText("");
            setValue("produces", []);
            setOpenPreviewOfferPopup(false);
            setSelectedProduce([]);
            setOfferDemandPostText("");
            setOpenOfferDemandPostDialog(false);
          }
        }
      } else {
        const response = await createProduceDemandPost(
          openPreviewDemandPopup
            ? selectedProduce
            : newAddedPriceQuantityProduces,
          user,
          openPreviewDemandPopup ? "demand" : "offer",
          currentContentAsText,
          currentContentAsHtml,
          // editedOfferDemandPostText,
          sliderText.label === "This post will not expire"
            ? null
            : sliderText.label
        );
        setLoading(false);

        const newPost = response?.posts?.[0];

        if (response) {
          reset({
            selectedBuyer: {},
            produces: [],
          });
          setSliderValue(16);
          const newGlobalFeedData = {
            ...globalFeedData,
            posts: [newPost, ...globalFeedData.posts],
          };

          setGlobalFeedData(newGlobalFeedData);
          setOpenDemandPostDialog(false);
          editPreviewOfferEditorState(EditorState.createEmpty());
          setEditedOfferDemandPostText("");
          setValue("produces", []);
          setOfferDemandPostText("");
          setOpenPreviewOfferPopup(false);
          setOpenOfferDemandPostDialog(false);
          setSelectedProduce([]);
          return await AmptitudeEvents(
            "Timeline",
            user?.id,
            openPreviewDemandPopup
              ? SOCIAL_FEED_DEMAND_POST
              : SOCIAL_FEED_OFFER_POST
          );
        }
      }
    }
  };

  const uploadFile = async (e) => {
    setLoading(true);
    const response = await fileUpload(e);

    if (editFileVideoImage) {
      const fileIdImages = selectedImages?.filter((item) => item?.fileUrl);

      const newFileIdImages = fileIdImages?.concat(response ? response : []);
      updatedImagePost(newFileIdImages);

      setLoading(false);
      setSelectedImages([]);
      setPostTextArea("");
      setOpenImageModal(false);
    } else {
      addImagePost(response);
      setLoading(false);
      setPostTextArea("");
      setSelectedImages([]);

      setOpenImageModal(false);
    }
  };
  const uploadVideo = async (e) => {
    setLoading(true);
    const response = await videoUpload(e);

    if (editFileVideoImage) {
      const fileIdVideos = selectedVideos?.filter((item) => item?.fileUrl);

      const newFileIdVideos = fileIdVideos?.concat(response ? response : []);
      updatedVideoPost(newFileIdVideos);
      setLoading(false);
      setSelectedVideos([]);
      setPostTextArea("");
      setOpenVideoModal(false);
    } else {
      addVideoPost(response);
      setLoading(false);
      setSelectedVideos([]);
      setPostTextArea("");
      setOpenVideoModal(false);
    }
  };

  const uploadNewFile = async (e) => {
    setLoading(true);
    const response = await newFileUpload(e);

    if (editFileVideoImage) {
      const fileIdFiles = selectedFiles?.filter((item) => item?.fileUrl);

      const newFileIdFiles = fileIdFiles?.concat(response ? response : []);
      updatedFilePost(newFileIdFiles);
      setLoading(false);
      setPostTextArea("");
      setSelectedFiles([]);
      setOpenFileModal(false);
    } else {
      addFilePost(response);
      setSelectedFiles([]);
      setPostTextArea("");
      setLoading(false);
      setOpenFileModal(false);
    }
  };

  const handleBeforeInput = (chars) => {
    if (chars === " ") {
      const currentContent = editorState.getCurrentContent();
      const selection = editorState.getSelection();
      const block = currentContent.getBlockForKey(selection.getStartKey());
      const start = selection.getStartOffset();
      const end = selection.getEndOffset();
      const selectedText = block.getText().slice(start, end);

      if (selectedText === " " || selectedText === "") {
        const newContentState = Modifier.replaceText(
          currentContent,
          selection,
          " ",
          editorState.getCurrentInlineStyle()
        );
        const newEditorState = EditorState.push(
          editorState,
          newContentState,
          "insert-characters"
        );
        setEditorState(newEditorState);
        return "handled";
      }
    }
    return "not-handled";
  };

  const deleteImage = (fileName) => {
    const newImages = Array.from(selectedImages)?.filter((item) =>
      item?.fileUrl ? item.fileUrl !== fileName : item.name !== fileName
    );

    setSelectedImages([...newImages]);
  };
  const deleteVideo = (fileName) => {
    const newVideos = Array.from(selectedVideos)?.filter((item) =>
      item?.fileUrl ? item.fileUrl !== fileName : item.name !== fileName
    );

    setSelectedVideos([...newVideos]);
  };

  const deleteFile = (fileName) => {
    const newFiles = Array.from(selectedFiles)?.filter((item) =>
      item?.fileUrl ? item.fileUrl !== fileName : item.name !== fileName
    );

    setSelectedFiles([...newFiles]);
  };

  const onPressBuyOn = () => {
    if (!user?.id) {
      onNavigateLogin();
    } else if (user?.id && !user?.companyId) {
      router.push("/company-registration");
    } else {
      if (company?.id && company?.verifiedBuyer?.status === "APPROVED") {
        setRenderType("no");
        setIsPressedOfferPostMakeOffer(false);
        setOpenDemandPostDialog(true);
      } else {
        if (company?.verifiedBuyer?.status === "PENDING") {
          setOpenWaitForApprovalModal(true);
        } else {
          setOpenLoggedOrNotVerifyModal(true);
        }
      }
    }
  };

  const onPressSellOn = () => {
    if (!user?.id) {
      onNavigateLogin();
    } else if (user?.id && !user?.companyId) {
      router.push("/company-registration");
    } else {
      if (company?.id && company?.verifiedSeller?.status === "APPROVED") {
        setOpenSelectInvoiceModal(true);
        setOpenSelectBuyerModal(false);
        setIsPressedOfferPostMakeOffer(false);
        setSelectedProduce([]);
        setIsUpdate(false);
      } else {
        if (company?.verifiedSeller?.status === "PENDING") {
          setOpenWaitForApprovalModal(true);
        } else {
          // localStorage.setItem("sellOn", true);
          router.push("/my-company-profile");
        }
      }
    }
  };

  const OfferDemandPostDialogTitle = {
    offerHeader: "What are you Selling?",
    offerButtonText: "Next",
    demandHeader: "What are you Buying?",
    demandButtonText: "Next",
  };

  const OfferDemandIconTitle = {
    offer: "Share Product",
    demand: "Share Demand",
  };

  const renderPreviewOfferPopUp = () => {
    return (
      <>
        <PreviewOfferPopUp
          isIpdate={isUpdate}
          watchSelectedBuyer={watchSelectedBuyer}
          isPressedOfferPostMakeOffer={isPressedOfferPostMakeOffer}
          setIsPressedOfferPostMakeOffer={setIsPressedOfferPostMakeOffer}
          setIsAddNew={setIsAddNew}
          renderType={renderType}
          setRenderType={setRenderType}
          watch={watch}
          selectedProduce={selectedProduce}
          setValue={setValue}
          watchProduces={watchProduces}
          control={control}
          setOpenSelectBuyerModal={setOpenSelectBuyerModal}
          sliderText={sliderText}
          sliderOfferValue={sliderValue}
          setSliderOfferValue={setSliderValue}
          setGlobalFeedData={setGlobalFeedData}
          globalFeedData={globalFeedData}
          postId={postId}
          setPostId={setPostId}
          setIsUpdate={setIsUpdate}
          isUpdate={isUpdate}
          onSubmit={onSendOfferDemandPost}
          editedOfferDemandPostText={editedOfferDemandPostText}
          setEditedOfferDemandPostText={setEditedOfferDemandPostText}
          setOpenOfferDemandPostDialog={setOpenOfferDemandPostDialog}
          produces={selectedProduce}
          setProduces={setSelectedProduce}
          setOpenPreviewOfferPopup={setOpenPreviewOfferPopup}
          openPreviewOfferPopup={openPreviewOfferPopup}
          editorState={previewOfferEditorState}
          setEditorState={editPreviewOfferEditorState}
        />
      </>
    );
  };
  const renderPreviewDemandPopUp = () => {
    return (
      <>
        <PreviewDemandPopUp
          editorState={previewOfferEditorState}
          setEditorState={editPreviewOfferEditorState}
          sliderText={sliderText}
          setGlobalFeedData={setGlobalFeedData}
          sliderDemandValue={sliderValue}
          setSliderDemandValue={setSliderValue}
          globalFeedData={globalFeedData}
          postId={postId}
          setPostId={setPostId}
          setIsUpdate={setIsUpdate}
          isUpdate={isUpdate}
          onSubmit={onSendOfferDemandPost}
          editedOfferDemandPostText={editedOfferDemandPostText}
          setEditedOfferDemandPostText={setEditedOfferDemandPostText}
          setOpenDemandPostDialog={setOpenDemandPostDialog}
          produces={selectedProduce}
          setProduces={setSelectedProduce}
          setOpenPreviewDemandPopup={setOpenPreviewDemandPopup}
          openPreviewDemandPopup={openPreviewDemandPopup}
        />
      </>
    );
  };

  const renderUpdateTextPostPopUp = () => {
    return (
      <>
        <UpdateTextPostPopUp
          editorState={editorEditedTextState}
          setEditorState={setEditorEditedTextState}
          sliderTextTextValue={sliderTextTextValue}
          sliderTextValue={sliderTextValue}
          setSliderTextValue={setSliderTextValue}
          setGlobalFeedData={setGlobalFeedData}
          globalFeedData={globalFeedData}
          postId={postId}
          setPostId={setPostId}
          setIsUpdate={setIsUpdate}
          isUpdate={isUpdate}
          editedPostText={editedPostText}
          setEditedPostText={setEditedPostText}
          openUpdateTextPostPopup={openUpdateTextPostPopup}
          setOpenUpdateTextPostPopup={setOpenUpdateTextPostPopup}
        />
      </>
    );
  };

  const handleInputClick = () => {
    if (company?.id && company?.status !== "VERIFIED") {
      setOpenCompanyNotVerifiedModal(true);
    } else if (!user?.id) {
      router.push("/login");
    } else {
      setInputActive(true);
    }
  };

  return (
    <Box>
      {user?.id && !user?.companyId ? (
        <Box className={classes.notRegisteredBox}>
          <span className={classes.notCompleteText}>
            Registration not completed ! You have 2 more steps to unlock full
            potential of FruPro!
          </span>

          <div
            style={{ width: "100%" }}
            className="d-flex flex-row gap-2 justify-content-center mt-3"
          >
            <div
              onClick={() => router.push("/company-registration")}
              className={classes.continueRegister}
            >
              Continue Registation
            </div>
          </div>
        </Box>
      ) : (
        <>
          <Box>
            {!isInputActive ? (
              <Box
                style={{
                  width: "100%",
                  padding: "10px",
                  backgroundColor: "white",
                  borderRadius: "20px",
                }}
              >
                <Box onClick={handleInputClick}>
                  <input
                    type="text"
                    style={{
                      width: "100%",
                      border: "none",
                      height: "44px",
                      opacity: 0.3,
                    }}
                    placeholder="Start a new post.."
                  />
                </Box>
              </Box>
            ) : (
              <Grid item className={classes.postBox2}>
                <Box
                  style={{
                    backgroundColor: "white",
                    borderRadius: 20,
                    height: 198,
                  }}
                >
                  <Box className={classes.headerTextPostText}>
                    <Box className={classes.iconsContainer2}>
                      <div
                        style={{
                          cursor: "pointer",
                          float: "right",
                          fontSize: 12,
                          borderRadius: 12,
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        {
                          <label>
                            <img
                              onClick={() => {
                                !user?.id
                                  ? onNavigateLogin()
                                  : setOpenImageModal(true);
                                setOpenFileModal(false);
                                setOpenVideoModal(false);
                                setEditFileVideoImage(false);
                              }}
                              src="/static/svg/image-post-icon.svg"
                              style={{ cursor: "pointer" }}
                            />
                            {/* <input
                          type="file"
                          style={{ display: "none" }}
                          accept="image/*"
                          name="file"
                          multiple
                          onChange={(e) => {
                            setSelectedImages(e.target.files);
                          }}
                        /> */}
                          </label>
                        }
                      </div>
                      <div
                        style={{
                          cursor: "pointer",
                          float: "right",
                          fontSize: 12,
                          borderRadius: 12,
                          alignItems: "flex-end",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        {
                          <label>
                            <img
                              onClick={() => {
                                !user?.id
                                  ? onNavigateLogin()
                                  : setOpenVideoModal(true);
                                setOpenImageModal(false);
                                setOpenFileModal(false);
                                setEditFileVideoImage(false);
                              }}
                              src="/static/svg/video-post-icon.svg"
                              style={{ cursor: "pointer" }}
                            />
                            {/* <input
                          type="file"
                          style={{ display: "none" }}
                          accept="video/*"
                          name="file"
                          multiple
                          onChange={(e) => {
                            setSelectedVideos(e.target.files);
                          }}
                        /> */}
                          </label>
                        }
                      </div>
                      <div
                        style={{
                          cursor: "pointer",
                          float: "right",
                          fontSize: 12,
                          borderRadius: 12,
                          alignItems: "flex-end",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        {
                          <label>
                            <img
                              onClick={() => {
                                !user?.id
                                  ? onNavigateLogin()
                                  : setOpenFileModal(true);
                                setOpenVideoModal(false);
                                setOpenImageModal(false);
                                setEditFileVideoImage(false);
                              }}
                              src="/static/svg/file-post-icon.svg"
                              style={{ cursor: "pointer" }}
                            />
                            {/* <input
                          type="file"
                          style={{ display: "none" }}
                          accept=".pdf,.doc,.docx,.xlsx"
                          name="file"
                          multiple
                          onChange={(e) => {
                            setSelectedFiles(e.target.files);
                          }}
                        /> */}
                          </label>
                        }
                      </div>
                    </Box>
                    {/* <textarea
                      type="text"
                      maxLength="500"
                      rows={6}
                      name="startPost"
                      value={postText}
                      onChange={(e) => {
                        setPostText(e.target.value);
                      }}
                      className={classes.textField}
                      style={{
                        opacity: 0.6,
                        border: "none",
                        fontSize: 16,
                        width: "80%",
                        resize: "none",
                        overflow: "hidden",
                      }}
                      placeholder={"Start a new post..."}
                    /> */}
                    <div
                      className={classes.textField}
                      onClick={() => editorRef.current.focus()}
                    >
                      <TextAreaEditor
                        editorState={editorState}
                        setEditorState={setEditorState}
                        editorRef={editorRef}
                        placeholder="Start a new post..."
                      />
                    </div>
                  </Box>

                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      padding: "0px 20px",
                    }}
                  >
                    <hr
                      style={{
                        opacity: 0.1,
                        alignItems: "center",
                        width: "100%",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    ></hr>
                  </Box>

                  <Box className={classes.textIconCounter}>
                    <Box
                      className={classes.textCounter}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ opacity: 0.3, fontSize: 16 }}>
                        {editorState.getCurrentContent().getPlainText().length}
                        /500
                      </p>
                      <p
                        onClick={() => setPostText("")}
                        style={{
                          color: "#F6133D",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        Clear
                      </p>
                    </Box>

                    <Box
                      onClick={() => {
                        !user?.id
                          ? onNavigateLogin()
                          : setOpenTextChooseExpireModal(true);
                      }}
                      className={classes.sendButton2}
                    >
                      Post
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
          </Box>

          <Box
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              marginTop: 20,
            }}
          >
            <Box
              onClick={onPressBuyOn}
              className={
                !company?.id
                  ? classes.buyButton
                  : company?.verifiedBuyer?.status !== "APPROVED"
                  ? classes.buyButton
                  : classes.buyButton
              }
            >
              Buy on FruPro
            </Box>
            <Box
              onClick={onPressSellOn}
              className={
                !company?.id
                  ? classes.sellButton
                  : company?.verifiedSeller?.status !== "APPROVED"
                  ? classes.sellButton
                  : classes.sellButton
              }
            >
              Sell on FruPro
            </Box>
          </Box>
        </>
      )}

      {renderPreviewOfferPopUp()}
      {renderPreviewDemandPopUp()}
      {openOfferDemandPostDialog || openDemandPostDialog ? (
        <OfferDemandDialogWithSearch
          setIsAddNew={setIsAddNew}
          setValue={setValue}
          setOpenSelectBuyerModal={setOpenSelectBuyerModal}
          shareLoading={loading}
          open={openOfferDemandPostDialog}
          openDemandDialog={openDemandPostDialog}
          setOpen={setOpenOfferDemandPostDialog}
          handleClose={handleCloseDialog}
          onSubmit={onSendOfferDemandPost}
          setOpenOfferDemandPostDialog={setOpenOfferDemandPostDialog}
          openPreviewDemandPopup={openPreviewDemandPopup}
          openPreviewOfferPopup={openPreviewOfferPopup}
          setOpenPreviewDemandPopup={setOpenPreviewDemandPopup}
          setOpenPreviewOfferPopup={setOpenPreviewOfferPopup}
          getData={
            openOfferDemandPostDialog ? getMyProduces : getMyDemandProduces
          }
          selecteds={selectedProduce}
          setSelecteds={setSelectedProduce}
          title={
            openOfferDemandPostDialog
              ? OfferDemandPostDialogTitle.offerHeader
              : OfferDemandPostDialogTitle.demandHeader
          }
          submitButtonText={
            openOfferDemandPostDialog
              ? OfferDemandPostDialogTitle.offerButtonText
              : OfferDemandPostDialogTitle.demandButtonText
          }
          renderItems={(produceItem) =>
            produceItem.status === "Available" ? (
              <AddOfferDemandItem
                openDemandDialog={openDemandPostDialog}
                loading={loading}
                openOfferDemandPostDialog={openOfferDemandPostDialog}
                key={produceItem?.id}
                selectedProduce={selectedProduce}
                produceItem={produceItem}
                onChecked={onChecked}
                setSelectedProduce={setSelectedProduce}
              />
            ) : null
          }
        />
      ) : null}

      <SelectBuyerModal
        isAddNew={isAddNew}
        setIsAddNew={setIsAddNew}
        handleSubmit={handleSubmit}
        errors={errors}
        watchCompanyName={watchCompanyName}
        watchEmail={watchEmail}
        renderType={renderType}
        setRenderType={setRenderType}
        setValue={setValue}
        setSelectedProduce={setSelectedProduce}
        setOpenPreviewOfferPopup={setOpenPreviewOfferPopup}
        watchSelectedBuyer={watchSelectedBuyer}
        control={control}
        setOpenOfferDemandPostDialog={setOpenOfferDemandPostDialog}
        setOpenSelectBuyerModal={setOpenSelectBuyerModal}
        openSelectBuyerModal={openSelectBuyerModal}
      />
      <SelectInvoiceModal
        isAddNew={isAddNew}
        setIsAddNew={setIsAddNew}
        handleSubmit={handleSubmit}
        errors={errors}
        watchCompanyName={watchCompanyName}
        watchEmail={watchEmail}
        renderType={renderType}
        setRenderType={setRenderType}
        setValue={setValue}
        setSelectedProduce={setSelectedProduce}
        setOpenPreviewOfferPopup={setOpenPreviewOfferPopup}
        watchSelectedBuyer={watchSelectedBuyer}
        control={control}
        setOpenOfferDemandPostDialog={setOpenOfferDemandPostDialog}
        setOpenSelectBuyerModal={setOpenSelectBuyerModal}
        openSelectBuyerModal={openSelectBuyerModal}
        setOpenSelectInvoiceModal={setOpenSelectInvoiceModal}
        openSelectInvoiceModal={openSelectInvoiceModal}
        setInvoiceUrl={setInvoiceUrl}
        setInvoiceRenderType={setInvoiceRenderType}
        invoiceRenderType={invoiceRenderType}
      />
      {loading ? (
        <LoadingIndicator loading />
      ) : (
        <Modal
          contentClassName={classes.imageModal}
          show={openImageModal || openVideoModal || openFileModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <>
              <Box
                style={{
                  padding: "0 20px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{ color: "#E77228", fontSize: 24, fontWeight: 600 }}
                >
                  {editFileVideoImage ? "Update Post" : " New Post"}
                </Typography>
                <img
                  onClick={() => {
                    setSelectedImages([]);
                    setSelectedVideos([]);
                    setSelectedFiles([]);
                    setOpenFileModal(false);
                    setOpenVideoModal(false);
                    setOpenImageModal(false);
                  }}
                  style={{ cursor: "pointer" }}
                  src="/static/svg/Close_round_delete.svg"
                />
              </Box>
              <Box className={classes.imageContainer}>
                {openImageModal ? (
                  <>
                    {Array.from(selectedImages).map((item) => {
                      return (
                        <>
                          <Box>
                            <label>
                              <div
                                onClick={() =>
                                  deleteImage(
                                    item?.fileUrl ? item?.fileUrl : item.name
                                  )
                                }
                              >
                                <img
                                  style={{
                                    top: 35,
                                    position: "relative",
                                    marginLeft: 120,
                                    width: 30,
                                    height: 30,
                                    cursor: "pointer",
                                  }}
                                  src="/static/svg/Close_round_delete.svg"
                                />
                              </div>

                              <img
                                style={{
                                  border: "0.2px solid gray",
                                  borderRadius: 20,
                                  gap: 10,
                                  width: 155,
                                  height: 155,
                                }}
                                src={
                                  item.fileUrl
                                    ? `${item?.fileUrl}?size=large`
                                    : URL.createObjectURL(item)
                                }
                              />
                            </label>
                          </Box>
                        </>
                      );
                    })}
                    <Box>
                      <label>
                        <Box className={classes.AddMoreImageContainer}>
                          <img
                            src="/static/svg/Img_load-box_light.svg"
                            style={{
                              margin: "0 auto",
                              cursor: "pointer",
                              width: 36,
                              height: 36,
                            }}
                            className={classes.AddMoreImage}
                          />
                          <p style={{ opacity: 0.3, cursor: "pointer" }}>
                            Upload an image
                          </p>
                        </Box>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          accept="image/*"
                          name="file"
                          multiple
                          onChange={(e) => {
                            // Array.from(e.target?.files).forEach((item) => {
                            // setSelectedImages([...selectedImages, item]);
                            // });

                            const newImages = Array.from(e.target.files).concat(
                              Array.from(selectedImages)
                            );

                            setSelectedImages([...newImages]);
                          }}
                        />
                      </label>
                    </Box>
                  </>
                ) : (
                  <>
                    {openFileModal ? (
                      <>
                        {Array.from(selectedFiles).map((item) => {
                          let type = "pdf";
                          let { name = "pdf" } = item?.attributes || item;
                          if (name?.includes(".pdf")) type = "pdf";
                          else if (name?.includes(".xlsx")) type = "xls";
                          else if (name?.includes(".xls")) type = "xls";
                          else if (name?.includes(".doc")) type = "doc";
                          else if (name?.includes(".docx")) type = "doc";
                          return (
                            <span>
                              <div
                                onClick={() =>
                                  deleteFile(
                                    item?.fileUrl ? item?.fileUrl : item.name
                                  )
                                }
                              >
                                <img
                                  style={{
                                    top: 30,
                                    position: "relative",
                                    marginLeft: 120,
                                    width: 30,
                                    height: 30,
                                    cursor: "pointer",
                                  }}
                                  src="/static/svg/Close_round_delete.svg"
                                />
                              </div>
                              <div
                                style={{
                                  border: "0.2px solid gray",
                                  borderRadius: 20,
                                  display: "flex",
                                  padding: 10,
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: 10,
                                  minHeight: 155,
                                  width: 155,
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}

                                // src={
                                // item?.fileUrl
                                // ? `${item?.fileUrl}?size=large`
                                // : URL.createObjectURL(item)
                                // }
                              >
                                {item?.fileUrl
                                  ? item?.attributes?.name
                                  : item?.name}

                                <img
                                  style={{
                                    width: 20,
                                    height: 20,
                                    marginLeft: 10,
                                  }}
                                  src={`/static/svg/ic_file_${type}.svg`}
                                  alt="ic_file_pdf"
                                />
                              </div>
                            </span>
                          );
                        })}

                        <Box>
                          <label>
                            <Box className={classes.AddMoreImageContainer}>
                              <img
                                src="/static/svg/Img_load-box_light.svg"
                                style={{
                                  margin: "0 auto",
                                  cursor: "pointer",
                                  width: 36,
                                  height: 36,
                                }}
                                className={classes.AddMoreImage}
                              />
                              <p style={{ opacity: 0.3, cursor: "pointer" }}>
                                Upload a file
                              </p>
                            </Box>
                            <input
                              type="file"
                              style={{ display: "none" }}
                              accept=".pdf,.doc,.docx,.xlsx"
                              name="file"
                              multiple
                              onChange={(e) => {
                                // Array.from(e.target?.files).forEach((item) => {
                                // setSelectedImages([...selectedImages, item]);
                                // });

                                const newFiles = Array.from(
                                  e.target.files
                                ).concat(Array.from(selectedFiles));

                                setSelectedFiles([...newFiles]);
                              }}
                            />
                          </label>
                        </Box>
                      </>
                    ) : (
                      <>
                        {openVideoModal &&
                          Array.from(selectedVideos).map((item) => {
                            return (
                              <span>
                                <div
                                  onClick={() =>
                                    deleteVideo(
                                      item?.fileUrl ? item?.fileUrl : item.name
                                    )
                                  }
                                >
                                  <img
                                    style={{
                                      top: 18,
                                      position: "relative",
                                      marginLeft: 130,
                                      width: 30,
                                      height: 30,
                                      cursor: "pointer",
                                    }}
                                    src="/static/svg/Close_round_delete.svg"
                                  />
                                </div>
                                <video
                                  style={{
                                    border: "0.2px solid gray",
                                    borderRadius: 20,
                                    gap: 10,
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  width="155px"
                                  height="155px"
                                  controls
                                  src={
                                    item?.fileUrl
                                      ? `${item?.fileUrl}?size=large`
                                      : URL.createObjectURL(item)
                                  }
                                />
                              </span>
                            );
                          })}

                        <Box>
                          <label>
                            <Box className={classes.AddMoreImageContainer}>
                              <img
                                src="/static/svg/Img_load-box_light.svg"
                                style={{
                                  margin: "0 auto",
                                  cursor: "pointer",
                                  width: 36,
                                  height: 36,
                                }}
                                className={classes.AddMoreImage}
                              />
                              <p style={{ opacity: 0.3, cursor: "pointer" }}>
                                Upload a video
                              </p>
                            </Box>
                            <input
                              type="file"
                              style={{ display: "none" }}
                              accept="video/*"
                              name="file"
                              multiple
                              onChange={(e) => {
                                // Array.from(e.target?.files).forEach((item) => {
                                // setSelectedImages([...selectedImages, item]);
                                // });

                                const newVideos = Array.from(
                                  e.target.files
                                ).concat(Array.from(selectedVideos));

                                setSelectedVideos([...newVideos]);
                              }}
                            />
                          </label>
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Box>
              <Typography
                onClick={() => {
                  setSelectedImages([]);
                  setSelectedVideos([]);
                  setSelectedFiles([]);
                }}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "rgba(231, 40, 40, 1)",
                  padding: "30px 30px",
                }}
              >
                Remove All
              </Typography>
              {/* <TextField
                defaultValue={postTextArea}
                inputProps={{ maxLength: 500 }}
                value={postTextArea}
                onChange={(e) => setPostTextArea(e.target.value)}
                style={{ width: "87%", marginLeft: 30 }}
                InputProps={{
                  disableUnderline: true,
                  className: classes.Input,
                  endAdornment: (
                    <>
                      <Typography
                        style={{
                          left: 10,
                          bottom: 10,
                          position: "absolute",
                          color: "gray",
                        }}
                      >
                        {postTextArea?.length}/500
                      </Typography>
                    </>
                  ),
                  classes: {
                    input: classes.customTextField,
                  },
                }}
                multiline
                rows={9}
                placeholder="Add text..."
              /> */}
              <div
                className={classes.Input}
                onClick={() => editorAreaRef.current.focus()}
              >
                <TextAreaEditor
                  editorState={textAreaEditorState}
                  setEditorState={setTextAreaEditorState}
                  editorRef={editorAreaRef}
                />
              </div>
              <Typography
                style={{
                  left: 50,
                  bottom: 80,
                  position: "absolute",
                  color: "gray",
                }}
              >
                {textAreaEditorState.getCurrentContent().getPlainText().length}
                /500
              </Typography>
              <div
                className={` ${classes.sliderBox}  d-flex mt-5 justify-content-start align-items-start flex-column`}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "92%",
                  }}
                >
                  <FormLabel
                    style={{
                      fontSize: 12,
                      color: "#000000",
                    }}
                  >
                    How long will this post remain available?
                  </FormLabel>
                  <span style={{ fontSize: 10, marginRight: 10 }}>
                    {sliderTextImageVideoFile?.label}
                  </span>
                </div>

                <PrettoSlider
                  aria-label="Custom marks"
                  getAriaValueText={valuetext}
                  style={{ width: "90%" }}
                  value={sliderImageFileVideoValue}
                  onChange={handleSliderChange}
                  defaultValue={16}
                  max={16}
                  step={null}
                  marks={marksSlider}
                />
              </div>

              {/* <Box className={classes.postTextContainer}>
<textarea
className={classes.inputText}
value={postTextArea}
rows={5}
placeholder="Please write something for your post."
onChange={(e) => setPostTextArea(e.target.value)}
/>
</Box> */}
            </>
          </Modal.Body>

          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 20,
              justifyContent: "center",
              marginBottom: 30,
            }}
          >
            <Button
              className={classes.cancelButton}
              onClick={() => {
                setOpenImageModal(false);
                setPostTextArea("");
                setOpenFileModal(false);
                setOpenVideoModal(false);
                setSelectedImages([]);
                setSelectedVideos([]);
                setSelectedFiles([]);
                setTextAreaEditorState(EditorState.createEmpty());
              }}
            >
              Cancel
            </Button>
            <Button
              className={classes.saveButton}
              disabled={loading}
              onClick={() => {
                selectedImages.length > 0
                  ? uploadFile(selectedImages)
                  : selectedFiles.length > 0
                  ? uploadNewFile(selectedFiles)
                  : selectedVideos?.length
                  ? uploadVideo(selectedVideos)
                  : null;
              }}
            >
              Share post
            </Button>
          </Box>
        </Modal>
      )}
      <TextPostChooseExpireModal
        addTextPost={addTextPost}
        sliderTextTextValue={sliderTextTextValue}
        sliderTextValue={sliderTextValue}
        setSliderTextValue={setSliderTextValue}
        openModal={openTextChooseExpireModal}
        setOpenModal={setOpenTextChooseExpireModal}
      />
      {renderUpdateTextPostPopUp()}
      <WaitForApprovalModal
        openModal={openWaitForApprovalModal}
        setOpenModal={setOpenWaitForApprovalModal}
      />
      <OpenLoggedOrNotVerifyModal
        user={user}
        openModal={openLoggedOrNotVerifyModal}
        setOpenModal={setOpenLoggedOrNotVerifyModal}
      />
      <CompanyNotVerifiedModal
        openModal={openCompanyNotVerifiedModal}
        setOpenModal={setOpenCompanyNotVerifiedModal}
      />
    </Box>
  );
};

export default AddpostItem;
