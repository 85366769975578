import React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import styles from "./Dialog.module.scss";
import { FruProButton } from "@components";

const useStyles = makeStyles(() => ({
  subtitle: {
    "& > *": {
      fontFamily: '"Roboto", sans-serif',
      fontSize: "16px",
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "center",
      textTransform: "none",
      marginTop: "4px !important",
      marginBottom: "0px !important",
      color: "#333",
      lineHeight: "1.5",
      fontWeight: 400,
    },
  },
  ml: {
    borderRadius: "20px!important",
  },
  modalFooter: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "16px",
    // backgroundColor: "#eeeeee",
    "& button ~ button": {
      marginLeft: "16px",
    },
  },
  centerFooter: {
    justifyContent: "center",
  },
 
}));

export default function FruProDialog({
  open,
  title,
  titleBorder,
  closeIcon,
  onClose,
  size,
  width,
  buttons,
  children,
  subtitle,
  customButtons,
  buttonCenter,
  ...x
}) {
  const classes = useStyles();

  const renderSizeClass = () => {
    if (size == "xsmall") {
      return styles.modalContainerXSm;
    } else if (size == "small") {
      return styles.modalContainerSm;
    } else if (size == "large") {
      return styles.modalContainerlg;
    } else if (size == "xlarge") {
      return styles.modalContainerXl;
    } else {
      return styles.modalContainerMd;
    }
  };

  const renderButtons = () => {
    if (buttons) {
      return buttons.map((button, index) => {
        const { text, color, disabled, onClick, ...other } = button;

        return (
          <FruProButton
            key={index}
            color={color}
            onClick={() => onClick()}
            disabled={disabled}
            className={classes.ml}
            {...other}
          >
            {text}
          </FruProButton>
        );
      });
    } else if (customButtons) {
      return customButtons;
    }
  };
  const renderCloseIcon = () => {
    if (closeIcon) {
      return (
        <IconButton onClick={onClose} className={styles.close}>
          <CloseIcon />
        </IconButton>
      );
    }
    return "";
  };

  const renderTitle = () => {
    if (title)
      return (
        <div
          id="simple-dialog-title"
          className={clsx(
            styles.modalTitle,
            titleBorder ? styles.modalTitleBorder : ""
          )}
        >
          <>
            <Typography variant="h6">{title}</Typography>
            {renderSubtitle()}
            {renderCloseIcon()}
          </>
        </div>
      );
  };

  const renderSubtitle = () => {
    if (subtitle)
      return (
        <div className={classes.subtitle}>
          <div>{subtitle}</div>
        </div>
      );
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => onClose()}
        classes={{
          container: styles.modal,
          paper: clsx(styles.modalContainer, renderSizeClass()),
        }}
        {...x}
      >
        {renderTitle()}
        <DialogContent className={styles.modalContent}>
          {children}
        </DialogContent>
        <div
          className={clsx(classes.modalFooter, {
            [classes.centerFooter]: buttonCenter,
          })}
        >
          {renderButtons()}
        </div>
      </Dialog>
    </>
  );
}
