import React, { useRef, useState } from "react";
import { EditorState, Modifier, Editor, RichUtils } from "draft-js";
import "draft-js/dist/Draft.css";

const TextAreaEditor = ({
  editorState,
  setEditorState,
  editorRef,
  placeholder,
  maxLength,
}) => {
  const [max, setMax] = useState(500);

  // CONFIG
  const MAX_LENGTH = max;

  // DATA & FUNCTIONS
  const currentCount = editorState.getCurrentContent().getPlainText().length;

  const __handlePastedText = (pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = _getLengthOfSelectedText();
    if (pastedText === " ") {
      const selection = editorState.getSelection();
      const block = currentContent.getBlockForKey(selection.getStartKey());
      const start = selection.getStartOffset();
      const end = selection.getEndOffset();
      const selectedText = block.getText().slice(start, end);

      const currentContentLength = currentContent.getPlainText("").length;
      if (currentContentLength + chars.length > 500) {
        return "handled";
      }

      if (selectedText === " " || selectedText === "") {
        const newContentState = Modifier.replaceText(
          currentContent,
          selection,
          " ",
          editorState.getCurrentInlineStyle()
        );
        const newEditorState = EditorState.push(
          editorState,
          newContentState,
          "insert-characters"
        );
        setEditorState(newEditorState);
        return "handled";
      }
    }
    if (
      currentContentLength + pastedText.length - selectedTextLength >
      MAX_LENGTH
    ) {
      const selection = editorState.getSelection();
      const isCollapsed = selection.isCollapsed();
      const tempEditorState = !isCollapsed ? _removeSelection() : editorState;
      _addPastedContent(pastedText, tempEditorState);

      return "handled";
    }
    return "not-handled";
  };

  const _addPastedContent = (input, editorState) => {
    const inputLength = editorState.getCurrentContent().getPlainText().length;
    let remainingLength = MAX_LENGTH - inputLength;

    const newContent = Modifier.insertText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      input.slice(0, remainingLength)
    );
    setEditorState(
      EditorState.push(editorState, newContent, "insert-characters")
    );
  };
  const _removeSelection = () => {
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const endKey = selection.getEndKey();
    const endOffset = selection.getEndOffset();
    if (startKey !== endKey || startOffset !== endOffset) {
      const newContent = Modifier.removeRange(
        editorState.getCurrentContent(),
        selection,
        "forward"
      );
      const tempEditorState = EditorState.push(
        editorState,
        newContent,
        "remove-range"
      );
      setEditorState(tempEditorState);
      return tempEditorState;
    }
    return editorState;
  };
  const __handleBeforeInput = () => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
     

      return "handled";
    }
    return "not-handled";
  };

  const _getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  const handleKeyCommandArea = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };
  const customStyleMap = {
    HIGHLIGHT: {
      backgroundColor: "yellow", // "HIGHLIGHT" stilini sarı arka plan olarak belirler
    },
  };

  return (
    <Editor
      ref={editorRef}
      editorState={editorState}
      onChange={setEditorState}
      handleBeforeInput={__handleBeforeInput}
      handlePastedText={__handlePastedText}
      handleKeyCommand={handleKeyCommandArea}
      placeholder={placeholder}
      customStyleMap={customStyleMap}
    />
  );
};

export default TextAreaEditor;
