import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button } from "@material-ui/core";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterIcon,
  TwitterShareButton,
  EmailIcon,
  EmailShareButton,
} from "react-share";
import { setNewChatInfo } from "@reducers/chat";
import { useDispatch } from "react-redux";


const useStyles = makeStyles({
  modalBody: {
  
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "hidden",
    width: "707px",
    height: "302px",
    backgroundColor: "#ffff",
    padding: 40,
    borderRadius: "30px",
    gap: "10px",
    boxShadowFilter: "blur(8px)",
    border: "none !important",
  },
  iconBody: {
    margin: "auto",
    textAlign: "center",
    padding: 20,
    display: "flex",
    justifyContent: "space-around",
    border: "none",
    alignContent: "center",
  },
  shareButton: {
    marginBottom: "20px",
  },
});

export default function PostShareModal({
  setOpenPostShareModal,
  openPostShareModal,
  postId,
  post,
}) {
  //Share Linke Area!***>>>>>>>>>>>>>>>>*/
  const classes = useStyles();
  const emailShareUrl = `${location.protocol}//${location.hostname}${
    location.port ? ":" + location.port : ""
  }/post-share?id=${postId}`;
  const facebookShareUrl = `${location.protocol}//${location.hostname}${
    location.port ? ":" + location.port : ""
  }/post-share?id=${postId}`;
  const linkedinShareUrl = `${location.protocol}//${location.hostname}${
    location.port ? ":" + location.port : ""
  }/post-share?id=${postId}`;
  const twitterfShareUrl = `${location.protocol}//${location.hostname}${
    location.port ? ":" + location.port : ""
  }/post-share?id=${postId}`;

  const [copied, setCopied] = useState(false);

  const userData = post?.metadata?.user;

  //button click 'copied' Message!***>>>>>>>>>>>>>>>>*/
  const handleCopy = () => {
    navigator.clipboard.writeText(
      `${location.protocol}//${location.hostname}${
        location.port ? ":" + location.port : ""
      }/post-share?id=${postId}`
    );
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  //Share to Direct Message Start!***>>>>>>>>>>>>>>>>*/

  const dispatch = useDispatch();
  const [openNewChat, setOpenNewChat] = useState(false);
  useEffect(() => {
    if (openNewChat) {
      setOpenPostShareModal(false);
    }
  }, [openNewChat, setOpenPostShareModal]);

  return (
    <Modal
      className={classes.modalBody}
      show={openPostShareModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      <Box>
        <Box>
          <img
            className="float-end"
            onClick={() => setOpenPostShareModal(false)}
            style={{
              cursor: "pointer",
              width: 20,
              height: 20,
              background: "#0000",
              zIndex: 1,
              alignItems: "self-end",
            }}
            src="./static/svg/close-pointer.png"
          />
        </Box>
        <Typography
          style={{
            fontSize: "24px",
            margin: "10px",
            color: "#E77228",
            fontWeight: "600",
          }}
        >
          Share Post
        </Typography>
        <Box className={classes.iconBody}>
          <div>
            <Button variant="text" color="default" onClick={handleCopy}>
              <img src="./static/svg/arrow-up-right-from-square-solid.svg" />
            </Button>
            <Typography style={{ fontSize: "11px" }}>
              {copied ? "Copied!" : "Copy link"}
            </Typography>
          </div>

          {/* Share To Direct Message-->>>>>>>>>>>>>> */}
          <div>
            <Button variant="text" color="default">
              <img
                src="./static/svg/paper-plane-regular.svg"
                onClick={() => {
                  dispatch(
                    setNewChatInfo({
                      companyId: userData?.companyId,
                      email: userData?.email,
                      fullName: userData?.fullName,
                      id: userData?.id,
                      photo: userData?.photo,
                      productAttachment: post,
                      type: post,
                      companyName: userData?.company?.companyName,
                      isBroadcast: true,
                    })
                  );
                }}
              />
            </Button>
            <Typography style={{ fontSize: "11px" }}>
              Share to message
            </Typography>
            <Typography style={{ fontSize: "11px" }}></Typography>
          </div>

          {/* Social Share Buttons***>>>>>>>>>>>>>>>> */}
          <EmailShareButton
            className="shareButton"
            url={emailShareUrl}
            quote={"Share Facebook"}
            hashtag={"#frupro"}
          >
            <EmailIcon size={60} round={true} />
            <Typography style={{ fontSize: "11px" }}>Email</Typography>
          </EmailShareButton>
          <FacebookShareButton
            className="shareButton"
            url={facebookShareUrl}
            quote={"Share Facebook"}
            hashtag={"#frupro"}
          >
            <FacebookIcon size={60} round={true} />
            <Typography style={{ fontSize: "11px" }}>Facebook</Typography>
          </FacebookShareButton>
          <LinkedinShareButton
            className="shareButton"
            url={linkedinShareUrl}
            quote={"Share linkedin"}
            hashtag={"#frupro"}
          >
            <LinkedinIcon size={60} round={true} />
            <Typography style={{ fontSize: "11px" }}>Linkedin</Typography>
          </LinkedinShareButton>
          <TwitterShareButton
            className="shareButton"
            url={twitterfShareUrl}
            quote={"Share Facebook"}
            hashtag={"#frupro"}
          >
            <TwitterIcon size={60} round={true} />
            <Typography style={{ fontSize: "11px" }}>Twitter</Typography>
          </TwitterShareButton>
        </Box>
      </Box>
    </Modal>
  );
}
