import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  InputAdornment,
  TextField,
  IconButton,
  DialogActions,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { FruProButton } from "@components";
import { debounce } from "lodash";
import {
  useDetectScrolledToBottom,
  useTableFetch,
  useShallowRouting,
} from "@utils/customHook";
import clsx from "clsx";
import { useSelector } from "react-redux";
import PreviewOfferPopUp from "./PreviewOfferPopUp";
import PreviewDemandPopUp from "./PreviewDemandPopUp";
import { useRouter } from "next/router";
const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    marginBottom: 16,
  },
  dialogContent: {
    width: "100%",
    height: 450,
    overflowY: "auto",
    padding: 0,
    paddingLeft: 16,
  },
  contentRows: {
    height: "100%",
    paddingRight: 16,
    "& p": {
      margin: 0,
    },
    "& .MuiCheckbox-root": {
      height: "fit-content",
      paddingRight: 16,
      "& svg": {
        width: 20,
        height: 20,
      },
    },
  },
  contentSearch: {
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& > .MuiInputBase-root": {
      height: 46,
      borderRadius: 35,
      border: "1px solid #69DCAE",
      background: "white",
      color: "#151B22",
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 16,
    },
  },
  demandContentSearch: {
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& > .MuiInputBase-root": {
      height: 46,
      borderRadius: 35,
      border: "1px solid #E77228",
      background: "white",
      color: "#151B22",
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 16,
    },
  },
  dialogTitle: {
    padding: "16px",
  },
  resetBtn: {
    padding: 0,
    minWidth: "unset",
    marginLeft: 8,
  },
  nextButton: {
    padding: "12px 24px",
    fontSize: "17px !important",
    fontWeight: 500,
    color: "white",
    backgroundColor: "#69DCAE !important",
    borderRadius: "20px !important",
    minWidth: "235px !important",
    maxHeight: 44,
  },
  dialogButtonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 16,
    marginTop: 16,
  },
  demandNextButton: {
    padding: "12px 24px",
    fontSize: "17px !important",
    fontWeight: 500,
    color: "white",
    backgroundColor: "#E77228 !important",
    borderRadius: "20px !important",
    minWidth: "235px !important",
    maxHeight: 44,
  },
  button: {
    padding: "12px 24px",
    fontSize: "17px !important",
    fontWeight: 500,
    color: "#69DCAE",
    borderRadius: "20px !important",
    minWidth: "235px !important",
    maxHeight: 44,
  },
  cancelButton: {
    background: "#ffffff",
    border: `1px solid #69DCAE`,
    color: "#69DCAE",
    textTransform: "capitalize",
    marginRight: 8,
  },
  demandCancelButton: {
    background: "#ffffff",
    border: `1px solid #E77228`,
    color: "#E77228",
    textTransform: "capitalize",
    marginRight: 8,
  },
  searchContainer: {
    alignContent: "center !important",
    alignItems: "center !important",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 16,
    marginBottom: 16,
  },
  searchButton: {
    backgroundColor: "#69DCAE",
    width: 65,
    height: 50,
    borderRadius: 35,
  },
  demandSearchButton: {
    backgroundColor: "#E77228",
    width: 65,
    height: 50,
    borderRadius: 35,
  },
  addNewButton: {
    background: "#69CDAE",
    color: "white",
    padding: 7,
    cursor: "pointer",
    width: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    borderRadius: "20px!important",
  },
  addNewDemandButton: {
    width: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#E77228",
    color: "white",
    padding: 7,
    cursor: "pointer",

    borderRadius: "20px!important",
  },
  noItemAddNewButton: {
    borderRadius: "20px!important",
    width: "93%",
    background: "#E77228!important",
    fontSize: "16px!important",
  },
  noItemAddNewDemandButton: {
    borderRadius: "20px!important",
    width: "100%",
    fontSize: "16px!important",
    background: "#69CDAE!important",
  },
}));

export default function OfferDemandDialogWithSearch({
  open,
  setIsAddNew,
  handleClose,
  setValue,
  setOpenOfferDemandPostDialog,
  getData,
  openDemandDialog,
  onSubmit,
  renderItems,
  shareLoading,
  selecteds,
  title,
  submitButtonText,
  openPreviewOfferPopup,
  openPreviewDemandPopup,
  setOpenSelectBuyerModal,
  setOpenPreviewDemandPopup,
  setOpenPreviewOfferPopup,
}) {
  const classes = useStyles();
  const listRef = useRef();
  const producesRef = useRef();

  const appRouter = useRouter();
  const [keyword, setKeyWord] = useState("");

  const [loading, setLoading] = useState(false);
  const [filteredProduces, setFilteredProduces] = useState([]);
  const [produces, setProduces] = useState([]);
  producesRef.current = produces;

  const [queryParams, setQueryParams] = useState({});
  const user = useSelector((state) => state.auth.user);

  const [router, shallowQuery, onChangeShallowQuery] = useShallowRouting();

  const initialQueryParams = {
    companyId: user?.companyId,
    ...shallowQuery,
  };

  useEffect(() => {
    const getAllProduceDemandData = async () => {
      setLoading(true);
      const response = await getData(initialQueryParams);
      setLoading(false);

      if (response) {
        setQueryParams({
          page: response?.metaData?.page,
          totalPage: response?.metaData?.totalPage,
          total: response?.metaData?.total,
          companyId: user.companyId,
        });
        const availableProducts = response.items?.filter(
          (item) => item.status === "Available"
        );
        if (availableProducts?.length) {
          setProduces(availableProducts);
          setFilteredProduces(availableProducts);
        } else {
          if (openDemandDialog) {
            // appRouter.push("/my-products/new-demanded-product");
            localStorage.setItem("isNewDemandForPost", true);
          } else {
            appRouter.push("/my-company/new-produce");
            localStorage.setItem("isNewProduceForPost", true);
          }
        }
      }
    };
    getAllProduceDemandData();
  }, []);

  const handleInputChange = (e) => {
    setKeyWord(e.target.value);
    if (keyword) {
      const filteredProduce = produces.filter((item) =>
        item?.name?.toLowerCase().includes(e.target.value)
      );
      setFilteredProduces(filteredProduce);
    } else {
      setFilteredProduces(produces);
    }
  };

  const handleCloseDialog = () => {
    setKeyWord("");
    setQueryParams(undefined);
    handleClose();
  };

  const onOpenPreviewPopUp = () => {
    if (openDemandDialog) {
      setOpenPreviewDemandPopup(true);
      handleClose();
    } else {
      // setOpenPreviewOfferPopup(true);

      handleClose();
      setOpenPreviewOfferPopup(true);
    }
  };

  const onPressAddNewButton = () => {
    if (openDemandDialog) {
      window.location.href = "/my-products/new-demanded-product";
      localStorage.setItem("isNewDemandForPost", true);
    } else {
      window.location.href = "/my-company/new-produce";
      localStorage.setItem("isNewProduceForPost", true);
    }
  };

  const renderData = () => {
    if (!filteredProduces?.length) {
      return (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>{loading ? "Loading..." : "No items found"}</Typography>
        </Box>
      );
    } else {
      return filteredProduces?.map(renderItems);
    }
  };

  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            width: "850px",
            borderRadius: 30,
            padding: "20px 30px",
            border: openDemandDialog
              ? "1px solid #69CDAE"
              : "1px solid #E77228",
          },
        }}
        className={classes.root}
        open={open || openDemandDialog}
        onClose={handleCloseDialog}
        maxWidth="xl"
      >
        <Box className={classes.dialogHeader}>
          <Typography
            variant="h6"
            style={{
              color: openDemandDialog ? "#69CDAE" : "#E77228",
              fontWeight: 600,
            }}
            align="start"
          >
            {title}
          </Typography>
          <Box
            onClick={onPressAddNewButton}
            variant="contained"
            className={
              openDemandDialog
                ? classes.addNewButton
                : classes.addNewDemandButton
            }
          >
            <img src="/static/svg/Close_round_white.svg" />{" "}
            <span style={{ marginLeft: 5 }}>
              {" "}
              {openDemandDialog ? "  Add a demand" : "  Add a product"}
            </span>
          </Box>
        </Box>
        <Box className={classes.searchContainer}>
          <TextField
            className={
              openDemandDialog
                ? classes.contentSearch
                : classes.demandContentSearch
            }
            variant="outlined"
            size="small"
            placeholder="Search"
            onChange={handleInputChange}
            value={keyword}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    src={`/static/svg/Search_light.svg`}
                    alt="Search connections icon"
                  />
                </InputAdornment>
              ),
              endAdornment: <Box display="flex" alignItems="center"></Box>,
            }}
          />
          <Button
            className={
              openDemandDialog
                ? classes.searchButton
                : classes.demandSearchButton
            }
          >
            {" "}
            <img
              src={`/static/svg/Search_light_white.svg`}
              alt="Search  icon"
            />
          </Button>
        </Box>
        {!filteredProduces.length && !loading ? (
          <FruProButton
            onClick={onPressAddNewButton}
            style={{ margin: 0 }}
            className={
              openDemandDialog
                ? classes.noItemAddNewDemandButton
                : classes.noItemAddNewButton
            }
          >
            {openDemandDialog ? "Add new demand" : "Add new product"}
          </FruProButton>
        ) : null}
        <DialogContent className={classes.dialogContent} ref={listRef}>
          <div className={classes.contentRows}>{renderData()}</div>
        </DialogContent>

        <Box className={classes.dialogButtonsContainer}>
          <Button
            className={clsx(
              classes.button,
              openDemandDialog
                ? classes.cancelButton
                : classes.demandCancelButton
            )}
            color="primary"
            onClick={() => {
              handleClose();
              setValue("selectedBuyer", {});
              setIsAddNew(false);
              setValue("companyName", "");
              setValue("email", "");
            }}
          >
            Cancel
          </Button>
          <FruProButton
            className={clsx(
              openDemandDialog ? classes.nextButton : classes.demandNextButton
            )}
            color="primary"
            onClick={onOpenPreviewPopUp}
            disabled={!selecteds?.length || shareLoading}
          >
            {submitButtonText}
          </FruProButton>
        </Box>
      </Dialog>
    </>
  );
}
