import {
  OFFER_TOAST_MESSAGE_ENUM,
  INVOICE_ACTION_TYPE_ENUM,
  COMPANY_ACTION_TYPE_ENUM
} from "@constants";
import toastr from "toastr";


export const showOfferToastMessage = (actionType, offerCode = '') => {
  let message = '';

  if (offerCode!=='') {
    message = `${OFFER_TOAST_MESSAGE_ENUM[actionType]} <span class="text-highlight">${offerCode}</span>!`
  } else {
    message = OFFER_TOAST_MESSAGE_ENUM[actionType]
  }

  toastr.success(message);
}

export const showInvoiceToastMessage = (actionType, data) => {
  let message = '';
  const { offer, invoice } = data;

  switch (actionType) {
    case INVOICE_ACTION_TYPE_ENUM.create:
      message = 
        `You have successfully sent a draft invoice for 
        <span class="text-highlight">${offer?.code}</span> to
        <span style="font-weight: 500;">${offer?.buyer?.fullName}</span>`;
      break;

    case INVOICE_ACTION_TYPE_ENUM.accept:
      message = 
        `You have accepted invoice 
        <span style="color: #04B0ED">${invoice?.code}</span> for
        <span class="text-highlight">${invoice?.offerCode}</span>`;
      break;
    
    case INVOICE_ACTION_TYPE_ENUM.sent:
      message = 
        `You have successfully sent invoice for 
        <span class="text-highlight">${invoice?.offerCode}</span> to 
        <span style="font-weight: 500;">${invoice?.buyer?.fullName}</span>`;
      break;

    case INVOICE_ACTION_TYPE_ENUM.revise:
      message = 
      `You have asked the 
      <span style="font-weight: 500;">${invoice?.seller?.fullName}</span> 
      to revise the invoice 
      <span style="color: #04B0ED">${invoice?.code}</span> for 
      <span class="text-highlight">${invoice?.offerCode}</span>`;
      break;

    case INVOICE_ACTION_TYPE_ENUM.paid:
      message = 
      `You have mark invoice 
      <span style="color: #04B0ED">${invoice?.code}</span> for 
      <span class="text-highlight">${invoice?.offerCode}</span> as paid.`
      break;

    case INVOICE_ACTION_TYPE_ENUM.delete:
      message = 
      `You have deleted invoice 
        <span style="color: #04B0ED">${invoice?.code}</span> for
        <span class="text-highlight">${invoice?.offerCode}</span>`;
      break;

    default:
      break;
  }

  toastr.success(message);
}

export const showCompanyToastMessage = (data) => {
  const { userName, companyName, action } = data;

  switch (action) {
    case COMPANY_ACTION_TYPE_ENUM.INVITE_USER:
      toastr.info(
        `You have added <span style="font-weight: 500;">${userName}</span>
         to <span style="font-weight: 500;">${companyName}</span>
         as a colleague!`
      );
      break;
    case COMPANY_ACTION_TYPE_ENUM.ADD_ADMIN:
      toastr.info(
        `You have added ${userName} to ${companyName} as a Company Admin!`
      );
      break;
    case COMPANY_ACTION_TYPE_ENUM.REMOVE_USER:
      toastr.success(
        `You have removed <span style="font-weight: 500;">${userName}</span> 
        from <span style="font-weight: 500;">${companyName}</span>
        on the FruPro Platform`
      );
      break;
    case COMPANY_ACTION_TYPE_ENUM.DETAILS_CHANGE:
      toastr.success(
        "You have successfully updated your company details."
      );
      break;
  }
}
