import React, { useState, useEffect } from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import styled from "styled-components";
import {
  addReactionToPost,
  removeReactionFromPost,
  addCommentToPost,
  deleteComment,
  getAllCommentsInPost,
  getListOfReactionInPost,
  getUserInfo,
  getListOfReactionInPostNext,
  sendNotificationWhenAddComment,
  sendNotificationWhenLikePost,
  sendNotificationWhenDeleteComment,
} from "@services/amityEndPoints";
import { setNewChatInfo } from "@reducers/chat";
import { useSelector, useDispatch } from "react-redux";
import { COMPANY_STATUS_ENUM } from "@constants";
import FruProDialog from "@components/Dialog";
import { Dropdown } from "react-bootstrap";
import { FruProButton, FruProLimitedCompanyModal } from "@components";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import PostShareModal from "./PostShareModal";
import { useRouter } from "next/router";
import CompanyNotRegisteredModal from "@components/CompanyNotRegisteredModal";
const PostActionItem = ({
  onNavigateLogin,
  postId,
  setLikeCount,
  setLikedUsers,
  post,
  openLikesModal,
  keyword,
  setLikedUsersLoading,
  setOpenLikesModal,
  setPostId,
  globalFeedData,
  // openLimitedCompanyModal,
  // setOpenLimitedCompanyModal,
  setGlobalFeedData,
  isLimitedCompany,
  user,
}) => {
  const useStyles = makeStyles((theme) => ({
    feedText: {
      fontSize: 18,
      fontWeight: 300,
      fontFamily: "Montserrat",
    },
    likeContent: {
      display: "flex",
      marginTop: 20,
      margin: "0 15px",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "8px",
    },
    likeText: {
      fontSize: "14px",
      fontWeight: 500,
    },
    addCommentContainer: {
      display: "flex",
      flexDirection: "row",
      gap: 5,
      alignItems: "center",
    },
    commentsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 5,
    },
    commentItem: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    commentContent: {
      marginLeft: 30,
      backgroundColor: "hsl(226.7,9.9%,92.8%)",

      color: "hsl(228.0,9.8%,10.0%)",

      borderRadius: "0 12px 12px 12px",
      overflowWrap: "anywhere !important",
      wordBreak: "break-word",
      padding: "12px",
      display: "inline-block",
    },
    moreCommentText: {
      textAlign: "center",
      fontSize: 18,
      marginTop: 10,
      cursor: "pointer",
    },
    commentInput: {
      "&:focus": {
        outline: "none",
      },
    },
    deleteContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    dotIcon: {
      backgroundColor: "#f8f8f8 !important",
      fontSize: "24px !important",
      cursor: "pointer",
      width: 4,
      height: 33,
    },
    deleteContent: {
      padding: `${theme.spacing(4)}px 20px`,
    },
  }));

  const DROPDOWN = styled(Dropdown)`
    padding: 0;
    .dropdown-item:hover {
      // background: #69CDAE;
      // opacity: 0.1;
      color: #69cdae;
    }
    .dropdown-toggle::after {
      display: none;
    }
    a {
      height: 30px;
      color: ${(props) => props.theme.texts.black};
      text-decoration: none;
      &:active {
        color: ${(props) => props.theme.texts.black};
        background: #f3f3f3;
      }
      .name {
        margin-left: 12px;
        font-weight: 500;
        font-size: 15px;
      }
    }

    button {
      height: 48px;
      border: none;
      box-shadow: none !important;
      display: flex;
      align-items: center;
      padding: 10px;
      color: ${(props) => props.theme.texts.black} !important;
      border-radius: 0;
      background: transparent !important;
    }
  `;

  const router = useRouter();

  const [openCompanyNotRegistered, setOpenCompanyNotRegistered] =
    useState(false);
  const [openDeleteCommentModal, setOpenDeleteCommentModal] = useState(false);
  const [firstComments, setFirstComments] = useState([]);
  const [likeLoading, setLikeLoading] = useState(false);
  const [reactionData, setReactionData] = useState({});
  const [postCommentId, setPostCommentId] = useState(null);
  const [commentLoading, setCommentLoading] = useState(false);
  const [paginateNumber, setPaginateNumber] = useState(5);
  const [commentId, setCommentId] = useState(null);
  const currentUser = useSelector((state) => state.auth.user);

  const deletedComments = firstComments?.comments?.filter(
    (item) => item.isDeleted !== true
  );

  const slice = deletedComments?.slice(0, paginateNumber);

  const deleteCommentInPost = async () => {
    const response = await deleteComment(commentId);

    if (response) {
      const newComments = { ...firstComments };

      const index = newComments?.comments?.findIndex(
        (comment) => comment._id === commentId
      );
      const newComment = newComments?.comments?.[index];

      newComment.isDeleted = true;

      setFirstComments(newComments);

      const newFeedData = { ...globalFeedData };
      const feedDataIndex = newFeedData?.posts.findIndex(
        (item) => item._id === postCommentId
      );

      const deletedFeedDataIndex = newFeedData?.posts[feedDataIndex];
      deletedFeedDataIndex.commentsCount -= 1;

      setGlobalFeedData(newFeedData);

      setFirstComments(newComments);

      const notificationData = {
        recipientId: deletedFeedDataIndex?.metadata?.user?.id,
        postData: {
          _id: deletedFeedDataIndex?.postId,
          comment: newComment?.data?.text,
        },
      };

      return await sendNotificationWhenDeleteComment(notificationData);
    }
  };

  const arrayButtons = [
    {
      onClick: () => setOpenDeleteCommentModal(false),
      color: "default",
      text: "Cancel",
    },
    {
      onClick: async () => {
        setOpenDeleteCommentModal(false);
        deleteCommentInPost();
      },
      color: "error",
      text: "Delete",
    },
  ];
  /* 
  useEffect(() => {
    if (currentUser?.id) {
      //  getAll();
    }
  }, [currentUser?.id]);
 */
  const getAll = async () => {
    const response = await getAllCommentsInPost(postId);

    setFirstComments(response);
  };

  const getAllComments = async () => {
    setPaginateNumber(paginateNumber + paginateNumber);
  };

  const getLessComments = async () => {
    setPaginateNumber(5);
  };

  const classes = useStyles();
  const dispatch = useDispatch();

  const [openCommentTab, setOpenCommentTab] = useState(false);
  const [commentText, setCommentText] = useState("");

  useEffect(() => {
    if (reactionData) {
      const getUserInformation = async () => {
        const promises = reactionData?.users?.map(async (user) => {
          return await getUserInfo(user?.userId);
        });

        if (promises) {
          Promise.all(promises).then((data) => {
            setLikedUsers(data);
          });
        }
      };

      getUserInformation();
    }
  }, [reactionData, !keyword]);

  // useEffect(() => {
  //   if (reactionData?.paging?.next) {
  //     const getNextUsers = async () => {
  //       const allUserPromises = await getListOfReactionInPostNext(
  //         postId,
  //         reactionData?.paging?.next
  //       );

  //       if (allUserPromises) {
  //         Promise.all(allUserPromises?.users).then((data) => {

  //           const newObj={...allUserPromises}
  //           setReactionData(newObj)
  //         });
  //       }
  //     };
  //     getNextUsers();
  //   }
  // }, [reactionData?.paging?.next]);

  const getListOfReaction = async (id) => {
    setLikedUsersLoading(true);
    const response = await getListOfReactionInPost(id);
    setLikedUsersLoading(false);

    if (response) {
      setReactionData(response);
    }
  };

  const likePost = async () => {
    if (!currentUser?.id) {
      onNavigateLogin();
    } else if (currentUser?.id && !currentUser?.companyId) {
      setOpenCompanyNotRegistered(true);
    } else {
      setLikeLoading(true);
      const response = await addReactionToPost(postId);
      setLikeLoading(false);

      if (response) {
        const likedPostIndex = globalFeedData.posts.findIndex(
          (post) => post._id === postId
        );

        const likedPost = globalFeedData?.posts[likedPostIndex];

        likedPost.myReactions.push("like");

        likedPost.reactionsCount += 1;

        setGlobalFeedData({ ...globalFeedData });

        const notificationData = {
          recipientId: likedPost?.metadata?.user?.id,
          postData: {
            _id: likedPost?.postId,
          },
        };

        return await sendNotificationWhenLikePost(notificationData);
      }
    }
  };

  const unlikePost = async () => {
    setLikeLoading(true);
    const response = await removeReactionFromPost(postId);
    setLikeLoading(false);

    if (response) {
      const willUnlikedPostIndex = globalFeedData.posts.findIndex(
        (post) => post._id === postId
      );

      const willUnlikedPost = globalFeedData?.posts[willUnlikedPostIndex];

      willUnlikedPost.myReactions?.splice(
        willUnlikedPost.myReactions.findIndex((item) => item === "like"),
        1
      );

      willUnlikedPost.reactionsCount -= 1;

      setGlobalFeedData({ ...globalFeedData });

      const notificationData = {
        recipientId: willUnlikedPost?.metadata?.user?.id,
        postData: {
          _id: willUnlikedPost?.postId,
        },
      };

      return await sendNotificationWhenLikePost(notificationData);
    }
  };

  const addComment = async () => {
    setCommentLoading(true);
    const response = await addCommentToPost(postId, commentText, currentUser);
    setCommentLoading(false);
    if (response) {
      setCommentText("");

      const newComment = response.comments[0];

      const commentedIndex = globalFeedData?.posts?.findIndex(
        (item) => item._id === newComment.referenceId
      );

      const newCommentsCount = {
        ...globalFeedData,
        comments: [newComment, ...globalFeedData.comments],
      };

      const newComments = {
        ...firstComments,
        comments: [newComment, ...firstComments.comments],
      };

      const increaseCount = newCommentsCount?.posts[commentedIndex];

      increaseCount.commentsCount += 1;

      increaseCount.comments.push(newComment._id);

      setGlobalFeedData(newCommentsCount);
      setFirstComments(newComments);

      const notificationData = {
        recipientId: increaseCount?.metadata?.user?.id,
        postData: {
          _id: newComment?.referenceId,
          comment: newComment?.data?.text,
        },
      };

      return await sendNotificationWhenAddComment(notificationData);
    }
  };

  const openDeleteContainer = () => {
    if (!currentUser?.id) {
      onNavigateLogin();
    } else if (currentUser?.id && !currentUser?.companyId) {
      setOpenCompanyNotRegistered(true);
    } else {
      if (currentUser?.id) {
        getAll();
      }
      setOpenCommentTab(!openCommentTab);
    }
  };

  const renderDeleteModal = () => {
    return (
      <FruProDialog
        size="small"
        title="Delete Comment"
        buttons={arrayButtons}
        open={openDeleteCommentModal}
        titleBorder
        onClose={() => setOpenDeleteCommentModal(false)}
      >
        <Typography className={classes.deleteContent} variant="body1">
          Are you sure you want to delete this comment?
        </Typography>
      </FruProDialog>
    );
  };

  // const renderLimitedCompanyModal = () => {
  //   return (
  //     <FruProLimitedCompanyModal
  //       openLimitedCompanyModal={openLimitedCompanyModal}
  //       setOpenLimitedCompanyModal={setOpenLimitedCompanyModal}
  //     />
  //   );
  // };

  const [openPostShareModal, setOpenPostShareModal] = useState(false);

  const handleImageClick = () => {
    if (!currentUser?.id) {
      onNavigateLogin();
    } else if (currentUser?.id && !currentUser?.companyId) {
      setOpenCompanyNotRegistered(true);
    } else {
      setOpenPostShareModal(true);
    }
  };

  const onPressReactionCount = () => {
    setOpenLikesModal(true);
    setLikeCount(post?.reactionsCount);
    setPostId(post?._id);
    getListOfReaction(post?._id);
    setLikedUsers([]);
  };

  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        container
      >
        <Box style={{ display: "flex", flexDirection: "row" }}>
          {post?.data?.type === "salePost" ? null : (
            <>
              {/* <Box className={classes.likeContent}>
                {post?.myReactions?.includes("like") ? (
                  <Typography className={classes.feedText}>
                    <img
                      onClick={!likeLoading && unlikePost}
                      style={{ cursor: "pointer" }}
                      src="/static/svg/post-liked-icon.svg"
                    />
                  </Typography>
                ) : (
                  <Typography className={classes.feedText}>
                    <img
                      onClick={!likeLoading && likePost}
                      style={{ cursor: "pointer" }}
                      src="/static/svg/post-like-icon.svg"
                    />
                  </Typography>
                )}
                <Typography
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    !currentUser?.id
                      ? onNavigateLogin()
                      : currentUser?.id && !currentUser?.companyId
                      ? setOpenCompanyNotRegistered(true)
                      : onPressReactionCount();
                  }}
                  className={classes.likeText}
                >
                  {post?.reactionsCount}
                </Typography>
              </Box> */}
              <Box className={classes.likeContent}>
                <Typography className={classes.feedText}>
                  <img
                    onClick={() => {
                      openDeleteContainer();
                    }}
                    style={{ cursor: "pointer" }}
                    src="/static/svg/Chat_light.svg"
                  />
                </Typography>
                <Typography className={classes.likeText}>
                  {post?.commentsCount}
                </Typography>
              </Box>
            </>
          )}
          <Box className={classes.likeContent}>
            <Typography className={classes.feedText}>
              <img
                style={{ width: 30, height: 30 }}
                src="/static/svg/bar-chart-light.svg"
              />
            </Typography>
            <Typography className={classes.likeText}>
              {post?.impression}
            </Typography>
          </Box>
        </Box>
        {post?.data?.type === "salePost" ? null : (
          <Box>
            <img
              onClick={handleImageClick}
              style={{
                width: 22,
                height: 25,
                cursor: "pointer",
                marginRight: 10,
              }}
              src="/static/svg/Frame 99.svg"
            />
            <PostShareModal
              post={post}
              openPostShareModal={openPostShareModal}
              setOpenPostShareModal={setOpenPostShareModal}
              postId={postId}
            />
          </Box>
        )}
      </Grid>

      <>
        {post.comments.length > 0 && (
          <>
            {openCommentTab ? (
              <>
                {post.commentsCount !== 0 && <hr />}
                <Box className={classes.commentsContainer}>
                  {slice?.map((item) => {
                    return (
                      item.isDeleted !== true && (
                        <>
                          <Box className={classes.deleteContainer}>
                            <Box className={classes.commentItem}>
                              <img
                                style={{
                                  width: 40,
                                  height: 40,
                                  borderRadius: "100%",
                                }}
                                src={
                                  item?.metadata?.user?.photo?.thumbUrl ||
                                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                }
                              />
                              <Typography
                                style={{ fontSize: 15, marginLeft: 5 }}
                              >
                                {item.metadata.user.fullName}
                              </Typography>{" "}
                              -{" "}
                              <Typography style={{ opacity: 0.5 }}>
                                {moment(item.createdAt).format("DD/MM/YYYY")}
                              </Typography>
                            </Box>
                            {currentUser.email === item.userId ? (
                              <Box className={classes.deleteCommentIcon}>
                                <DROPDOWN>
                                  <Dropdown.Toggle id="dropdown-button-drop-down-centered">
                                    <Typography id="basic-button">
                                      <img
                                        className={classes.dotIcon}
                                        src="/static/svg/Frame 1980.svg"
                                      />
                                    </Typography>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {
                                      <Dropdown.Item
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          gap: 15,
                                          alignItems: "center",
                                        }}
                                        onClick={() => {
                                          setOpenDeleteCommentModal(true);
                                          setCommentId(item?._id);
                                          setPostCommentId(item?.referenceId);
                                        }}
                                      >
                                        <img
                                          style={{ width: 20, height: 20 }}
                                          src="/static/svg/ic_delete.svg"
                                        />

                                        <Typography style={{ fontSize: 16 }}>
                                          Delete comment
                                        </Typography>
                                      </Dropdown.Item>
                                    }
                                  </Dropdown.Menu>
                                </DROPDOWN>
                              </Box>
                            ) : null}
                          </Box>
                          <Box className={classes.commentContent}>
                            <Typography>{item.data.text}</Typography>
                          </Box>
                        </>
                      )
                    );
                  })}

                  {post.commentsCount > 5 &&
                  slice?.length === post?.commentsCount ? (
                    <Typography
                      onClick={getLessComments}
                      className={classes.moreCommentText}
                    >
                      Load Less Comments..
                    </Typography>
                  ) : (
                    post.commentsCount > 5 && (
                      <Typography
                        onClick={getAllComments}
                        className={classes.moreCommentText}
                      >
                        Load More Comments..
                      </Typography>
                    )
                  )}
                </Box>
              </>
            ) : null}
          </>
        )}
        {openCommentTab ? (
          <>
            <hr />
            <Box id="commentElement" className={classes.addCommentContainer}>
              <img
                src={
                  currentUser?.photo?.thumbUrl ||
                  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                }
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "100%",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                }}
              />
              <input
                placeholder="Say something nice"
                value={commentText}
                className={classes.commentInput}
                onChange={(e) => setCommentText(e.target.value)}
                style={{
                  width: 300,
                  opacity: 0.5,
                  height: 40,
                  borderRadius: 10,
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "start",
                  border: "0.2 px solid gray",
                }}
                type="text"
              />

              <FruProButton
                disabled={!commentText || commentLoading}
                onClick={addComment}
              >
                Add comment
              </FruProButton>
            </Box>{" "}
          </>
        ) : null}
      </>
      <CompanyNotRegisteredModal
        setOpenModal={setOpenCompanyNotRegistered}
        openModal={openCompanyNotRegistered}
      />
      {renderDeleteModal()}
    </>
  );
};

export default PostActionItem;
