import toastr from "toastr";
import axios from "axios";
import { axiosInstance } from "services";
///join user to frupro

let communityId = "63aec51a1f8ad84ae99ba3da";

export const AddUserToFruPro = async () => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.post(
      `https://api.eu.amity.co/api/v3/communities/${communityId}/join`,
      {},
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);

    return error;
  }
};

////////////////////////

///user endpoints

export const updateUserInfo = async (fullName, user) => {
  const accessToken = localStorage.getItem("amityAccessToken");

  const response = await axios.put(
    "https://api.eu.amity.co/api/v3/users",
    {
      displayName: fullName,
      avatarCustomUrl: user,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  if (response.data) {
    return response.data;
  }

  try {
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};
export const getUserInfo = async (userId) => {
  const accessToken = localStorage.getItem("amityAccessToken");

  const response = await axios.get(
    `https://api.eu.amity.co/api/v3/users/${userId}`,

    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  if (response.data) {
    return response.data;
  }

  try {
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

/////////////////////

//generate access token

export const generateAccessToken = async (userId) => {
  try {
    const refreshToken = "11112";

    const response = await axios.get(
      `https://api.eu.amity.co/v1/accessToken?userId=${userId}&refreshToken=${refreshToken}`,
      {
        headers: {
          "x-api-key": "b0efef093d89a2604c318e1e030f138bd10c89e2ba343e2d",
        },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);

    return error;
  }
};
export const verifyAccessToken = async () => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.get(
      `https://api.eu.amity.co/api/v3/sessions`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          accessToken: accessToken,
        },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);

    return error;
  }
};

//////////////////////

//Follow-Unfollow Requests
export const FollowUser = async (amityUserId) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const amityFollow = await axios.post(
      `https://api.eu.amity.co/api/v4/me/following/${amityUserId}`,
      {},
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    if (amityFollow.data) {
      return amityFollow.data;
    }
  } catch (error) {
    console.error(error);

    return error;
  }
};
export const AddFollowerUser = async (amityUserId) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const amityFollow = await axios.post(
      `https://api.eu.amity.co/api/v4/me/followers/${amityUserId}`,
      {},
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    if (amityFollow.data) {
      return amityFollow.data;
    }
  } catch (error) {
    console.error(error);

    return error;
  }
};

export const UnFollowUser = async (amityUserId) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const amitydeleteFollowing = await axios.delete(
      `https://api.eu.amity.co/api/v4/me/following/${amityUserId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (amitydeleteFollowing) {
      return amitydeleteFollowing.data;
    }
  } catch (error) {
    console.error(error);

    return error;
  }
};
export const UnFollowerUser = async (amityUserId) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const amitydeleteFollowing = await axios.delete(
      `https://api.eu.amity.co/api/v4/me/followers/${amityUserId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (amitydeleteFollowing) {
      return amitydeleteFollowing.data;
    }
  } catch (error) {
    console.error(error);

    return error;
  }
};

export const getMyFollowings = async () => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const myFollowings = await axios.get(
      `https://api.eu.amity.co/api/v4/me/following`,

      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    if (myFollowings) {
      return myFollowings.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    return error;
  }
};
export const getMyFollowers = async () => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const myFollowers = await axios.get(
      `https://api.eu.amity.co/api/v4/me/followers`,

      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    if (myFollowers) {
      return myFollowers.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    return error;
  }
};
//////////////////////////////////////////////////////

//Feed Requests

export const getCompanyPosts = async (companyId) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.get(
      `https://api.eu.amity.co/api/v4/posts?options%5Blimit%5D=15&&isDeleted=false`,
      {
        params: {
          targetId: communityId,
          targetType: "community",
          tags: [companyId],
        },

        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    return error;
  }
};
export const nextCompanyPosts = async (companyId, next) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.get(
      `https://api.eu.amity.co/api/v4/posts?options%5Btoken%5D=${next}&&isDeleted=false`,
      {
        params: {
          targetId: communityId,
          targetType: "community",
          tags: [companyId],
        },

        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    return error;
  }
};
export const prevCompanyPosts = async (companyId, prev) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.get(
      `https://api.eu.amity.co/api/v4/posts?options%5Btoken%5D=${prev}&&isDeleted=false`,
      {
        params: {
          targetId: communityId,
          targetType: "community",
          tags: [companyId],
          token: prev,
        },

        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    return error;
  }
};

export const globalFeeds = async (tokenRes) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.get(
      `https://api.eu.amity.co/api/v3/community-feeds/${communityId}?limit=15&&isDeleted=false`,

      {
        headers: {
          Authorization: `Bearer ${tokenRes ? tokenRes : accessToken}`,
        },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);

    return error;
  }
};
export const limitedCompanyGlobalFeeds = async () => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");
    const response = await axios.get(
      `https://api.eu.amity.co/api/v3/community-feeds/${communityId}?limit=2&&isDeleted=false`,

      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);

    return error;
  }
};
export const personalGlobalFeeds = async (email) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.get(
      `https://api.eu.amity.co/api/v4/posts?targetId=${email}&targetType=user`,

      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);

    return error;
  }
};
export const otherGlobalFeeds = async (next) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.get(
      `https://api.eu.amity.co/api/v3/community-feeds/${communityId}?options%5Btoken%5D=${next}&&isDeleted=false`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);

    return error;
  }
};
export const prevGlobalFeeds = async (prev) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.get(
      `https://api.eu.amity.co/api/v3/community-feeds/${communityId}?options%5Btoken%5D=${prev}&&isDeleted=false`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);

    return error;
  }
};

export const userFeeds = async (amityUserId) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.get(
      `https://api.eu.amity.co/api/v4/posts?targetId=${amityUserId}&&targetType=user`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};
///////////////////////////////////////////////////////

//Post Requests
export const createTextPost = async (postText, richText, user, expireDate) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.post(
      "https://api.eu.amity.co/api/v3/posts",
      {
        data: {
          text: postText,
          richText: richText,
        },
        targetType: "community",
        dataType: "upstra.customtype",
        tags: [`${user?.companyId}`],
        targetId: communityId,
        metadata: { user, endDate: expireDate },
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response.data) {
      toastr.success("Post created Successfully");
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};
export const createImagePost = async (
  imagesData,
  postText,
  richText,
  user,
  expireDate
) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.post(
      "https://api.eu.amity.co/api/v3/posts",
      {
        data: {
          text: postText,
          richText: richText,
          images: imagesData ? imagesData : [],
        },
        targetType: "community",
        dataType: "upstra.customtype",
        tags: [`${user?.companyId}`],
        targetId: communityId,
        metadata: { user, endDate: expireDate },
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response.data) {
      toastr.success("Post created Successfully");
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};
export const createVideoPost = async (
  videosData,
  postText,
  richText,
  user,
  expireDate
) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.post(
      "https://api.eu.amity.co/api/v3/posts",
      {
        data: {
          text: postText,
          richText: richText,
          videos: videosData ? videosData : [],
        },
        targetType: "community",
        dataType: "upstra.customtype",
        tags: [`${user?.companyId}`],
        targetId: communityId,
        metadata: { user, endDate: expireDate },
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response.data) {
      toastr.success("Post created Successfully");
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};
export const createFilePost = async (
  filesData,
  postText,
  richText,
  user,
  expireDate
) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.post(
      "https://api.eu.amity.co/api/v3/posts",
      {
        data: {
          text: postText,
          richText: richText,
          files: filesData ? filesData : [],
        },
        targetType: "community",
        dataType: "upstra.customtype",
        tags: [`${user?.companyId}`],
        targetId: communityId,
        metadata: { user, endDate: expireDate },
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response.data) {
      toastr.success("Post created Successfully");
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};
export const createProduceDemandPost = async (
  selectedProduces,
  user,
  type,
  currentContentAsText,
  currentContentAsHtml,
  expireDate
) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.post(
      "https://api.eu.amity.co/api/v3/posts",
      {
        data: {
          type,
          text: currentContentAsText,
          richText: currentContentAsHtml,
        },
        targetType: "community",
        tags: [`${user?.companyId}`],
        dataType: "upstra.customtype",
        targetId: communityId,
        metadata: { selectedProduces, user, endDate: expireDate },
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response.data) {
      toastr.success("Post created Successfully");
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};

export const createProduceDemandPostFromNewProduce = async (
  selectedProduces,
  user,
  type,
  offerDemandPostText,
  expireDate
) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.post(
      "https://api.eu.amity.co/api/v3/posts",
      {
        data: { type, text: offerDemandPostText },
        targetType: "community",
        tags: [`${user?.companyId}`],
        dataType: "upstra.customtype",
        targetId: communityId,
        metadata: { selectedProduces, user, endDate: expireDate },
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};

export const getVideoPosts = async (amityUserId) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.get(
      `https://api.eu.amity.co/api/v4/me/global-feeds?targetId=${amityUserId}&targetType=user&dataTypes=video`,

      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};

export const getImagePosts = async (amityUserId) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.get(
      `https://api.eu.amity.co/api/v4/me/global-feeds?targetId=637dd0b146751a2eadfc6f6b&targetType=community&dataTypes=image`,

      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};

export const deletePost = async (postId) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.delete(
      `https://api.eu.amity.co/api/v4/posts/${postId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response.data) {
      toastr.success("Your post is deleted successfully.");
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};

//////////////////////////////////////////////

//post reports

export const reportPost = async (postId) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");
    const response = await axios.post(
      `https://api.eu.amity.co/api/v3/posts/${postId}/flag`,
      {},
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (!response.message) {
      toastr.success("Post reported successfully.");
      return response.data;
    } else {
      toastr.error(response.data.message);
    }
  } catch (error) {
    console.error(error);
    toastr.info(
      error.response.data.message
        ? "You have already reported this post."
        : null
    );
    return error;
  }
};
export const reportedByMe = async (postId) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");
    const response = await axios.get(
      `https://api.eu.amity.co/api/v3/posts/${postId}/isflagbyme`,

      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};

//////////////////////////////////

//reactions

export const addReactionToPost = async (postId) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");
    const response = await axios.post(
      "https://api.eu.amity.co/api/v2/reactions",
      {
        reactionName: "like",
        referenceType: "post",
        referenceId: postId,
        referenceVersion: 0,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};

export const removeReactionFromPost = async (postId) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");
    const response = await axios.delete(
      "https://api.eu.amity.co/api/v2/reactions",

      {
        data: {
          referenceId: postId,
          referenceType: "post",
          reactionName: "like",
          referenceVersion: 0,
        },

        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};

export const getListOfReactionInPost = async (postId) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");
    const response = await axios.get(
      `https://api.eu.amity.co/api/v3/reactions?referenceId=${postId}&referenceType=post&reactionName=like&referenceVersion=0`,

      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};
export const getListOfReactionInPostNext = async (postId, next) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");
    const response = await axios.get(
      `https://api.eu.amity.co/api/v3/reactions?referenceId=${postId}&referenceType=post&reactionName=like&referenceVrsion=0&options%5Blimit%5D=1&options%5Btoken%5D=${next}`,

      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};

/////////////////////////////////////////////////////////////

//comments

export const addCommentToPost = async (postId, commentText, user) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.post(
      "https://api.eu.amity.co/api/v3/comments",
      {
        referenceId: postId,
        referenceType: "post",
        data: {
          text: commentText,
        },
        metadata: { user },
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response.data) {
      toastr.success("Comment added successfully");

      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};

export const deleteComment = async (commentId) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.delete(
      `https://api.eu.amity.co/api/v4/comments/${commentId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response) {
      toastr.success("Your comment is deleted successfully.");
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};

export const getAllCommentsInPost = async (postId) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const params = new URLSearchParams({
      referenceId: postId,
    });

    const response = await axios.get(
      `https://api.eu.amity.co/api/v3/comments?&options%5Btype%5D=pagination&options%5Blimit%5D=${100}&` +
        params,
      {
        data: {
          referenceType: "post",
          options: { type: "pagination" },
        },

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};
export const getAllOtherCommentsInPost = async (postId, next) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.get(
      `https://api.eu.amity.co/api/v3/comments?referenceId=${postId}&options%5Btoken%5D=${next}&options%5Btype%5D=pagination`,
      {
        data: {
          referenceType: "post",
        },

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};

/////////////////////////////////////////////////////////

//files

export const fileUpload = async (selectedImages) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");
    const form = new FormData();

    // let images = Array.from(selectedImages).map((image) => {
    //   return `files=${image.name};type=${image.type}`;
    // });

    Array.from(selectedImages).forEach((image) => {
      form.append("files", image);
    });

    const response = await axios.post(
      "https://api.eu.amity.co/api/v4/images",

      form,

      {
        headers: {
          accept: "application/json",

          Authorization: `Bearer ${accessToken}`,

          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (!response.message) {
      return response.data;
    } else {
      toastr.error(response.error);
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.response.data.message);
    return error;
  }
};

export const videoUpload = async (selectedVideos) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");
    const form = new FormData();

    // let images = Array.from(selectedImages).map((image) => {
    //   return `files=${image.name};type=${image.type}`;
    // });

    Array.from(selectedVideos).forEach((video) => {
      form.append("files", video);
    });

    const response = await axios.post(
      "https://api.eu.amity.co/api/v4/videos",

      form,

      {
        headers: {
          accept: "application/json",

          Authorization: `Bearer ${accessToken}`,

          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (!response.message) {
      return response.data;
    } else {
      toastr.error(response.error);
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.response.data.message);
    return error;
  }
};
export const newFileUpload = async (selectedFiles) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");
    const form = new FormData();

    // let images = Array.from(selectedImages).map((image) => {
    //   return `files=${image.name};type=${image.type}`;
    // });

    Array.from(selectedFiles).forEach((file) => {
      form.append("files", file);
    });

    const response = await axios.post(
      "https://api.eu.amity.co/api/v4/files",

      form,

      {
        headers: {
          accept: "application/json",

          Authorization: `Bearer ${accessToken}`,

          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (!response.message) {
      return response.data;
    } else {
      toastr.error(response.error);
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.response.data.message);
    return error;
  }
};

export const getImageFile = async (fileId) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.get(
      `https://api.eu.amity.co/api/v3/files/${fileId}`,
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};

export const getPostById = async (postId) => {
  const accessToken = localStorage.getItem("amityAccessToken");
  const response = await axios.get(
    `https://api.eu.amity.co/api/v3/posts/${postId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (response) {
    return response.data;
  }
};

export const updateOfferDemandPost = async (
  type,
  postId,
  selectedProduces,
  user,
  currentContentAsText,
  currentContentAsHtml,
  endDate
) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.put(
      `https://api.eu.amity.co/api/v4/posts/${postId}`,
      {
        data: {
          type,
          richText:currentContentAsHtml,
          text:  currentContentAsText ?  currentContentAsText : null,
        },
        metadata: {
          selectedProduces,
          user,
          endDate: endDate,
        },
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    if (response) {
      toastr.success("Your post is updated successfully.");
      return response?.data;
    }
  } catch (error) {
    toastr.error(error);

    console.error(error);
  }
};

export const updateTextPost = async (postId, user,   currentContentAsText,
  currentContentAsHtml, endDate) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.put(
      `https://api.eu.amity.co/api/v4/posts/${postId}`,
      {
        data: {
          text:currentContentAsText,
          richText:currentContentAsHtml
        },
        metadata: {
          user,
          endDate: endDate,
        },
      },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );

    if (response) {
      toastr.success("Your post is updated successfully.");
      return response?.data;
    }
  } catch (error) {
    toastr.error(error);

    console.error(error);
  }
};
export const updateImagePost = async (
  imagesData,
  currentContentAsText,
     currentContentAsHtml,
  user,
  postId,
  expireDate
) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.put(
      `https://api.eu.amity.co/api/v4/posts/${postId}`,
      {
        data: {
          text:  currentContentAsText ?  currentContentAsText : null,
          images: imagesData ? imagesData : [],
          richText: currentContentAsHtml
        },
        targetType: "community",
        dataType: "upstra.customtype",
        tags: [`${user?.companyId}`],
        targetId: communityId,
        metadata: { user, endDate: expireDate },
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response.data) {
      toastr.success("Your post updated successfully");
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};

export const updateVideoPost = async (
  videosData,
  currentContentAsText,
     currentContentAsHtml,
  user,
  postId,
  expireDate
) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.put(
      `https://api.eu.amity.co/api/v4/posts/${postId}`,
      {
        data: {
          text: currentContentAsText ? currentContentAsText : null,
          videos: videosData ? videosData : [],
          richText:currentContentAsHtml
        },
        targetType: "community",
        dataType: "upstra.customtype",
        tags: [`${user?.companyId}`],
        targetId: communityId,
        metadata: { user, endDate: expireDate },
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response.data) {
      toastr.success("Your post updated successfully");
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};
export const updateFilePost = async (
  filesData,
  currentContentAsText,
  currentContentAsHtml,
  user,
  postId,
  expireDate
) => {
  try {
    const accessToken = localStorage.getItem("amityAccessToken");

    const response = await axios.put(
      `https://api.eu.amity.co/api/v4/posts/${postId}`,
      {
        data: {
          text: currentContentAsText ? currentContentAsText : null,
          files: filesData ? filesData : [],
          richText:currentContentAsHtml
        },
        targetType: "community",
        dataType: "upstra.customtype",
        tags: [`${user?.companyId}`],
        targetId: communityId,
        metadata: { user, endDate: expireDate },
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response.data) {
      toastr.success("Your post updated successfully");
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
    return error;
  }
};

export const sendNotificationWhenLikePost = async (data) => {
  try {
    const { ...rest } = data;
    const response = await axiosInstance.post("/feed/like", { ...rest });

    if (response) {
      return response?.data;
    }
  } catch (error) {
    toastr.error(error?.message);
    console.error(error);
  }
};

export const sendNotificationWhenAddComment = async (data) => {
  try {
    const { ...rest } = data;
    const response = await axiosInstance.post("/feed/comment", { ...rest });

    if (response) {
      return response?.data;
    }
  } catch (error) {
    toastr.error(error?.message);
    console.error(error);
  }
};

export const sendNotificationWhenDeleteComment = async (data) => {
  try {
    const { ...rest } = data;
    const response = await axiosInstance.post("/feed/comment/remove", {
      ...rest,
    });

    if (response) {
      return response?.data;
    }
  } catch (error) {
    toastr.error(error?.message);
    console.error(error);
  }
};

export const addViewCountToPost = async (data) => {
  const accessToken = localStorage.getItem("amityAccessToken");
  try {
    const { ...rest } = data;
    const response = await axiosInstance.post(
      "https://api.eu.amity.co/api/v1/analytics/activities",
      {
        ...rest,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    if (response) {
      return response?.data;
    }
  } catch (error) {
    toastr.error(error?.message);
    console.error(error);
  }
};
