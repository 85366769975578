import React, { useEffect, useState } from "react";
import { Box, Checkbox, Avatar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import clsx from "clsx";

import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  contentRow: {
    padding: "16px 0",
    borderBottom: "1px solid #E8E8E8",
    "&:hover": {
      cursor: "pointer",
      // backgroundColor: theme.palette.grey.light,
    },
  },
  userInfo: {
    paddingLeft: 12,
  },
  emailPhone: {
    fontSize: 15,
    fontWeight: 500,
    paddingTop: 6,
    color: "#858688",
  },
  disabled: {
    backgroundColor: theme.palette.grey.light,
    cursor: "not-allowed",
    pointerEvents: "none",
  },
  // rowChecked: {
  //   color:"#69DCAE",
  //   background: "#FAF0ED",
  // },
  rowDisabled: {
    background: "rgba(227, 227, 227, 0.5)",
  },
  userAvatar: {
    width: 65,
    height: 65,
    objectFit: "cover",
    borderRadius: 10,
  },
  demandAvatar: {
    width: 90,
    height: 90,
    objectFit: "cover",
    borderRadius: 10,
  },
  messageInputContainer: {
    paddingTop: 12,
    "& textarea": {
      width: "100%",
      padding: "6px 8px",
      fontSize: 10,
      fontWeight: 300,
      borderColor: "#DEE2E6",
      borderRadius: 5,
    },
  },
  connectedLabel: {
    fontSize: 16,
    fontWeight: 600,
    display: "flex",
    justifyContent: "space-between",
    color: "#858688",
    paddingLeft: 5,
    gap: 5,
  },
  w100: {
    width: "100%",
  },
  offerNameText: {
    fontSize: 20,
    fontWeight: 500,
  },
  Checkbox: {
    transform: "scale(1.6)",
  },
}));

export default function AddOfferDemandItem({
  selectedProduce,
  openDemandDialog,
  setSelectedProduce,
  openOfferDemandPostDialog,
  produceItem,
  onChecked,
  loading,
}) {
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);

  const fromDate = moment(produceItem.timeline.from).format("DD/MM/YYYY");
  const toDate = moment(produceItem.timeline.to).format("DD/MM/YYYY");

  const destination =
    produceItem?.location?.country?.name ||
    produceItem?.location?.city?.name ||
    produceItem?.location?.state?.name
      ? `${produceItem?.location?.country?.name},${produceItem.location.city.name},${produceItem.location.state.name}`
      : "N/A";

  const renderDemandItem = () => {
    return (
      <Box
        key={produceItem.id}
        className={clsx({
          [classes.contentRow]: true,
          // [classes.disabled]: membersInfo[item._id || item.id],
          [classes.rowChecked]: !!selectedProduce.some(
            (user) => user.id === produceItem?.id
          ),
        })}
        onClick={() =>
          onChecked(
            selectedProduce.some((user) => user.id === produceItem?.id),
            produceItem
          )
        }
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Box>
            <Checkbox
              checked={
                !!selectedProduce.some((user) => user.id === produceItem.id)
              }
              className={classes.Checkbox}
              sx={{ "& .MuiSvgIcon-root": { fontSize: "37px !important" } }}
              style={{
                color: !openOfferDemandPostDialog ? "#69DCAE" : "#E77228",
              }}
            />
          </Box>
          <Box display="flex">
            <Avatar
              src={produceItem?.photos?.[0]?.thumbUrl}
              alt="user avatar"
              className={classes.demandAvatar}
              variant="square"
            />
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                className={clsx({
                  [classes.userInfo]: true,
                })}
                width="100%"
              >
                <Typography className={classes.offerNameText}>
                  {produceItem?.name}
                </Typography>
                <Box
                  style={{
                    width: "590px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <Box>
                    <Typography
                      style={{ display: "flex", flexDirection: "row", gap: 5 }}
                    >
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 16,
                          color: "rgba(134, 134, 134, 1)",
                        }}
                      >
                        Demanded Dates:{"  "}
                      </p>

                      <p
                        style={{
                          color: "rgba(0, 0, 0, 1)",
                          display: "flex",
                          fontSize: 16,
                        }}
                      >
                        {fromDate ? fromDate : "N/A"} -{" "}
                        {toDate ? toDate : "N/A"}{" "}
                      </p>
                    </Typography>
                    <Typography
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 5,
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 16,
                          color: "rgba(134, 134, 134, 1)",
                        }}
                      >
                        Destination:{"  "}
                      </p>
                      <p
                        style={{
                          color: "black",
                          wordWrap: "normal",
                          width: "100%",
                        }}
                      >
                        {destination}{" "}
                      </p>
                    </Typography>
                    <Typography
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 5,
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 16,
                          color: "rgba(134, 134, 134, 1)",
                        }}
                      >
                        Category:{"  "}
                      </p>
                      <p style={{ color: "black", display: "flex" }}>
                        {produceItem?.category ? produceItem?.category : "N/A"}{" "}
                      </p>
                    </Typography>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <hr
                      style={{
                        borderLeft: "1px solid #DFDFDF",
                        width: 70,
                        transform: "rotate(90deg)",
                      }}
                    />
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 5,
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 400,
                            fontSize: 16,
                            color: "rgba(134, 134, 134, 1)",
                          }}
                        >
                          Quantity:{"  "}
                        </p>
                        <p
                          style={{
                            color: "rgba(0, 0, 0, 1)",
                            display: "flex",
                            fontSize: 16,
                          }}
                        >
                          {produceItem?.quantity
                            ? produceItem?.quantity
                            : "N/A"}{" "}
                        </p>
                      </Typography>

                      <Typography
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 5,
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: 400,
                            fontSize: 16,
                            color: "rgba(134, 134, 134, 1)",
                          }}
                        >
                          Del/Collected:{"  "}
                        </p>
                        <p style={{ color: "black", display: "flex" }}>
                          {produceItem?.devOrCol
                            ? produceItem?.devOrCol
                            : "N/A"}{" "}
                        </p>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderOfferItem = () => {
    return (
      <Box
        key={produceItem.id}
        className={clsx({
          [classes.contentRow]: true,
          // [classes.disabled]: membersInfo[item._id || item.id],
          [classes.rowChecked]: !!selectedProduce.some(
            (user) => user.id === produceItem?.id
          ),
        })}
        onClick={() =>
          onChecked(
            selectedProduce.some((user) => user.id === produceItem?.id),
            produceItem
          )
        }
      >
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Box display="flex">
            <Avatar
              src={produceItem?.photos?.[0]?.thumbUrl}
              alt="user avatar"
              className={classes.userAvatar}
              variant="square"
            />
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                className={clsx({
                  [classes.userInfo]: true,
                })}
                width={"auto"}
              >
                <Typography className={classes.offerNameText}>
                  {produceItem?.name}
                </Typography>
                <Typography className={classes.emailPhone}>
                  <span
                    style={{ color: "black", fontSize: 20, fontWeight: 400 }}
                  >
                    {produceItem?.category ? produceItem?.category : "N/A"}{" "}
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box>
            <Checkbox
              checked={
                !!selectedProduce.some((user) => user.id === produceItem.id)
              }
              className={classes.Checkbox}
              sx={{ "& .MuiSvgIcon-root": { fontSize: "37px !important" } }}
              style={{
                color: !openOfferDemandPostDialog ? "#69DCAE" : "#E77228",
              }}
            />
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box style={{ marginTop: 20 }}>
            <Typography
              style={{ display: "flex", flexDirection: "row", gap: 5 }}
            >
              <p
                style={{
                  fontWeight: 400,
                  opacity: "0.5",
                  fontSize: 16,
                  color: "rgba(134, 134, 134, 1)",
                }}
              >
                Timeline:{"  "}
              </p>

              <p
                style={{
                  color: "rgba(0, 0, 0, 1)",

                  display: "flex",
                  fontSize: 16,
                }}
              >
                {fromDate ? fromDate : "N/A"} - {toDate ? toDate : "N/A"}{" "}
              </p>
            </Typography>
            <Typography
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 5,
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontWeight: 400,
                  opacity: "0.5",
                  fontSize: 16,
                  color: "rgba(134, 134, 134, 1)",
                }}
              >
                Destination:{"  "}
              </p>
              <p style={{ color: "black" }}>{destination} </p>
            </Typography>
            <Typography
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 5,
                alignItems: "center",
              }}
            >
              <p
                style={{
                  fontWeight: 400,
                  opacity: "0.5",
                  fontSize: 16,
                  color: "rgba(134, 134, 134, 1)",
                }}
              >
                Category:{"  "}
              </p>
              <p style={{ color: "black", display: "flex" }}>
                {produceItem?.category ? produceItem?.category : "N/A"}{" "}
              </p>
            </Typography>
          </Box>

          <Box style={{ display: "flex", justifyContent: "flex-start" }}>
            <hr
              style={{
                marginTop: 50,
                borderLeft: "1px solid #DFDFDF",
                width: 70,
                transform: "rotate(90deg)",
              }}
            />

            <Box
              style={{
                marginTop: 20,
              }}
            >
              <Typography
                style={{ display: "flex", flexDirection: "row", gap: 200 }}
              >
                <p
                  style={{
                    opacity: "0.5",
                    fontWeight: 400,
                    fontSize: 16,
                    color: "rgba(134, 134, 134, 1)",
                  }}
                >
                  Quantity:{"  "}
                </p>
                <p
                  style={{
                    color: "rgba(0, 0, 0, 1)",
                    display: "flex",
                    fontSize: 16,
                  }}
                >
                  {produceItem?.quantity ? produceItem?.quantity : "N/A"}{" "}
                </p>
              </Typography>
              <Typography
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    opacity: "0.5",
                    fontWeight: 400,
                    fontSize: 16,
                    color: "rgba(134, 134, 134, 1)",
                  }}
                >
                  Origin Country:{"  "}
                </p>
                <p style={{ color: "black", display: "flex" }}>
                  {produceItem?.countryOfOrigin?.name
                    ? produceItem?.countryOfOrigin?.name
                    : "N/A"}
                </p>
              </Typography>
              <Typography
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    opacity: "0.5",
                    fontWeight: 400,
                    fontSize: 16,
                    color: "rgba(134, 134, 134, 1)",
                  }}
                >
                  Del/Collected:{"  "}
                </p>
                <p style={{ color: "black", display: "flex" }}>
                  {produceItem?.devOrCol ? produceItem?.devOrCol : "N/A"}{" "}
                </p>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <div>
       {!openOfferDemandPostDialog ? renderDemandItem() : renderOfferItem()}
    </div>
  );
}
