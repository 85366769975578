import { Button, withStyles, makeStyles, FormLabel } from "@material-ui/core";
import React from "react";
import { Modal } from "react-bootstrap";
import Slider from "@mui/material/Slider";
import { marksSlider } from "./constants";

const useStyles = makeStyles({
  sliderBox: {
    marginLeft: 35,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    paddingRight: 20,
    gap: 20,
  },
  shareButton: {
    width: "100%",
    backgroundColor: "#e77228",
    border: "none",
    color: "white",
    borderRadius: 20,
  },
  closeButton: {
    backgroundColor: "white",
    border: "1px solid #e77228",
    width: "100%",
    color: "#e77228",
    borderRadius: 20,
  },
  customLikeModal: {
    borderRadius: "30px",
    border: "1px solid #E77228",
  },
});

const PrettoSlider = withStyles({
  root: {
    color: "#52af77",
    height: 2,
  },
  thumb: {
    color: "#E77228",
    background: "#E77228",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(2px)",
    height: 24,
    width: 24,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },

  valueLabel: {
    left: "calc(50% - 16px)",
    color: "#E77228",
    fontSize: "10px",
  },
  active: {},
  track: {
    height: 2,
    color: "#E77228",
  },
  rail: {
    height: 2,
    color: "#F1F1F1",
  },
})(Slider);

const TextPostChooseExpireModal = ({
  openModal,
  setOpenModal,
  sliderTextValue,
  addTextPost,
  sliderTextTextValue,
  setSliderTextValue,
}) => {
  const classes = useStyles();
  return (
    <Modal
      contentClassName={classes.customLikeModal}
      show={openModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className={` ${classes.sliderBox}`}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <FormLabel
              style={{
                fontSize: 12,
                color: "#000000",
              }}
            >
              How long will this post remain available?
            </FormLabel>
            <span style={{ fontSize: 10 }}>{sliderTextTextValue?.label}</span>
          </div>
          <PrettoSlider
            aria-label="Custom marks"
            style={{ width: "100%" }}
            value={sliderTextValue}
            onChange={(e) => setSliderTextValue(e.target.value)}
            defaultValue={16}
            max={16}
            step={null}
            marks={marksSlider}
          />
        </div>
      </Modal.Body>
      <div className="container p-3">
        <div className="row">
          <div className="col-6">
            <Button
              className={classes.closeButton}
              onClick={() => setOpenModal(false)}
            >
              Close
            </Button>
          </div>
          <div className="col-6">
            <Button className={classes.shareButton} onClick={addTextPost}>
              Share
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TextPostChooseExpireModal;
