import { API_404_ERROR_MESSAGES_ITEMS } from "@constants";
import toastr from "toastr";
import router from "next/router";

export const handleAPIErrors = (error) => {
  if (
    API_404_ERROR_MESSAGES_ITEMS.includes(
      error?.message || error || error.errors?.[0]?.message
    )
  ) {
    router.push({
      pathname: "/404",
      query: {
        message: error?.message || error,
      },
    });
    throw error;
  } else {
    router.push({
      pathname: "/404",
    });
  }
};
