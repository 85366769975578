import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  TextField,
  DialogActions,
  Typography,
  Button,
  withStyles,
  FormLabel,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { marks, marksSlider } from "./constants";
import { FruProButton, FruProEmptyData } from "@components";

import Slider from "@mui/material/Slider";
import toastr from "toastr";
import { updateOfferDemandPost } from "@services/amityEndPoints";
import clsx from "clsx";
import { useSelector } from "react-redux";
import FruProControllerTextField from "@components/ControllerTextField";
import TextAreaEditor from "./TextAreaEditor";
import { Editor, EditorState, Modifier, RichUtils } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "draft-js/dist/Draft.css";

const PrettoSlider = withStyles({
  root: {
    color: "#52af77",
    height: 2,
    width: "99%!important",
  },
  thumb: {
    color: "#E77228",
    background: "#E77228",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(2px)",
    height: 24,
    width: 24,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },

  valueLabel: {
    left: "calc(50% - 16px)",
    color: "#E77228",
    fontSize: "10px",
  },
  active: {},
  track: {
    height: 2,
    color: "#E77228",
  },
  rail: {
    height: 2,
    color: "#F1F1F1",
  },
})(Slider);

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
  },
  dialogContent: {
    width: "100%",
    height: "auto",
    overflowY: "auto",
    padding: "20px 0px",
  },
  contentRows: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },

  dialogTitle: {
    padding: "16px",
  },
  resetBtn: {
    padding: 0,
    minWidth: "unset",
    marginLeft: 8,
  },
  nextButton: {
    padding: "12px 24px",
    fontSize: "17px !important",
    fontWeight: 500,
    color: "white",
    backgroundColor: "#E77228 !important",
    borderRadius: "20px !important",
    minWidth: "235px !important",
    maxHeight: 44,
  },
  demandNextButton: {
    padding: "12px 24px",
    fontSize: "17px !important",
    fontWeight: 500,
    color: "white",
    backgroundColor: "#E77228 !important",
    borderRadius: "20px !important",
    minWidth: "235px !important",
    maxHeight: 44,
  },
  button: {
    padding: "12px 24px",
    fontSize: "17px !important",
    fontWeight: 500,
    color: "#E77228",
    borderRadius: "20px !important",
    minWidth: "235px !important",
    maxHeight: 44,
  },
  cancelButton: {
    background: "#ffffff",
    border: `1px solid #E77228`,
    color: "#E77228",
    textTransform: "capitalize",
    marginRight: 8,
  },
  demandCancelButton: {
    background: "#ffffff",
    border: `1px solid #E77228`,
    color: "#E77228",
    textTransform: "capitalize",
    marginRight: 8,
  },
  textAreaInput: {
    borderRadius: 20,
    width: "100%",
    position: "relative",
    border: "1px solid #E77228",
    backgroundColor: "white",
    overflowY: "auto",
    height: 200,
    maxHeight: 200,
    paddingTop: "30px",
    paddingLeft: 10,
  },
  searchContainer: {
    alignContent: "center !important",
    alignItems: "center !important",
  },
  searchButton: {
    backgroundColor: "#E77228",
    width: 65,
    height: 50,
    borderRadius: 35,
  },
  demandSearchButton: {
    backgroundColor: "#E77228",
    width: 65,
    height: 50,
    borderRadius: 35,
  },
  produceCard: {
    display: "flex",
    gap: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: 105,
    borderRadius: 10,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    padding: 10,
  },
  Input: {
    borderRadius: 20,
    border: "1px solid #E77228",
    backgroundColor: "white",
  },
  sliderBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%!important",
    marginTop: 20,
    gap: 5,
  },
  textField: {
    margin: "0 28px !important",
    width: "82%",
    cursor: "text",
    height: 120,
    minHeight: 120,
    maxHeight: 120,
    overflowY: "auto",
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      opacity: "0.3",
    },
  },
  customControlledField: {
    width: 120,
    background: "transparent",
    "& .MuiInputBase-root": {
      borderRadius: 20,

      "&::before": {
        borderColor: theme.palette.default.main,
      },
    },
    "& .MuiInputBase-input": {
      padding: 7,
    },
    "& .MuiInputLabel-root": {
      fontWeight: 400,
    },
  },
  infoBox: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: 30,
    borderRadius: 20,
    width: 75,
    background: "#F8F8F8",
  },
}));

function valuetext(value) {
  return `${value}°C`;
}

const PreviewOfferPopUp = ({
  watchSelectedBuyer,
  isPressedOfferPostMakeOffer,
  setIsPressedOfferPostMakeOffer,
  produces,
  renderType,
  setRenderType,
  watch,
  watchProduces,
  setIsAddNew,
  setValue,
  control,
  sliderOfferValue,
  sliderText,
  setSliderOfferValue,
  setOpenSelectBuyerModal,
  setProduces,
  setOpenPreviewOfferPopup,
  openPreviewOfferPopup,
  setOpenOfferDemandPostDialog,
  editedOfferDemandPostText,
  setEditedOfferDemandPostText,
  onSubmit,
  setIsUpdate,
  isUpdate,
  postId,
  setPostId,
  setGlobalFeedData,
  globalFeedData,
  editorState,
  setEditorState,
}) => {
  const classes = useStyles();

  const user = useSelector((state) => state.auth.user);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isError, setIsError] = useState(false);

  const editorRef = useRef(null);

  const currentInputQuantity = watch(`produces[${selectedIndex}].quantity`);

  const produceQuantity = produces?.find(
    (item, index) => index === selectedIndex
  );

  useEffect(() => {
    if (isUpdate) {
      const newProduces = produces?.map((item, index) => ({
        quantity: item?.newQuantity,
        price: item?.newPrice,
        priceOnRequest: item?.newPriceOnRequest,
      }));
      setValue("produces", newProduces);
    }
  }, [isUpdate]);

  useEffect(() => {
    if (produceQuantity?.quantity > parseFloat(currentInputQuantity)) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [selectedIndex, produceQuantity, currentInputQuantity]);

  const onDeleteProduce = (id, index) => {
    const newProduces = produces?.filter((item) => item?.id !== id);

    setProduces(newProduces);

    const newItems = watchProduces?.filter((item, i) => i !== index);
    setValue("produces", newItems);
  };

  const onPressShare = () => {
    const isDisabled = watchProduces.filter((item) =>
      item?.priceOnRequest
        ? item.quantity === "" || item.quantity === undefined
        : item.price === "" ||
          item.quantity === "" ||
          item.price === undefined ||
          item.quantity === undefined
    );

    if (isDisabled?.length || !watchProduces?.length) {
      if (watchProduces?.length > 1) {
        toastr.error("Plase fill all quantity and price fields for produces");
      } else {
        toastr.error("Plase fill  quantity and price field for produce");
      }
    } else {
      onSubmit();
    }
  };

  const onUpdate = async () => {
    const isDisabled = watchProduces.filter((item) =>
      item?.priceOnRequest
        ? item.quantity === "" || item.quantity === undefined
        : item.price === "" ||
          item.quantity === "" ||
          item.price === undefined ||
          item.quantity === undefined
    );

    if (isDisabled?.length || !watchProduces?.length) {
      if (watchProduces?.length > 1) {
        toastr.error("Plase fill all quantity and price fields for produces");
      } else {
        toastr.error("Plase fill  quantity and price field for produce");
      }
    } else {
      const currentContent = editorState.getCurrentContent();
      const currentContentAsText = currentContent.getPlainText();
      const currentContentAsHtml = stateToHTML(currentContent);
      const itemPriceQuantity = produces?.map((item, index) => {
        return watchProduces?.find((produce, i) => i === index);
      });

      const newAddedPriceQuantityProduces = produces?.map((item, index) => ({
        ...item,
        newQuantity: parseFloat(itemPriceQuantity?.[index]?.quantity),
        newPrice: itemPriceQuantity?.[index]?.price,
        newPriceOnRequest: itemPriceQuantity?.[index]?.priceOnRequest
          ? itemPriceQuantity?.[index]?.priceOnRequest
          : false,
      }));
      const response = await updateOfferDemandPost(
        "offer",
        postId,
        newAddedPriceQuantityProduces,
        user,
        currentContentAsText,
        currentContentAsHtml,
        sliderText.label === "This post will not expire"
          ? null
          : sliderText.label
      );
      if (response) {
        setSliderOfferValue(16);
        const index = globalFeedData?.posts.findIndex(
          (item) => item?.postId === postId
        );

        let newPosts = { ...globalFeedData };

        const updatedPost = newPosts?.posts;

        updatedPost[index] = response?.posts[0];

        setGlobalFeedData(newPosts);
        setEditorState(EditorState.createEmpty());
        setEditedOfferDemandPostText("");
        setOpenPreviewOfferPopup(false);
        setProduces([]);
      }
    }
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 30,
          border: "1px solid #E77228",
          padding: "10px 30px",
          width: "100%",
        },
      }}
      open={openPreviewOfferPopup}
      //onClose={handleCloseDialog}
    >
      <Box className={classes.dialogHeader}>
        <Typography
          variant="h6"
          style={{
            color: "#E77228",
            fontWeight: 600,
          }}
          align="start"
        >
          Desired price & specifics
        </Typography>
        {!isPressedOfferPostMakeOffer ? (
          <img
            onClick={() => {
              setOpenPreviewOfferPopup(false);
              setOpenOfferDemandPostDialog(true);
            }}
            style={{ cursor: "pointer" }}
            src="/static/svg/Close_round.svg"
          />
        ) : null}
      </Box>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.contentRows}>
          {!produces?.length ? (
            <FruProEmptyData>You don't have any selected offer</FruProEmptyData>
          ) : (
            produces?.map((item, index) => {
              return (
                <div key={index} className="d-flex flex-row gap-2">
                  {" "}
                  <Box className={classes.produceCard}>
                    <Box
                      style={{ display: "flex", alignItems: "center", gap: 10 }}
                    >
                      <img
                        style={{
                          width: "65px",
                          overflow: "hidden",
                          borderRadius: 10,
                          height: "100%",
                          objectFit: "contain",
                          backgroundSize: "contain",
                        }}
                        src={item?.photos?.[0]?.url}
                      />

                      <Box>
                        <Typography style={{ fontSize: 16, fontWeight: 400 }}>
                          {item?.name}
                        </Typography>
                        <Typography style={{ fontSize: 16, fontWeight: 400 }}>
                          {item?.category}
                        </Typography>
                      </Box>
                    </Box>
                    <div className="d-flex flex-row gap-3">
                      <div
                        onClick={() => setSelectedIndex(index)}
                        className="d-flex gap-2 flex-column"
                      >
                        <FruProControllerTextField
                        
                          inputProps={{ maxLength: 6, disableUnderline: true }}
                          defaultValue={
                            !isPressedOfferPostMakeOffer && item?.newQuantity
                          }
                          className={classes.customControlledField}
                          name={`produces[${index}].quantity`}
                          placeholder={`QTY(min ${item.quantity})`}
                          label=""
                          control={control}
                          disableUnderline={true}
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#000000" },
                          }}
                        />
                        <FruProControllerTextField
                          inputProps={{ maxLength: 6, disableUnderline: true }}
                          disabled={watch(`produces[${index}].priceOnRequest`)}
                          defaultValue={
                            !isPressedOfferPostMakeOffer && item?.newPrice
                          }
                          className={classes.customControlledField}
                          name={`produces[${index}].price`}
                          placeholder="Price"
                          label=""
                          control={control}
                          disableUnderline={true}
                          InputLabelProps={{
                            shrink: true,
                            style: { color: "#000000" },
                          }}
                        />
                      </div>

                      <div className="d-flex flex-column gap-2">
                        <div className={classes.infoBox}>
                          <span style={{ opacity: 0.5 }}>
                            {item?.unit?.name}
                          </span>
                        </div>
                        <div className={classes.infoBox}>
                          <span style={{ opacity: 0.5 }}>
                            {item?.currency?.symbol}
                          </span>
                        </div>
                      </div>
                      {watchSelectedBuyer?.id ? null : (
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={
                                !isPressedOfferPostMakeOffer &&
                                item?.newPriceOnRequest
                              }
                              onChange={(e) => {
                                setValue(
                                  `produces[${index}].priceOnRequest`,
                                  e.target.checked
                                );
                                setValue(`produces[${index}].price`, "");
                              }}
                              value={watch(`produces[${index}].priceOnRequest`)}
                              style={{ color: "#E77228" }}
                            />
                          }
                          label="Price on request"
                        />
                      )}
                    </div>
                  </Box>
                  <img
                    onClick={() => onDeleteProduce(item?.id, index)}
                    style={{ cursor: "pointer" }}
                    src="/static/svg/Close_round_delete.svg"
                  />
                </div>
              );
            })
          )}

          {/* <Typography
            onClick={() => {
              setProduces([]);
              setValue("produces", []);
            }}
            style={{
              color: "rgba(231, 40, 40, 1)",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Remove All
          </Typography> */}
        </div>
      </DialogContent>

      <Box>
        {/* <TextField
          defaultValue={editedOfferDemandPostText}
          value={editedOfferDemandPostText}
          onChange={(e) => setEditedOfferDemandPostText(e.target.value)}
          style={{ width: "100%" }}
          inputProps={{ maxLength: 500 }}
          InputProps={{
            disableUnderline: true,
            className: classes.Input,
            endAdornment: (
              <>
                <Typography
                  style={{
                    left: 10,
                    bottom: -10,
                    position: "absolute",
                    color: "gray",
                  }}
                >
                  {editorState.getCurrentContent().getPlainText().length}/500
                </Typography>
              </>
            ),
            style: { backgroundColor: "white" },
          }}
          multiline
          rows={10}
          placeholder="Add text"
        /> */}
        <div
          className={classes.textAreaInput}
          onClick={() => editorRef.current.focus()}
        >
          <TextAreaEditor
            editorState={editorState}
            setEditorState={setEditorState}
            editorRef={editorRef}
            placeholder="Add Text"
          />
        </div>
        <Typography
          style={{
            marginTop: 10,
          }}
        >
          {editorState.getCurrentContent().getPlainText().length}/500
        </Typography>
        <div className={` ${classes.sliderBox}`}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <FormLabel
              style={{
                fontSize: 12,
                color: "#000000",
              }}
            >
              How long will this post remain available?
            </FormLabel>
            <span style={{ fontSize: 10 }}>{sliderText?.label}</span>
          </div>

          <PrettoSlider
            onChange={(e) => setSliderOfferValue(e.target.value)}
            aria-label="Custom marks"
            max={16}
            defaultValue={16}
            getAriaValueText={valuetext}
            step={null}
            marks={marksSlider}
          />
        </div>

        <DialogActions
          style={{
            padding: 0,
            margin: 0,
            width: "100%",
            justifyContent: "space-between",
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          <Button
            onClick={() => {
              setValue("produces", []);
              setIsAddNew(false);
              setValue("companyName", "");
              setValue("email", "");
              setValue("selectedBuyer", {});
              setOpenOfferDemandPostDialog(false);
              setOpenSelectBuyerModal(false);
              setEditedOfferDemandPostText("");
              setOpenPreviewOfferPopup(false);
              setProduces([]);
              setEditorState(EditorState.createEmpty());
            }}
            className={clsx(classes.button, classes.cancelButton)}
            color="primary"
          >
            Cancel
          </Button>
          <FruProButton
            disabled={!produces.length || isError}
            className={clsx(classes.nextButton)}
            style={{ margin: 0 }}
            color="primary"
            onClick={() => {
              isUpdate ? onUpdate() : onPressShare();
            }}
            //disabled={!selecteds?.length || shareLoading}
          >
            Share
          </FruProButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default PreviewOfferPopUp;
