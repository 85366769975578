import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  withStyles,
  TextField,
  DialogActions,
  Typography,
  Button,
  FormLabel,
} from "@material-ui/core";
import { FruProButton, FruProEmptyData } from "@components";
import clsx from "clsx";
import { marks, marksSlider } from "./constants";
import Slider from "@mui/material/Slider";

import { useSelector } from "react-redux";
import { updateOfferDemandPost } from "@services/amityEndPoints";
import TextAreaEditor from "./TextAreaEditor";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 40px",
  },
  dialogContent: {
    width: "100%",
    height: "auto",
    overflowY: "auto",
    padding: "20px 0px",
  },
  contentRows: {
    padding: 20,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },

  dialogTitle: {
    padding: "16px",
  },
  resetBtn: {
    padding: 0,
    minWidth: "unset",
    marginLeft: 8,
  },
  nextButton: {
    padding: "12px 24px",
    fontSize: "17px !important",
    fontWeight: 500,
    color: "white",
    backgroundColor: "#69CDAE !important",
    borderRadius: "20px !important",
    minWidth: "235px !important",
    maxHeight: 44,
  },
  demandNextButton: {
    padding: "12px 24px",
    fontSize: "17px !important",
    fontWeight: 500,
    color: "white",
    backgroundColor: "#69CDAE !important",
    borderRadius: "20px !important",
    minWidth: "235px !important",
    maxHeight: 44,
  },
  button: {
    padding: "12px 24px",
    fontSize: "17px !important",
    fontWeight: 500,
    color: "#69CDAE",
    borderRadius: "20px !important",
    minWidth: "235px !important",
    maxHeight: 44,
  },
  cancelButton: {
    background: "#ffffff",
    border: `1px solid #69CDAE`,
    color: "#69CDAE",
    textTransform: "capitalize",
    marginRight: 8,
  },
  demandCancelButton: {
    background: "#ffffff",
    border: `1px solid #69CDAE`,
    color: "#69CDAE",
    textTransform: "capitalize",
    marginRight: 8,
  },
  searchContainer: {
    alignContent: "center !important",
    alignItems: "center !important",
  },
  searchButton: {
    backgroundColor: "#69CDAE",
    width: 65,
    height: 50,
    borderRadius: 35,
  },
  demandSearchButton: {
    backgroundColor: "#69CDAE",
    width: 65,
    height: 50,
    borderRadius: 35,
  },
  produceCard: {
    display: "flex",
    gap: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: 480,
    height: 65,
    borderRadius: 10,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  },
  Input: {
    borderRadius: 20,
    border: "1px solid #69CDAE",
    backgroundColor: "white",
  },
  sliderBox: {
    paddingLeft: 35,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "475px!important",
    marginTop: 20,
    gap: 5,
  },
  textAreaInput: {
    borderRadius: 20,
    width: "500px",
    position: "relative",
    border: "1px solid #69cdae",
    backgroundColor: "white",
    overflowY: "auto",
    height: 200,
    marginLeft:10,
    maxHeight: 200,
    padding: "10px",
  },
}));

const PrettoSlider = withStyles({
  root: {
    color: "#52af77",
    height: 2,
  },
  thumb: {
    color: "#69CDAE",
    background: "#69CDAE",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(2px)",
    height: 24,
    width: 24,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },

  valueLabel: {
    left: "calc(50% - 16px)",
    color: "#69CDAE",
    fontSize: "10px",
  },
  active: {},
  track: {
    height: 2,
    color: "#69CDAE",
  },
  rail: {
    height: 2,
    color: "#F1F1F1",
  },
})(Slider);

function valuetext(value) {
  return `${value}°C`;
}

const PreviewOfferPopUp = ({
  editorState,
  setEditorState,
  produces,
  sliderText,
  setProduces,
  sliderDemandValue,
  setSliderDemandValue,
  setOpenPreviewDemandPopup,
  openPreviewDemandPopup,
  setOpenDemandPostDialog,
  editedOfferDemandPostText,
  setEditedOfferDemandPostText,
  onSubmit,
  setIsUpdate,
  isUpdate,
  postId,
  setPostId,
  globalFeedData,
  setGlobalFeedData,
}) => {
  const classes = useStyles();

  const user = useSelector((state) => state.auth.user);


  const editorRef = useRef(null);

  const onDeleteProduce = (id) => {
    const newProduces = produces?.filter((item) => item?.id !== id);

    setProduces(newProduces);
  };

  const onUpdate = async () => {
    
    const currentContent = editorState.getCurrentContent();
    const currentContentAsText = currentContent.getPlainText();
    const currentContentAsHtml = stateToHTML(currentContent);
    const response = await updateOfferDemandPost(
      "demand",
      postId,
      produces,
      user,
      currentContentAsText,
      currentContentAsHtml,
      sliderText.label === "This post will not expire" ? null : sliderText.label
    );

    if (response) {
      setSliderDemandValue(16);
      const index = globalFeedData?.posts.findIndex(
        (item) => item?.postId === postId
      );
      setEditorState(EditorState.createEmpty())
      let newPosts = { ...globalFeedData };

      const updatedPost = newPosts?.posts;

      updatedPost[index] = response?.posts[0];

      setGlobalFeedData(newPosts);
      setEditedOfferDemandPostText("");
    }
  };
  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: 30, border: "1px solid #69CDAE" },
      }}
      scroll="body"
      open={openPreviewDemandPopup}
      //onClose={handleCloseDialog}
      maxWidth="sm"
    >
      <Box className={classes.dialogHeader}>
        <Typography
          variant="h6"
          style={{
            color: "#69CDAE",
            fontWeight: 600,
          }}
          align="start"
        >
          Share Demand
        </Typography>
        <img
          onClick={() => {
            setOpenPreviewDemandPopup(false);
            setOpenDemandPostDialog(true);
          }}
          style={{ cursor: "pointer" }}
          src="/static/svg/Close_round.svg"
        />
      </Box>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.contentRows}>
          {!produces?.length ? (
            <FruProEmptyData>
              You don't have any selected demands
            </FruProEmptyData>
          ) : (
            produces?.map((item) => {
              return (
                <Box className={classes.produceCard}>
                  <Box
                    style={{ display: "flex", alignItems: "center", gap: 10 }}
                  >
                    <img
                      style={{
                        width: "65px",
                        overflow: "hidden",
                        borderRadius: 10,
                        height: "100%",
                        objectFit: "contain",
                        backgroundSize: "contain",
                      }}
                      src={item?.photos?.[0]?.url}
                    />

                    <Box>
                      <Typography style={{ fontSize: 16, fontWeight: 400 }}>
                        {item?.name}
                      </Typography>
                      <Typography style={{ fontSize: 16, fontWeight: 400 }}>
                        {item?.category}
                      </Typography>
                    </Box>
                  </Box>
                  <img
                    onClick={() => onDeleteProduce(item?.id)}
                    style={{ cursor: "pointer", marginRight: 10 }}
                    src="/static/svg/Close_round_delete.svg"
                  />
                </Box>
              );
            })
          )}

      
        </div>
      </DialogContent>

      <Box>
      <div
          className={classes.textAreaInput}
          onClick={() => editorRef.current.focus()}
        >
          <TextAreaEditor
            editorState={editorState}
            setEditorState={setEditorState}
            editorRef={editorRef}
            placeholder="Add Text"
          />
        </div>
        <Typography
          style={{
            marginTop: 10,
            marginLeft:10
          }}
        >
          {editorState.getCurrentContent().getPlainText().length}/500
        </Typography>
        <div className={` ${classes.sliderBox} `}>
          <div
            style={{
              width: "475px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <FormLabel
              style={{
                fontSize: 12,
                color: "#000000",
              }}
            >
              How long will this post remain available?
            </FormLabel>
            <span style={{ fontSize: 10 }}>{sliderText?.label}</span>
          </div>
          <div>
            <PrettoSlider
              style={{ width: 475 }}
              aria-label="Custom marks"
              onChange={(e) => setSliderDemandValue(e.target.value)}
              defaultValue={16}
              max={16}
              getAriaValueText={valuetext}
              step={null}
              marks={marksSlider}
            />
          </div>
        </div>

        <DialogActions style={{ marginRight: 25 }}>
          <Button
            onClick={() => {
              setOpenPreviewDemandPopup(false);
              setProduces([]);
              setEditedOfferDemandPostText("");
              setEditorState(EditorState.createEmpty())
            }}
            className={clsx(classes.button, classes.cancelButton)}
            color="primary"
          >
            Cancel
          </Button>
          <FruProButton
            disabled={!produces.length}
            className={clsx(classes.nextButton)}
            color="primary"
            onClick={() => {
              isUpdate ? onUpdate() : onSubmit();
              setOpenPreviewDemandPopup(false);
              setProduces([]);
            }}
            //disabled={!selecteds?.length || shareLoading}
          >
            Share Demand
          </FruProButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default PreviewOfferPopUp;
