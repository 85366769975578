import React, { useState, useEffect, useMemo } from "react";
import clsx from "clsx";
import { Box, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import "react-multi-carousel/lib/styles.css";
import { getDailyProduces } from "@services/produce-explore";
import { useDispatch, useSelector } from "react-redux";
import { getCart } from "@reducers/cart";
import { COMPANY_STATUS_ENUM } from "@constants";
import { useShallowRouting } from "@utils/customHook";
import { getAvailableUsers } from "@services/user";

import {
  setIsFirstTimeGetChannelsSuccess,
  setNewChatInfo,
} from "@reducers/chat";
import AddPostItem from "./AmityContainer/AddpostItem";
import SocialItem from "./AmityContainer/SocialItem";
import { setAdvancedSearchParamsAction } from "@reducers/global";
import { getLastestVersion } from "../../services/info";

import {
  globalFeeds,
  updateUserInfo,
  personalGlobalFeeds,
  limitedCompanyGlobalFeeds,
  otherGlobalFeeds,
  AddUserToFruPro,
} from "@services/amityEndPoints";
import { AmityRegister, registerAmityInDashboard } from "@services/auth";
import LoadingIndicator from "@components/LoadingIndicator";
import NavItem from "./components/NavItem";
import NavCollapse from "./components/NavCollapse";
import { AmptitudeEvents } from "@services/amptitude_events";
import { PAGE_VIEW } from "@services/amptitude_contants";
import { useRouter } from "next/router";
import { signout } from "@reducers/auth";
import { Helmet } from "react-helmet";
import { EditorState } from "draft-js";
import { projectId } from "../../services/firebaseConfig.json";
const responsiveProducts = {
  desktop: {
    breakpoint: { max: 4000, min: 0 },
    items: 4,
  },
};

const useStyles = makeStyles((theme) => ({
  dashboard: {
    width: "750px",
  },
  bannerLeft: {
    height: "240px",
    width: "124px",
    backgroundColor: theme.palette.grey.main,
    position: "fixed",
    left: "8px",
    marginTop: "16px",
  },
  bannerRight: {
    height: "240px",
    width: "124px",
    backgroundColor: theme.palette.grey.main,
    position: "fixed",
    right: "8px",
    marginTop: "16px",
  },
  dailyDeal: {
    "& .react-multi-carousel-dot-list": {
      marginTop: "24px",
    },
  },
  tab: {
    padding: "24px 0 16px",
    fontSize: "18px",
    color: theme.palette.grey.text,
    textTransform: "none",

    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },
  },
  sectionTitle: {
    fontSize: 21,
    fontWeight: "bold",
    lineHeight: "36px",
    marginLeft: 10,
  },
  relatedCarousel: {
    "& .react-multi-carousel-track": {
      margin: "0 -4px",
    },
    "& .react-multi-carousel-item": {
      padding: "0 8px",
    },
    "& .react-multiple-carousel__arrow": {
      background: theme.palette.primary.main,
      opacity: 0.8,
      top: 92,
    },
    "& .react-multiple-carousel__arrow--right": {
      right: 20,
    },
    "& .react-multiple-carousel__arrow--left": {
      left: 12,
    },
  },
  sectionPopular: {
    marginTop: 32,
    "& .section-header": {
      background: theme.palette.initial.main,
      padding: 16,
      borderRadius: "22px 22px 0 0",
    },
  },
  borderBottomProducts: {
    borderBottom: "5px solid #69CDAE !important",
    borderBottomWidth: "2px !important",
    width: "27%",
  },
  postBox: {
    width: "1000px !important",
    height: "160px !important",
    margin: "auto",
    boxShadow: "0px 20px 60px rgba(241, 244, 248, 0.5) !important",
    borderRadius: "4px !important",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    boxSizing: "border-box !important",
    alignItems: "center",
  },
  postHeader: {
    margin: "24px 28px",
    fontSize: "16px",
    alignItems: "center",
  },
  textField: {
    margin: "0 28px !important",
  },
  iconContainer: {
    justifyContent: "flex-end",
    gap: "0.5rem",
    display: "flex",
  },
  sideBarBox: {
    height: "100%",
    margin: "0 auto",
    backgroundColor: "white",
    borderRadius: 20,
  },
  mainContainer: {
    margin: "0 auto",
    maxWidth: "1250px",
    marginBottom: 50,
  },
}));

export default function DashboardContainer() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const Router = useRouter();
  const [renderType, setRenderType] = useState("yes");
  const [openPreviewOfferPopup, setOpenPreviewOfferPopup] = useState(false);
  const [openPreviewDemandPopup, setOpenPreviewDemandPopup] = useState(false);
  const [openUpdateTextPostPopup, setOpenUpdateTextPostPopup] = useState(false);

  const [isPressedOfferPostMakeOffer, setIsPressedOfferPostMakeOffer] =
    useState(false);
  const [makeOfferBuyerCompanyId, setMakeOfferBuyerCompanyId] = useState("");
  const [openOfferDemandPostDialog, setOpenOfferDemandPostDialog] =
    useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [openFileModal, setOpenFileModal] = useState(false);

  const [editFileVideoImage, setEditFileVideoImage] = useState(false);

  const [companyLoading, setCompanyLoading] = useState(false);
  const [selectedProduce, setSelectedProduce] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [postId, setPostId] = useState(null);

  const [limitedCompanyGlobalFeed, setLimitedCompanyGlobalFeed] = useState([]);
  // const [openLimitedCompanyModal, setOpenLimitedCompanyModal] = useState(false);
  const [openLikesModal, setOpenLikesModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [postText, setPostText] = useState("");
  const [editedPostText, setEditedPostText] = useState("");
  const [offerDemandPostText, setOfferDemandPostText] = useState("");
  const [editedOfferDemandPostText, setEditedOfferDemandPostText] =
    useState("");
  const [postTextArea, setPostTextArea] = useState("");
  const dispatch = useDispatch();
  const [router, shallowQuery, onChangeShallowQuery] = useShallowRouting();
  const user = useSelector((state) => state.auth.user);
  const company = useSelector((state) => state.auth.company);
  const isCompanyLoaded = useSelector((state) => state.auth.isCompanyLoading);
  const global = useSelector((state) => state.preferences);
  const [activeTab, setActiveTab] = useState("social");
  const [globalFeedData, setGlobalFeedData] = useState({});

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [editorEditedTextState, setEditorEditedTextState] = useState(() =>
    EditorState.createEmpty()
  );
  const [textAreaEditorState, setTextAreaEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [previewOfferEditorState, editPreviewOfferEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const { tab } = router.query || {};

  const isLimitedCompany = user?.companyStatus !== COMPANY_STATUS_ENUM.VERIFIED;

  const amityToken = localStorage.getItem("amityAccessToken");

  //this effect for  removing the localstorage item for if buyer or seller is not verified and redirect them to company

  useEffect(() => {
    if (user?.id && !user?.isVerified) {
      dispatch(signout());
      Router.push("/login");
    }
  }, [user?.id, user?.isVerified]);

  useEffect(() => {
    document.title = "Latest | FruPro";
    localStorage.removeItem("sellOn");
    localStorage.removeItem("buyOn");
  }, []);

  //event
  useEffect(() => {
    if (user?.id && user?.companyId) {
      const sendEvent = async () => {
        return await AmptitudeEvents(
          "Timeline",
          user?.id,
          PAGE_VIEW,
          window.location.href,
          user.companyId,
          user.companyName,
          "",
          "homepage",
          ""
        );
      };
      sendEvent();
    }
  }, [user?.id]);

  //companyData

  useEffect(() => {
    const registerAmityAfterPaymentSkip = async () => {
      const registerResponse = await AmityRegister(
        user?.email && user?.companyId ? user?.email : "demo.user@frupro.com"
      );
      const communityResponse = await AddUserToFruPro();
    };
    registerAmityAfterPaymentSkip();
  }, [user?.email]);

  useEffect(() => {
    MyFeeds();
  }, []);

  useEffect(() => {
    if (!user?.id) {
      const registerAmity = async () => {
        const tokenRes = await registerAmityInDashboard();
        if (tokenRes && !user?.id && !amityToken) {
          setLoading(true);
          const response = await globalFeeds(tokenRes);
          setLoading(false);
          setGlobalFeedData(response);
        }
      };
      registerAmity();
    }
  }, [user?.id]);

  const MyFeeds = async () => {
    if (amityToken) {
      const response = await personalGlobalFeeds(
        user?.email && user?.companyId ? user?.email : "demo.user@frupro.com"
      );
    }
  };

  useEffect(() => {
    if (amityToken) {
      getGlobalFeeds();
    }
  }, []);

  const getGlobalFeeds = async () => {
    setLoading(true);
    const response = await globalFeeds();
    setLoading(false);
    setGlobalFeedData(response);
  };

  const nextPage = async () => {
    if (globalFeedData?.paging?.next) {
      const newRes = await otherGlobalFeeds(globalFeedData.paging.next);

      const newGlobalFeedData = {
        ...globalFeedData,
        paging: newRes?.paging,
        posts: [...globalFeedData?.posts, ...newRes?.posts],
      };

      setGlobalFeedData(newGlobalFeedData);
    }
  };

  const prevPage = async () => {
    if (globalFeedData?.paging?.previous) {
      const newRes = await otherGlobalFeeds(globalFeedData.paging.previous);

      setGlobalFeedData(newRes);
      scrollToTop();
    }
  };

  useEffect(() => {
    updateUser();
  }, []);

  const updateUser = async () => {
    if (amityToken) {
      const response = await updateUserInfo(
        user?.fullName,
        user?.photo?.thumbUrl
      );
    }
  };

  const isFirstTimeGetChannelsSuccess = useSelector(
    (state) => state.chat.isFirstTimeGetChannelsSuccess
  );

  const { chatbox } = shallowQuery;

  const isRestrictUser = useMemo(() => {
    const restrictNormalUser =
      !user?.companyId && user?.type !== "COMPANY_ADMIN";

    const restrictAdmin =
      user?.companyStatus === COMPANY_STATUS_ENUM.PENDING &&
      user?.type === "COMPANY_ADMIN";

    return restrictNormalUser || restrictAdmin;
  }, [user]);

  useEffect(() => {
    if (user?.companyId) {
      dispatch(getCart(user));
    }
  }, []);

  useEffect(() => {
    onChangeShallowQuery();
    if (chatbox && isFirstTimeGetChannelsSuccess) {
      handleSetNewChat();
    }
  }, [chatbox, isFirstTimeGetChannelsSuccess]);

  // async function getVersionInfo() {
  //   try {
  //     const data = await getLastestVersion();

  //     const lastestVersion = data.version.web;
  //     const currentVersion = localStorage.getItem("version");
  //     const openedBefore = localStorage.getItem("opened");

  //     if (
  //       lastestVersion !== currentVersion ||
  //       lastestVersion == null ||
  //       currentVersion == null
  //     ) {
  //       if (
  //         (openedBefore == "false" || openedBefore == null) &&
  //         data?.content?.length !== 0
  //       ) {
  //         router.push("/whats-new");
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // useEffect(() => {
  //   getVersionInfo();
  // }, []);

  const handleSetNewChat = () => {
    getAvailableUsers({ keyword: chatbox, includes: "company" })
      .then((response) => {
        const { items: userList } = response;

        if (userList?.length) {
          dispatch(
            setNewChatInfo({
              companyId: userList[0].companyId,
              email: userList[0].email,
              fullName: userList[0].fullName,
              id: userList[0].id,
              photo: userList[0].photo,
              companySlug: userList[0]?.companySlug,
              companyName: userList[0]?.company?.companyName,
            })
          );
        }

        dispatch(setIsFirstTimeGetChannelsSuccess(false));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const MenuItems = [
    // {
    //   name: "Home",
    //   href: "/",
    //   src: "/static/svg/Home_light.svg",
    //   type: "item",
    // },
    // {
    //   name: "My Orders",
    //   href: "/",
    //   src: "/static/svg/Order_light_black.svg",
    //   type: "collapse",
    //   children: [
    //     {
    //       src: "/static/svg/User_box_light.svg",
    //       //activeSrc: "/static/svg/ic_company_profile_active.svg",
    //       name: "Purchases",
    //       href: "/purchases/orders-placed",
    //       type: "item",
    //     },
    //     {
    //       src: "/static/svg/User_box_light.svg",
    //       //activeSrc: "/static/svg/ic_colleagues_active.svg",
    //       name: "Sales",
    //       href: "/sales/orders-received",
    //       type: "item",
    //     },
    //   ],
    // },
    // {
    //   name: "Available Products",
    //   href: "/produce-explore",
    //   src: "/static/svg/Order_light_black.svg",
    //   type: "item",
    // },
    // {
    //   name: "Demands",
    //   href: "/demanded-products",
    //   src: "/static/svg/Chart_alt_light.svg",
    //   type: "item",
    // },
    {
      name: "Latest",
      href: "/",
      src: "/static/svg/Chart_alt_light.svg",
      type: "item",
    },
    {
      name: "Products",
      href: "/produce-explore",
      src: "/static/svg/Box_alt_light.svg",
      type: "item",
    },
    {
      name: "Demands",
      href: "/demanded-products",
      src: "/static/svg/Shop_light_gray.svg",
      type: "item",
    },
    {
      name: "Company Directory",
      href: "/company-directory",
      src: "/static/svg/info_gray.svg",
      type: "item",
    },
    {
      name: "Connections",
      href: "/connection-explore",
      src: "/static/svg/User_box_light.svg",
      type: "item",
    },
    {
      name: "Donations",
      href: "/my-donations/my-donation-listings",
      src: "/static/svg/Donations_gray.svg",
      type: "item",
    },
    // {
    //   name: "Get Support",
    //   href: "/",
    //   src: "/static/svg/User_box_light.svg",
    //   type: "item",
    //   trigger: "openReport",
    // },
    // {
    //   name: "What's new",
    //   href: "/whats-new",
    //   src: "/static/svg/User_box_light.svg",
    //   type: "item",
    // },
  ];

  const onNavigateLogin = () => {
    if (!user?.id) {
      router.push("/login");
    } else if (user?.id && !user?.isVerified) {
      router.push("/sign-up");
    } else if (user?.id && user?.isVerified && !user?.companyId) {
      router.push("/company-registration");
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {projectId === "frupro-staging"
            ? "🟡Latest | FruPro"
            : "Latest | FruPro"}
        </title>
      </Helmet>
      {isCompanyLoaded ? (
        <LoadingIndicator loading={isCompanyLoaded} />
      ) : user?.id && !user?.isVerified ? null : (
        <div className="container">
          <div className="row">
            <div
              className="col-3"
              style={{
                paddingLeft: 0,
                height: "100%",
                margin: "0 auto",
                backgroundColor: "white",
                borderRadius: 20,
              }}
            >
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "15px 20px",
                }}
                className="menu-list"
              >
                {MenuItems?.map((item, index) => {
                  switch (item.type) {
                    case "collapse":
                      return <NavCollapse key={index} item={item} />;
                    case "item":
                      return <NavItem key={index} item={item} />;
                    default:
                      break;
                  }
                })}
              </ul>
            </div>
            <div className="col-9" style={{ paddingLeft: 30 }}>
              <>
                {loading ? (
                  <LoadingIndicator loading={loading} />
                ) : (
                  <AddPostItem
                    editorState={editorState}
                    editorEditedTextState={editorEditedTextState}
                    setEditorEditedTextState={setEditorEditedTextState}
                    setEditorState={setEditorState}
                    isPressedOfferPostMakeOffer={isPressedOfferPostMakeOffer}
                    setIsPressedOfferPostMakeOffer={
                      setIsPressedOfferPostMakeOffer
                    }
                    previewOfferEditorState={previewOfferEditorState}
                    editPreviewOfferEditorState={editPreviewOfferEditorState}
                    textAreaEditorState={textAreaEditorState}
                    setTextAreaEditorState={setTextAreaEditorState}
                    onNavigateLogin={onNavigateLogin}
                    company={company}
                    renderType={renderType}
                    setRenderType={setRenderType}
                    makeOfferBuyerCompanyId={makeOfferBuyerCompanyId}
                    setMakeOfferBuyerCompanyId={setMakeOfferBuyerCompanyId}
                    setOpenOfferDemandPostDialog={setOpenOfferDemandPostDialog}
                    openOfferDemandPostDialog={openOfferDemandPostDialog}
                    editedOfferDemandPostText={editedOfferDemandPostText}
                    setEditedOfferDemandPostText={setEditedOfferDemandPostText}
                    editedPostText={editedPostText}
                    setEditedPostText={setEditedPostText}
                    postTextArea={postTextArea}
                    setPostTextArea={setPostTextArea}
                    editFileVideoImage={editFileVideoImage}
                    setEditFileVideoImage={setEditFileVideoImage}
                    openImageModal={openImageModal}
                    setOpenImageModal={setOpenImageModal}
                    openVideoModal={openVideoModal}
                    setOpenVideoModal={setOpenVideoModal}
                    openFileModal={openFileModal}
                    setOpenFileModal={setOpenFileModal}
                    openUpdateTextPostPopup={openUpdateTextPostPopup}
                    setOpenUpdateTextPostPopup={setOpenUpdateTextPostPopup}
                    postId={postId}
                    setPostId={setPostId}
                    setIsUpdate={setIsUpdate}
                    isUpdate={isUpdate}
                    setSelectedProduce={setSelectedProduce}
                    selectedProduce={selectedProduce}
                    setOpenPreviewDemandPopup={setOpenPreviewDemandPopup}
                    setOpenPreviewOfferPopup={setOpenPreviewOfferPopup}
                    openPreviewDemandPopup={openPreviewDemandPopup}
                    openPreviewOfferPopup={openPreviewOfferPopup}
                    // openLimitedCompanyModal={openLimitedCompanyModal}
                    offerDemandPostText={offerDemandPostText}
                    setOfferDemandPostText={setOfferDemandPostText}
                    // setOpenLimitedCompanyModal={setOpenLimitedCompanyModal}
                    openLikesModal={openLikesModal}
                    setOpenLikesModal={setOpenLikesModal}
                    isLimitedCompany={isLimitedCompany}
                    getGlobalFeeds={getGlobalFeeds}
                    postText={postText}
                    selectedImages={selectedImages}
                    selectedVideos={selectedVideos}
                    setSelectedVideos={setSelectedVideos}
                    setSelectedImages={setSelectedImages}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    setPostText={setPostText}
                    setGlobalFeedData={setGlobalFeedData}
                    globalFeedData={globalFeedData}
                    nextPage={nextPage}
                    prevPage={prevPage}
                  />
                )}

                {loading ? (
                  <LoadingIndicator loading />
                ) : (
                  <SocialItem
                    editorEditedTextState={editorEditedTextState}
                    setEditorEditedTextState={setEditorEditedTextState}
                    editorState={editorState}
                    setEditorState={setEditorState}
                    previewOfferEditorState={previewOfferEditorState}
                    editPreviewOfferEditorState={editPreviewOfferEditorState}
                    textAreaEditorState={textAreaEditorState}
                    setTextAreaEditorState={setTextAreaEditorState}
                    isPressedOfferPostMakeOffer={isPressedOfferPostMakeOffer}
                    setIsPressedOfferPostMakeOffer={
                      setIsPressedOfferPostMakeOffer
                    }
                    selectedProduce={selectedProduce}
                    onNavigateLogin={onNavigateLogin}
                    renderType={renderType}
                    setRenderType={setRenderType}
                    setMakeOfferBuyerCompanyId={setMakeOfferBuyerCompanyId}
                    setOpenOfferDemandPostDialog={setOpenOfferDemandPostDialog}
                    openOfferDemandPostDialog={openOfferDemandPostDialog}
                    editedOfferDemandPostText={editedOfferDemandPostText}
                    setEditedOfferDemandPostText={setEditedOfferDemandPostText}
                    editedPostText={editedPostText}
                    setEditedPostText={setEditedPostText}
                    postTextArea={postTextArea}
                    setPostTextArea={setPostTextArea}
                    editFileVideoImage={editFileVideoImage}
                    setEditFileVideoImage={setEditFileVideoImage}
                    openImageModal={openImageModal}
                    setOpenImageModal={setOpenImageModal}
                    openVideoModal={openVideoModal}
                    setOpenVideoModal={setOpenVideoModal}
                    openFileModal={openFileModal}
                    setOpenFileModal={setOpenFileModal}
                    setPostText={setPostText}
                    postText={postText}
                    openUpdateTextPostPopup={openUpdateTextPostPopup}
                    setOpenUpdateTextPostPopup={setOpenUpdateTextPostPopup}
                    postId={postId}
                    setPostId={setPostId}
                    setIsUpdate={setIsUpdate}
                    isUpdate={isUpdate}
                    setOfferDemandPostText={setOfferDemandPostText}
                    offerDemandPostText={offerDemandPostText}
                    setSelectedProduce={setSelectedProduce}
                    setOpenPreviewDemandPopup={setOpenPreviewDemandPopup}
                    setOpenPreviewOfferPopup={setOpenPreviewOfferPopup}
                    openPreviewDemandPopup={openPreviewDemandPopup}
                    openPreviewOfferPopup={openPreviewOfferPopup}
                    // openLimitedCompanyModal={openLimitedCompanyModal}
                    // setOpenLimitedCompanyModal={setOpenLimitedCompanyModal}
                    isLimitedCompany={isLimitedCompany}
                    openLikesModal={openLikesModal}
                    setOpenLikesModal={setOpenLikesModal}
                    selectedImages={selectedImages}
                    selectedVideos={selectedVideos}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    setSelectedVideos={setSelectedVideos}
                    setSelectedImages={setSelectedImages}
                    setGlobalFeedData={setGlobalFeedData}
                    globalFeedData={globalFeedData}
                    nextPage={nextPage}
                    loading={loading}
                    prevPage={prevPage}
                  />
                )}
              </>
            </div>
          </div>
        </div>
      )}
      {/* {loading ? <LoadingIndicator loading={loading} /> : null} */}
      <div className="container">
        <Grid
          className={classes.mainContainer}
          container
          xs={10}
          md={10}
          lg={10}
          xl={6}
        >
          <Grid item xs={3} className={classes.sideBarBox}></Grid>
          <Grid
            item
            xs={8}
            style={{
              width: "100%",
              margin: "0 auto",
              borderRadius: 20,
              alignItems: "center",
            }}
          >
            {/* <MAINPAGETABS
            activeKey={activeTab}
            mountOnEnter
            id="mycolleagues-controlled-tab"
            onSelect={handleSelectTab}
          >
            {MAIN_PAGE_TABS.map(({ eventKey, title, children }) => (
              <Tab eventKey={eventKey} title={title} key={eventKey}>
                {children}
              </Tab>
            ))}
          </MAINPAGETABS> */}
          </Grid>
          {/* {!isRestrictUser && <DemandedProductsSection />} */}
        </Grid>
      </div>
    </>
  );
}
