import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { Box, Collapse } from "@material-ui/core";
import NavItem from "./NavItem";
import clsx from "clsx";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  navCollapse: {
    //margin: "3px 0",
  },
  navCollapseInfo: {
    position: "relative",
    width: "100%",
    height: 55,
    color: "rgba(124, 124, 124, 1)",
    // marginBottom: 3,
    "&.active, &:hover": {
      background: "rgba(231, 114, 40, 0.3)",
      color: "black",
      width: "100%",
      height: 55,
      borderRadius: 10,
      cursor: "pointer",
    },
    display: "flex",
    alignItems: "center",
    minHeight: 36,
    "& .nav-icon": {
      margin: "0 12px",
    },
    "& .name": {
      fontSize: 15,
      fontWeight: 500,
    },
    "& .arrow-icon": {
      position: "absolute",
      right: 16,
    },
  },
  collapseList: {
    paddingLeft: 18,
  },
}));

export default function NavCollapse({ item }) {
  const classes = useStyles();
  const router = useRouter();
  const [openCollapse, setOpenCollapse] = useState(
    router.pathname.includes(item.href) || item.href.includes(router.pathname)
  );
  const [selected, setSelected] = useState(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (
      selected === item.href ||
      router.pathname?.includes(item.href) ||
      item.href.includes(router.pathname)
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [selected, router.pathname]);

  useEffect(() => {
    handleCollapse();
  }, [router.pathname]);

  const handleCollapse = () => {
    const childrenHrefArray = item?.children?.map(
      (item) => item?.href?.split("?")[0]
    );

    if (childrenHrefArray.includes(router.pathname)) {
      setOpenCollapse(true);
      setIsActive(true);
    } else {
      setOpenCollapse(false);
      setIsActive(false);
    }
  };

  return (
    <li
      key={item.name}
      onClick={(e) => {
        e.preventDefault();
        setSelected(!selected ? item.href : null);
        setOpenCollapse(!openCollapse);
      }}
      className={classes.navCollapse}
    >
      <Box
        className={clsx(classes.navCollapseInfo, isActive ? "active" : null)}
        minHeight="36px"
      >
        <img className="nav-icon" width="20" height="20" src={item.src} />
        <span className="name">{item.name}</span>
        <img
          src={
            openCollapse
              ? `/static/svg/ic_expand_more.svg`
              : `/static/svg/ic_chevron_right.svg`
          }
          alt="Right arrow icon"
          className="arrow-icon"
        />
      </Box>
      <Box style={{ background: "white" }}>
        <Collapse in={openCollapse} className={classes.collapseList}>
          <ul>
            {item?.children?.map((child, index) => {
              return <NavItem key={index} item={child} />;
            })}
          </ul>
        </Collapse>
      </Box>
    </li>
  );
}
