import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Tooltip,
  CardContent,
  Card,
} from "@material-ui/core";
import Link from "next/link";
import { COMPANY_STATUS_ENUM } from "@constants";
import { useSelector } from "react-redux";
const OfferDemandPostItem = ({
  onNavigateLogin,
  produce,
  isLimitedCompany,
  // setOpenLimitedCompanyModal,
}) => {
  const useStyles = makeStyles((theme) => ({
    card: {
      width: "100%",
      height: "100%",
      boxShadow: "0px 4px 22px rgba(0, 0, 0, 0.05)",
      margin: "15px 10px",
      borderRadius: "6px 6px 6px 6px",
      overflow: "visible",
      backgroundColor: theme.palette.initial.main,
    },
    cardContent: {
      position: "relative",
      padding: "6px 12px 24px",
      fontSize: "15px !important",
      borderRadius: "6px 6px 0 0",
    },
    categoryContent: {
      display: "flex",
      justifyContent: "space-between",
    },
    media: {
      position: "relative",
      borderRadius: 20,
      height: 200,
      cursor: "pointer",
      width: "100%",

      // 16:9
      backgroundColor: "#F5F5F5",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      borderRadius: "6px 6px 6px 6px",
    },
    title: {
      fontSize: 16,
      color: "#13151B",
      display: "inline-block",
     
      width: "250px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontWeight: "bold",
    },
    customTooltip: {
      fontSize: 14,
      fontWeight: 400,
    },
    category: {
      fontWeight: "500",
      // fontSize: 12,
      color: "#858688",
    },
    smallTypo: {
      fontSize: 12,
    },
    price: {
      color: theme.palette.primary.main,
      fontWeight: 700,
      margin: "0 4px",
    },

    verifiedPriceSection: {
      display: "flex",
      justifyContent: "space-between",
    },
    textGrey: {
      color: theme.palette.grey.label,
      fontSize: 16,
    },
    unitText: {
      color: "#e77228",
      fontSize: 16,
    },
    categoryText: {
      opacity: 0.5,
      fontSize: 16,
      whiteSpace: "nowrap",
    },
    textSize: {
      fontSize: 16,
    },
    availableButton: {
      backgroundColor: "#B1F59D",
      color: "white",
      marginTop: 10,
      width: 100,
      height: 22,
      alignItems: "center",
      textAlign: "center",
      borderRadius: 20,
    },
    unAvailableButton: {
      backgroundColor: "#F6133D",
      color: "white",
      marginTop: 10,
      width: 100,
      height: 22,
      alignItems: "center",
      textAlign: "center",
      borderRadius: 20,
    },
  }));

  const classes = useStyles();

  const user = useSelector((state) => state.auth.user);

  const canEditProduce = useMemo(() => {
    const ownerProduceIds = produce?.owners?.map((owner) => owner?.id);
    // If user is produce owner or creator then user can edit or delete produce
    if (
      (produce?.user?.id || produce?.user) === user?.id ||
      ownerProduceIds?.includes(user?.id)
    ) {
      return true;
    }

    return false;
  }, [produce]);

  const getProduceHref = () => {
    let pathname = "/produce-explore/[id]";
    let query = {
      id: produce?._id || produce?.id,
    };

    /**
     * If user is one of owners or creator of produce then redirect to my produce
     * Else if user have same company with produce then redirect to my company
     */
    if (canEditProduce) {
      pathname = "/my-company/detail-produce";
      query = {
        produceId: produce?.id || produce?._id,
        prevTab: "myProducts",
      };
    } else if (produce?.isMyCompany) {
      pathname = "/my-company/detail-produce";
      query = {
        produceId: produce?.id || produce?._id,
        prevTab: "myCompany",
      };
    }

    return {
      pathname,
      query,
    };
  };

  const renderCompanyVerifiedIcon = () =>
    produce?.companyInfo?.status === COMPANY_STATUS_ENUM.VERIFIED &&
    !produce?.hideVerified ? (
      <img
        className={classes.verifiedIcon}
        src={`/static/svg/ic_company_verified.svg`}
        alt="Verified icon"
      />
    ) : null;

  return !user?.id ? (
    <Box style={{ cursor: "pointer" }} onClick={onNavigateLogin}>
      <Card
        className={classes.card}
        elevation={0}
        onDragStart={(e) => {
          e.preventDefault();
        }}
      >
        <img className={classes.media} src={produce?.photos?.[0]?.thumbUrl} />
        <CardContent className={classes.cardContent}>
          <Tooltip
            title={"FruPro"}
            arrow
            classes={{
              tooltip: classes.customTooltip,
            }}
          >
            <span className={classes.title}>{produce?.name}</span>
          </Tooltip>

          <Box className={classes.categoryContent} marginBottom={1}>
            <Typography className="text-sm fw-medium">
              {produce?.name}
            </Typography>
            <Typography className={classes.categoryText}>
              {produce?.category}
            </Typography>
            {/* <Typography className={"text-sm"}>
            <span className={classes.textGrey}>{produce?.companyName}</span>
          </Typography> */}
          </Box>
          <Typography className={"text-sm"}>
            <span className={classes.textGrey}> Min QTY: </span>
            <Typography className={classes.textSize} display="inline">
              {produce?.quantity ? produce?.quantity : "N/A"}
            </Typography>
          </Typography>
          <Box className={classes.verifiedPriceSection}>
            <Typography className={"text-sm"}>
              <span className={classes.textGrey}>
                {" "}
                {produce.priceGuide ? "Price guide" : "Del/Collected"}:{" "}
              </span>
              <Typography className={classes.unitText} display="inline">
                {produce?.priceGuide
                  ? `${produce.currency.symbol}${produce.priceGuide}`
                  : produce.devOrCol
                  ? produce.devOrCol
                  : "N/A"}
                {produce.priceGuide ? "/" : ""}
                {produce.priceGuide
                  ? produce?.unit?.name && produce?.unit?.name
                  : ""}
              </Typography>
            </Typography>
            {renderCompanyVerifiedIcon()}
          </Box>
          <Typography>
            {produce.status === "Unavailable" ? (
              <Box className={classes.unAvailableButton}>Unavailable</Box>
            ) : (
              <Box className={classes.availableButton}>Available</Box>
            )}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  ) : (
    <a
      style={{ textDecoration: "none" }}
      href={
        canEditProduce
          ? `/my-company/detail-produce?produceId=${
              produce?.id || produce?._id
            }`
          : `/produce-explore/${produce?._id || produce?.id}`
      }
    >
      <Card
        className={classes.card}
        elevation={0}
        onDragStart={(e) => {
          e.preventDefault();
        }}
      >
        <img className={classes.media} src={produce?.photos?.[0]?.thumbUrl} />
        <CardContent className={classes.cardContent}>
          <Tooltip
            title={"FruPro"}
            arrow
            classes={{
              tooltip: classes.customTooltip,
            }}
          >
            <span className={classes.title}>{produce?.name}</span>
          </Tooltip>

          <Box className={classes.categoryContent} marginBottom={1}>
            <Typography className="text-sm fw-medium">
              {produce?.name}
            </Typography>
            <Typography className={classes.categoryText}>
              {produce?.category}
            </Typography>
            {/* <Typography className={"text-sm"}>
            <span className={classes.textGrey}>{produce?.companyName}</span>
          </Typography> */}
          </Box>
          <Typography className={"text-sm"}>
            <span className={classes.textGrey}> Min QTY: </span>
            <Typography className={classes.textSize} display="inline">
              {produce?.quantity ? produce?.quantity : "N/A"}
            </Typography>
          </Typography>
          <Box className={classes.verifiedPriceSection}>
            <Typography className={"text-sm"}>
              <span className={classes.textGrey}>
                {" "}
                {produce?.newPriceOnRequest
                  ? "Price on request"
                  : produce.priceGuide
                  ? "Price guide : "
                  : "Del/Collected"}
                {}{" "}
              </span>
              <Typography className={classes.unitText} display="inline">
                {produce?.newPriceOnRequest
                  ? null
                  : produce?.priceGuide
                  ? `${produce.currency.symbol}${produce.priceGuide}`
                  : produce.devOrCol
                  ? produce.devOrCol
                  : "N/A"}
                {produce?.newPriceOnRequest
                  ? null
                  : produce.priceGuide
                  ? "/"
                  : ""}
                {produce?.newPriceOnRequest
                  ? null
                  : produce.priceGuide
                  ? produce?.unit?.name && produce?.unit?.name
                  : ""}
              </Typography>
            </Typography>
            {renderCompanyVerifiedIcon()}
          </Box>
          <Typography>
            {produce.status === "Unavailable" ? (
              <Box className={classes.unAvailableButton}>Unavailable</Box>
            ) : (
              <Box className={classes.availableButton}>Available</Box>
            )}
          </Typography>
        </CardContent>
      </Card>
    </a>
  );
};

export default OfferDemandPostItem;
