import { axiosInstance } from "services";
import toastr from "toastr";
import { queryObjSerialize } from "@utils/index";

export const getUserDetails = async (userSlug) => {
  try {
    const response = await axiosInstance.get(`/users/slug/${userSlug}`);

    if (response) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
  }
};
export const getUserDetailsWithId = async (userId) => {
  try {
    const response = await axiosInstance.get(`/users/id/${userId}`);

    if (response) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
  }
};

export const getCommonLinks = async (userId1,userId2) => {
  try {
    const response = await axiosInstance.get(`/get-common-links?user1=${userId1}&&user2=${userId2}`);

    if (response) {
     
      return response.data;
    }
  } catch (error) {
    console.error(error);
    toastr.error(error);
  }
};


export const changePassword = async (oldPassword, newPassword) => {
  try {
    if (!oldPassword || !newPassword) return;
    const { data, status } = await axiosInstance.put("update-password", {
      oldPassword,
      newPassword,
    });
    if ([200, 204].includes(status)) return data;
    return null;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getInvitations = async () => {
  try {
    const result = await axiosInstance.get("/employee-invitations");
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const acceptRequest = async (requestId) => {
  try {
    const result = await axiosInstance.put(`employee-invitations/${requestId}`);
    if (result?.status === 204) {
      toastr.success("Accepted Successfully");
      return {
        isSuccess: true,
      };
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.errors?.[0]?.message || error.message || error);
  }
};

export const cancelRequest = async (requestId) => {
  try {
    const result = await axiosInstance.delete(
      `employee-invitations/${requestId}`
    );
    if (result?.status === 204) {
      toastr.success("Cancel Successfully");
      return {
        isSuccess: true,
      };
    }
  } catch (error) {
    console.error(error);
    toastr.error(error.errors?.[0]?.message || error.message || error);
  }
};

export const getAvailableUsers = async (queryParams) => {
  try {
    let queryStrings = queryObjSerialize(queryParams);
    const result = await axiosInstance.get(`/available-users?${queryStrings}`);
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};
