import React, { useState, useEffect } from "react";

import {
  Avatar,
  Box,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import { expireDateVariables } from "./components/constants";
import clsx from "clsx";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import OfferDemandPostItem from "./components/OfferDemandPostItem";
import PostActionItem from "./components/PostActionItem";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-multi-carousel";
import LoadingIndicator from "@components/LoadingIndicator";
import FruProDialog from "@components/Dialog";
import DemandPostItem from "./components/DemandPostItem";
import InputAdornment from "@mui/material/InputAdornment";
import { setNewChatInfo } from "@reducers/chat";
import { FruProButton, FruProLimitedCompanyModal } from "@components";
import Linkify from "react-linkify";
import { Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  deletePost,
  reportPost,
  getListOfReactionInPost,
  addViewCountToPost,
} from "@services/amityEndPoints";
import Link from "next/link";
import router from "next/router";
import moment from "moment";
import { postTextShow } from "@utils/index";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInView } from "react-intersection-observer";
import ConsigmentsOfferPopUp from "./components/ConsigmentsOfferPopUp";
import CompanyNotRegisteredModal from "@components/CompanyNotRegisteredModal";
import HtmlMapper from "react-html-map";
import { ContentState, convertFromHTML, EditorState } from "draft-js";

const DROPDOWN = styled(Dropdown)`
  padding: 0;
  .dropdown-item:hover {
    // background: #69CDAE;
    // opacity: 0.1;
    color: #69cdae;
  }
  .dropdown-toggle::after {
    display: none;
  }
  a {r
    height: 30px;
    color: ${(props) => props.theme.texts.black};
    text-decoration: none;
    &:active {
      color: ${(props) => props.theme.texts.black};
      background: #f3f3f3;
    }
    .name {
      margin-left: 12px;
      font-weight: 500;
      font-size: 15px;
    }
  }

  button {
    height: 48px;
    border: none;
    box-shadow: none !important;
    display: flex;
    align-items: center;
    padding: 10px;
    color: ${(props) => props.theme.texts.black} !important;
    border-radius: 0;
    background: transparent !important;
  }
`;

const FeedItem = ({
  editorEditedTextState,
  setEditorEditedTextState,
  editorState,
  setEditorState,
  previewOfferEditorState,
  editPreviewOfferEditorState,
  textAreaEditorState,
  setTextAreaEditorState,
  isPressedOfferPostMakeOffer,
  setIsPressedOfferPostMakeOffer,
  renderType,
  setRenderType,
  setMakeOfferBuyerCompanyId,
  setOpenOfferDemandPostDialog,
  openOfferDemandPostDialog,
  editedOfferDemandPostText,
  setEditedOfferDemandPostText,
  postTextArea,
  editedPostText,
  setEditedPostText,
  setPostTextArea,
  editFileVideoImage,
  setEditFileVideoImage,
  selectedImages,
  selectedVideos,
  selectedFiles,
  setSelectedFiles,
  setSelectedVideos,
  setSelectedImages,
  onNavigateLogin,
  openImageModal,
  setOpenImageModal,
  openVideoModal,
  setOpenVideoModal,
  openFileModal,
  setOpenFileModal,
  globalFeedData,
  openLikesModal,
  setOpenLikesModal,
  setGlobalFeedData,
  nextPage,
  isLimitedCompany,
  // openLimitedCompanyModal,
  // setOpenLimitedCompanyModal,
  prevPage,
  loading,
  setOpenPreviewDemandPopup,
  setOpenPreviewOfferPopup,
  openPreviewDemandPopup,
  openPreviewOfferPopup,
  selectedProduce,
  setSelectedProduce,
  setOfferDemandPostText,
  offerDemandPostText,
  setIsUpdate,
  isUpdate,
  postId,
  setPostId,
  openUpdateTextPostPopup,
  setOpenUpdateTextPostPopup,
  postText,
  setPostText,
}) => {
  const useStyles = makeStyles((theme) => ({
    feedItems: {
      height: "auto",
      padding: 20,
      borderRadius: "20px!important",
      backgroundColor: "white!important",
      //margin: "36px 0",
    },
    expiredFeedItems: {
      opacity: 0.4,
    },
    userItem: {
      display: "flex",
      alignContent: "center",
    },
    userJobText: {
      color: "rgba(133, 134, 136, 1)",
      marginLeft: 5,
      fontSize: 16,
      cursor: "pointer",
    },
    timeText: {
      color: "rgba(133, 134, 136, 1)",
    },
    companyText: {
      marginLeft: 10,
      fontSize: 16,
      cursor: "pointer",
      fontWeight: 700,
    },
    timeText: {
      fontSize: 16,
      color: "#727477",
      display: "flex",
      alignContent: "flex-end",
      alignItems: "flex-end",
    },
    feedContent: {
      display: "flex !important",
      margin: "0 18px",
      alignItems: "center",
      marginTop: 10,
      justifyContent: "!important",
      alignContent: "center",
    },

    offerDemandFeedContent: {
      display: "flex",
      margin: "0 18px",
      alignItems: "center",
      marginTop: 15,
      justifyContent: "space-between",
      alignContent: "center",
    },
    feedText: {
      fontSize: 17,
      fontWeight: 400,
      wordBreak: "break-word",
    },
    likeContent: {
      display: "flex",
      marginTop: 20,
      margin: "0 18px",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "10px",
    },
    likeText: {
      fontSize: "14px",
      fontWeight: 500,
    },
    card: {
      width: "100%",
      height: "100%",
      margin: "0px 10px",
      borderRadius: "6px 6px 6px 6px",
      overflow: "visible",
      backgroundColor: theme.palette.initial.main,
    },
    cardContent: {
      position: "relative",
      padding: "6px 12px 24px",
      fontSize: "15px !important",
      borderRadius: "6px 6px 0 0",
    },
    media: {
      position: "relative",
      height: 240,
      width: 240,

      // 16:9
      backgroundColor: "#F5F5F5",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      cursor: "pointer",
      borderRadius: "6px 6px 6px 6px",
    },
    title: {
      fontSize: 19,
      color: "#13151B",
      display: "inline-block",
      width: "auto",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontWeight: "bold",
    },
    customTooltip: {
      fontSize: 14,
      fontWeight: 400,
    },
    category: {
      fontWeight: "500",
      // fontSize: 12,
      color: "#858688",
    },
    smallTypo: {
      fontSize: 12,
    },
    price: {
      color: theme.palette.primary.main,
      fontWeight: 700,
      margin: "0 4px",
    },
    verifiedIcon: {
      position: "absolute",
      top: 6,
      right: 6,
      maxWidth: 24,
      maxHeight: 24,
    },
    textGrey: {
      color: theme.palette.grey.label,
    },
    unitText: {
      color: "#e77228",
    },
    pagination: {
      alignItems: "center !important ",
      margin: "20px auto !important",
      textAlign: "center",
      display: "flex",
      gap: 10,
      bottom: 15,
      position: "relative",
      justifyContent: "center",
    },
    paginationText: {
      "&:hover": {
        color: "black !important",
      },
    },
    fileContent: {
      display: "flex",
      border: "1px solid hsl(226.7,9.9%,92.8%)",
      borderRadius: "4px",
      overFlow: "hidden",
      alignItems: "center",
      width: "auto",
      marginTop: 10,
      justifyContent: "flex-start",
      gap: 20,
      height: "auto",
    },
    InfoContent: {
      display: "flex",
      justifyContent: "space-between",
    },
    dotIcon: {
      backgroundColor: "##f8f8f8 !important",
      cursor: "pointer",
      width: 20,
      height: 20,
    },
    deleteContent: {
      padding: `${theme.spacing(4)}px 20px`,
    },
    MoreDemandContainer: {
      marginTop: 20,

      textAlign: "center",
    },
    MoreDemandText: {
      fontSize: 20,
    },
    messageActionIconsBox: {
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
    },
    messageActionIconsMakeOfferBox: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
    expiredTag: {
      fontSize: 12,
      background: "rgba(237, 0, 0, 0.14)",
      color: "rgba(237, 0, 0, 1)",
      padding: 5,
      width: 100,
      height: 25,
      borderRadius: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    typeContent: {},
    customLikeModal: {
      borderRadius: "30px",
      border: "1px solid #E77228",
    },
    likeCountBox: {
      width: 103,
      height: 35,
      borderRadius: 20,
      backgroundColor: "#E77228",
      display: "flex",
      gap: 6,
      justifyContent: "center",
      alignItems: "center",
    },
    LikeHeaderCountBox: {
      display: "flex",
      gap: 20,
    },
    likeSearchContainer: {
      display: "flex",
      gap: 10,
      alignItems: "center",
    },
    searchInputContainer: {
      borderRadius: 35,
      border: "1px solid #E77228",
      display: "flex",
      width: 400,
      height: 50,
    },
    searchButtonContainer: {
      display: "flex",
      alignItems: "center",
      borderRadius: "35px important",
      backgroundColor: "#E77228",
      width: 65,
      height: 50,
    },
    likedUsersContainer: {
      marginTop: 21,
    },
    likedUserItem: {
      display: "flex",
      flexDirection: "row",
      gap: 10,
    },
    makeOfferInactive: {
      position: "absolute",
      top: 10,
      right: 15,
      display: " flex",
      borderRadius: 10,
      color: "white",
      cursor: "pointer",
      background: "#737373",
      width: "164px",
      height: "35px",
      fontWeight: 600,
      padding: "6px 0px",
      justifyContent: "center",
      alignItems: "center",
    },
    makeOffer: {
      position: "absolute",
      top: 10,
      right: 15,
      display: " flex",
      borderRadius: 10,
      color: "white",
      cursor: "pointer",
      background: "#69CDAE",
      width: "164px",
      height: "35px",
      fontWeight: 600,
      padding: "6px 0px",
      justifyContent: "center",
      alignItems: "center",
    },
    makeOfferInactiveText: {
      position: "absolute",
      top: 8,
      right: 45,
      display: " flex",
      borderRadius: 10,
      color: "white",
      cursor: "pointer",
      background: "#737373",
      width: "164px",
      height: "35px",
      fontWeight: 600,
      padding: "6px 0px",
      justifyContent: "center",
      alignItems: "center",
    },
    makeOfferText: {
      position: "absolute",
      top: 6,
      right: 75,
      display: " flex",
      borderRadius: 10,
      color: "white",
      cursor: "pointer",
      background: "#69CDAE",
      width: "164px",
      height: "35px",
      fontWeight: 600,
      padding: "6px 0px",
      justifyContent: "center",
      alignItems: "center",
    },
  }));
  const responsiveProducts = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const classes = useStyles();

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const [openCompanyNotRegistered, setOpenCompanyNotRegistered] =
    useState(false);
  const [openNotEditablePopUp, setOpenNotEditablePopup] = useState(false);
  const [openBiggerImageModal, setOpenBiggerImageModal] = useState(false);
  const [openBiggerVideoModal, setOpenBiggerVideoModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [likedUsers, setLikedUsers] = useState([]);
  const [likedUsersLoading, setLikedUsersLoading] = useState(false);
  const [newArr, setNewArr] = useState([]);
  const [likeCount, setLikeCount] = useState(0);

  const [keyword, setKeyword] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);

  const [fileUrl, setFileUrl] = useState(null);
  const [socialItem, setSocialItem] = useState([]);
  const [videoUrl, setVideoUrl] = useState(null);

  TimeAgo.addDefaultLocale(en);

  const deleteUserPost = async (postId) => {
    const response = await deletePost(postId);

    if (response) {
      const newDeletedGlobalFeedData = { ...globalFeedData };

      const deletedPostIndex = newDeletedGlobalFeedData.posts.findIndex(
        (item) => item.postId === postId
      );

      const deletedGlobalFeed =
        newDeletedGlobalFeedData?.posts[deletedPostIndex];

      deletedGlobalFeed.isDeleted = true;

      setGlobalFeedData({ ...newDeletedGlobalFeedData });
    }
  };

  const reportUserPost = async (postId) => {
    const response = await reportPost(postId);

    if (response) {
      const newFlaggedGlobalFeedData = { ...globalFeedData };

      const flaggedPostIndex = newFlaggedGlobalFeedData.posts.findIndex(
        (item) => item.postId === postId
      );

      const flaggedGlobalFeed =
        newFlaggedGlobalFeedData?.posts[flaggedPostIndex];

      flaggedGlobalFeed.hashFlag = response?.posts?.[0]?.hashFlag;

      setGlobalFeedData({ ...newFlaggedGlobalFeedData });
    }
  };

  const biggerImage = (i) => {
    setFileUrl(i.fileUrl);
    setOpenBiggerImageModal(true);
  };

  const biggerVideo = () => {
    setOpenBiggerVideoModal(true);
    setVideoUrl(item?.fileUrl);
  };

  const filterLikedUsers = () => {
    const filteredUsers = likedUsers.filter((user) =>
      user?.users?.[0].displayName.toLowerCase().includes(keyword)
    );

    setLikedUsers(filteredUsers);
  };

  const openEditImagePostModal = (images) => {
    setOpenImageModal(true);
    setSelectedImages(images);
  };

  const openEditVideoPostModal = (videos) => {
    setOpenVideoModal(true);
    setSelectedVideos(videos);
  };

  const openEditFilePostModal = (files) => {
    setOpenFileModal(true);
    setSelectedFiles(files);
  };

  const editSetPostText = (video, image, file, text) => {
    if (!video?.length && !image?.length && !file?.length) {
      setEditorEditedTextState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(text))
        )
      );
    } else {
      setTextAreaEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(text))
        )
      );
    }
  };
  const [openConsigmentsOfferPopUp, setOpenConsigmentsOfferPopUp] =
    useState(false);
  const [pressedOfferText, setPressedOfferText] = useState();
  const [pressedCompanyId, setPressedCompanyId] = useState();
  const onPressMakeOfferToOfferPost = (produces) => {
    setOpenPreviewOfferPopup(true);
    setIsPressedOfferPostMakeOffer(true);
    setSelectedProduce(produces);
  };
  const onPressMakeOfferToTextPost = (text, item) => {
    setPressedCompanyId(item?.metadata?.user?.companyId);
    setPressedOfferText(text);
    setOpenConsigmentsOfferPopUp(true);
  };

  const renderBiggerImageModal = () => {
    return openBiggerImageModal ? (
      <Modal
        show={openBiggerImageModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <img
            style={{ width: "100%", height: 600, objectFit: "contain" }}
            src={`${fileUrl}?size=large`}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "#e77228",
              border: "none",
              color: "white",
            }}
            onClick={() => setOpenBiggerImageModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    ) : null;
  };
  const renderBiggerVideoModal = () => {
    return openBiggerVideoModal ? (
      <Modal
        show={openBiggerVideoModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <video
            controls
            style={{ width: "100%", height: 600, objectFit: "contain" }}
            src={`${videoUrl}`}
          ></video>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "#e77228",
              border: "none",
              color: "white",
            }}
            onClick={() => setOpenBiggerVideoModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    ) : null;
  };
  const renderNotEditableModal = () => {
    return openNotEditablePopUp ? (
      <Modal
        show={openNotEditablePopUp}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          This post can be edited within 3 minutes of being shared
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{
              backgroundColor: "#e77228",
              border: "none",
              color: "white",
            }}
            onClick={() => setOpenNotEditablePopup(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    ) : null;
  };

  const arrayButtons = [
    {
      onClick: () => setOpenDeleteModal(false),
      color: "default",
      text: "Cancel",
    },
    {
      onClick: async () => {
        deleteUserPost(postId);
        setOpenDeleteModal(false);
      },
      color: "error",
      text: "Delete",
    },
  ];
  const reportArrayButtons = [
    {
      onClick: () => setOpenReportModal(false),
      color: "default",
      text: "Cancel",
    },
    {
      onClick: async () => {
        reportUserPost(postId);
        setOpenReportModal(false);
      },
      color: "error",
      text: "Report",
    },
  ];

  const renderDeleteModal = () => {
    return (
      <FruProDialog
        size="small"
        title="Delete Post"
        buttons={arrayButtons}
        open={openDeleteModal}
        titleBorder
        onClose={() => setOpenDeleteModal(false)}
      >
        <Typography className={classes.deleteContent} variant="body1">
          Are you sure you want to delete this post?
        </Typography>
      </FruProDialog>
    );
  };
  const renderReportModal = () => {
    return (
      <FruProDialog
        size="small"
        title="Report Post"
        buttons={reportArrayButtons}
        open={openReportModal}
        titleBorder
        onClose={() => setOpenReportModal(false)}
      >
        <Typography className={classes.deleteContent} variant="body1">
          Are you sure you want to report this post?
        </Typography>
      </FruProDialog>
    );
  };

  const renderLikesModal = () => {
    return (
      <Modal
        scrollable={true}
        contentClassName={classes.customLikeModal}
        show={openLikesModal}
        onHide={() => setOpenLikesModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ border: "none" }} closeButton>
          <Box className={classes.LikeHeaderCountBox}>
            <Box className={classes.likeCountBox}>
              <img src="/static/svg/Favorite_fill.svg" />
              <Typography style={{ color: "white", fontSize: 16 }}>
                {likeCount}
              </Typography>
            </Box>
            <Modal.Title
              style={{ color: "#E77228" }}
              id="contained-modal-title-vcenter"
            >
              Likes
            </Modal.Title>
          </Box>
        </Modal.Header>
        <Modal.Body>
          <Box className={classes.likeSearchContainer}>
            <Box>
              <TextField
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="Search.."
                InputProps={{
                  startAdornment: (
                    <>
                      <img
                        style={{ marginLeft: 10 }}
                        src="/static/svg/Search_light.svg"
                      />
                    </>
                  ),
                  disableUnderline: true,
                  className: classes.searchInputContainer,
                  style: { backgroundColor: "white" },
                }}
                variant="standard"
              />
            </Box>

            <FruProButton
              onClick={filterLikedUsers}
              style={{ borderRadius: 35 }}
              className={classes.searchButtonContainer}
            >
              <img src="/static/svg/Search_like_post.svg" />
            </FruProButton>
          </Box>
          <Box className={classes.likedUsersContainer}>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 400,
                color: "rgba(0, 0, 0, 1)",
              }}
            >
              Liked by
            </p>

            {likedUsersLoading ? (
              <CircularProgress style={{ margin: "0 200px" }} />
            ) : (
              <Box
                style={{ display: "flex", gap: 15, flexDirection: "column" }}
              >
                {!likedUsers?.length && !likedUsersLoading ? (
                  <Typography style={{ textAlign: "center" }}>
                    No users found
                  </Typography>
                ) : (
                  likedUsers.map((user) => {
                    return (
                      <Box className={classes.likedUserItem}>
                        <img
                          style={{
                            width: 50,
                            height: 50,
                            borderRadius: "100%",
                          }}
                          src={
                            user?.users?.[0]?.avatarCustomUrl
                              ? user?.users?.[0]?.avatarCustomUrl
                              : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          }
                        />

                        <Typography style={{ fontSize: "16px" }}>
                          {user?.users?.[0].displayName}
                        </Typography>
                      </Box>
                    );
                  })
                )}
              </Box>
            )}
          </Box>
        </Modal.Body>
      </Modal>
    );
  };
  const onAddViewCount = async (id) => {
    const isAlreadyAdded = socialItem.some((post) => post.contentId === id);
    if (!isAlreadyAdded) {
      setSocialItem([
        ...socialItem,
        {
          contentId: id,
          contentType: "post",
          activityType: "view",
          timestamp: new Date(),
        },
      ]);
      const data = {
        activities: [
          {
            contentId: id,
            contentType: "post",
            activityType: "view",
            timestamp: new Date(),
          },
        ],
      };
      const added = await addViewCountToPost(data);
    }
  };
  const RenderTextPostItems = ({ item }) => {
    const { ref, inView, entry } = useInView({
      /* Optional options */
      threshold: 0,
    });

    if (inView) {
      onAddViewCount(item?._id);
    }
    const avatar = globalFeedData?.users?.find((user) => {
      return user?.userId === item?.postedUserId;
    });
    const endDate = item?.metadata?.endDate;
    const createdDate = item?.createdAt;
    const now = moment();
    const expireDate = moment(createdDate).add(
      expireDateVariables[endDate],
      "h"
    );

    const isExpired = item?.metadata?.endDate && now.isAfter(expireDate);
    return (
      <div
        ref={ref}
        style={{
          margin: "20px 0",
          background: "white",
          borderRadius: 20,
          height: "auto",
        }}
      >
        <div className={classes.feedItems}>
          <Box className={classes.InfoContent} style={{ position: "relative" }}>
            {item?.metadata?.produces?.length > 0 ? (
              <Box
                onClick={() =>
                  !user?.id
                    ? onNavigateLogin()
                    : !isExpired &&
                      onPressMakeOfferToTextPost(item?.metadata?.produces, item)
                }
                className={
                  isExpired
                    ? classes.makeOfferInactiveText
                    : classes.makeOfferText
                }
              >
                Make an offer
              </Box>
            ) : null}
            <Box className={classes.userItem}>
              {user?.id && !user?.companyId ? (
                <div onClick={() => setOpenCompanyNotRegistered(true)}>
                  <Avatar
                    style={{
                      borderRadius: "100px",
                      width: 45,
                      height: 45,
                      cursor: "pointer",
                    }}
                    src={
                      !user?.id || !user?.companyId
                        ? ""
                        : avatar?.avatarCustomUrl
                        ? avatar?.avatarCustomUrl
                        : item?.metadata?.user?.photo?.url
                        ? item?.metadata?.user?.photo?.url
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                  />
                </div>
              ) : (
                <Link
                  href={
                    !user?.id
                      ? "/login"
                      : user?.id && !user?.companyId
                      ? "/company-registration"
                      : `/u/${
                          item?.metadata?.user?.userSlug
                            ? item?.metadata?.user?.userSlug
                            : item?.metadata?.user?.id
                        }`
                  }
                >
                  <Avatar
                    style={{
                      borderRadius: "100px",
                      width: 45,
                      height: 45,
                      cursor: "pointer",
                    }}
                    src={
                      !user?.id || !user?.companyId
                        ? ""
                        : avatar?.avatarCustomUrl
                        ? avatar?.avatarCustomUrl
                        : item?.metadata?.user?.photo?.url
                        ? item?.metadata?.user?.photo?.url
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                  />
                </Link>
              )}
              <Typography
                onClick={() =>
                  !user?.id
                    ? router.push("/login")
                    : user?.id && !user?.companyId
                    ? setOpenCompanyNotRegistered(true)
                    : router.push(
                        `/u/${
                          item?.metadata?.user?.userSlug
                            ? item?.metadata?.user?.userSlug
                            : item?.metadata?.user?.id
                        }`
                      )
                }
                className={classes.companyText}
              >
                {!user?.id ? "Anonymous" : item?.metadata?.user?.fullName}
              </Typography>

              {!user?.id || !user?.companyId ? null : item?.data?.type ===
                "salePost" ? null : (
                <div
                  style={{
                    borderLeft: "2px solid  gray",
                    opacity: 0.4,
                    height: 20,
                    marginLeft: 5,
                  }}
                ></div>
              )}
              <Typography
                onClick={() =>
                  !user?.id
                    ? router.push("/login")
                    : user?.id && !user?.companyId
                    ? setOpenCompanyNotRegistered(true)
                    : router.push(`/c/${item?.metadata?.user?.companySlug}`)
                }
                className={classes.userJobText}
              >
                {!user?.id || !user?.companyId
                  ? ""
                  : item?.data?.type === "salePost"
                  ? null
                  : item?.metadata?.user?.companyName}
              </Typography>
            </Box>
            <Box className={classes.messageActionIconsBox}>
              {isExpired ? (
                <div className={classes.expiredTag}>Expired</div>
              ) : null}
              {item?.data?.type === "salePost" ? null : (
                <img
                  onClick={() => {
                    !user?.id
                      ? onNavigateLogin()
                      : user?.id && !user?.companyId
                      ? setOpenCompanyNotRegistered(true)
                      : dispatch(
                          setNewChatInfo({
                            fullName: item?.metadata?.user.fullName,
                            companyName: item?.metadata?.user?.companyName,
                            id: item?.metadata?.user?.id,
                            companyId: item?.metadata.user.companyId,
                            isBroadcast: true,
                          })
                        );
                    // : setOpenLimitedCompanyModal(true);
                  }}
                  style={{ cursor: "pointer" }}
                  src="/static/svg/Message_light.svg"
                />
              )}
              <Box>
                <DROPDOWN>
                  {user?.id && user?.companyId ? (
                    item?.data?.type === "salePost" ? null : (
                      <Dropdown.Toggle id="dropdown-button-drop-down-centered">
                        <Typography id="basic-button">
                          <img
                            className={classes.dotIcon}
                            src="/static/svg/Frame 1980.svg"
                          />
                        </Typography>
                      </Dropdown.Toggle>
                    )
                  ) : user?.id && !user?.companyId ? (
                    item?.data?.type === "salePost" ? null : (
                      <div
                        onClick={() => setOpenCompanyNotRegistered(true)}
                        id="dropdown-button-drop-down-centered"
                      >
                        <Typography id="basic-button">
                          <img
                            className={classes.dotIcon}
                            src="/static/svg/Frame 1980.svg"
                          />
                        </Typography>
                      </div>
                    )
                  ) : item?.data?.type === "salePost" ? null : (
                    <div
                      onClick={onNavigateLogin}
                      id="dropdown-button-drop-down-centered"
                    >
                      <Typography id="basic-button">
                        <img
                          className={classes.dotIcon}
                          src="/static/svg/Frame 1980.svg"
                        />
                      </Typography>
                    </div>
                  )}
                  {
                    <Dropdown.Menu>
                      {user?.email === item.postedUserId ? (
                        <>
                          <Dropdown.Item
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              gap: 15,
                              alignItems: "center",
                            }}
                            onClick={() => {
                              setOpenDeleteModal(true);
                              setPostId(item?.postId);
                            }}
                          >
                            <img
                              style={{ width: 20, height: 20 }}
                              src="/static/svg/ic_delete.svg"
                            />

                            <Typography style={{ fontSize: 16 }}>
                              Delete post
                            </Typography>
                          </Dropdown.Item>
                          <Dropdown.Item
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              gap: 15,
                              alignItems: "center",
                            }}
                            onClick={() => {
                              var diff =
                                (Date.now() - new Date(item?.createdAt)) / 1000;
                              diff /= 60;
                              const dateDifference = Math.abs(Math.round(diff));

                              if (dateDifference < 3) {
                                item?.data?.videos?.length
                                  ? openEditVideoPostModal(item?.data?.videos)
                                  : item?.data?.images?.length
                                  ? openEditImagePostModal(item?.data?.images)
                                  : item?.data?.files?.length
                                  ? openEditFilePostModal(item?.data?.files)
                                  : setOpenUpdateTextPostPopup(true);

                                editSetPostText(
                                  item?.data?.videos,
                                  item?.data?.images,
                                  item?.data?.files,
                                  item?.data?.text
                                );
                                setPostId(item?.postId);
                                setEditFileVideoImage(true);
                              } else {
                                setOpenNotEditablePopup(true);
                              }
                            }}
                          >
                            <img
                              style={{ width: 20, height: 20 }}
                              src="/static/svg/ic_edit.svg"
                            />

                            <Typography style={{ fontSize: 16 }}>
                              Edit post
                            </Typography>
                          </Dropdown.Item>
                        </>
                      ) : (
                        <Dropdown.Item
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: 15,
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setOpenReportModal(true);
                            setPostId(item?.postId);
                          }}
                        >
                          <img
                            style={{ width: 20, height: 20 }}
                            src="/static/svg/ic_files.svg"
                          />

                          <Typography style={{ fontSize: 16 }}>
                            Report post
                          </Typography>
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  }
                </DROPDOWN>
              </Box>
            </Box>
          </Box>

          <Box>
            <Typography className={classes.timeText}>
              <ReactTimeAgo
                style={{ marginLeft: 55, position: "absolute" }}
                date={item?.createdAt}
                locale="en-US"
              />
            </Typography>
          </Box>

          <Grid className={isExpired ? classes.expiredFeedItems : null} item>
            <Box className={classes.feedContent}>
              {/* <div
                  className={classes.feedText}
                  dangerouslySetInnerHTML={{ __html: item?.data?.richText }}
                /> */}

              {item?.data?.richText ? (
                <div
                  className={clsx({
                    "blurry-text": !user?.companyId || !user?.id,
                  })}
                  style={{ whiteSpace: "pre-line" }}
                >
                  {!user?.companyId || !user?.id ? (
                    <div>{item?.data?.richText}</div>
                  ) : (
             
                    <HtmlMapper html={item?.data?.richText}>
                      {{
                        p: (props) => <p {...props} />,
                        h1: (props) => <h1 {...props} />,
                        div: (props) => <div {...props} />,
                        h2: (props) => <h2 {...props} />,
                        h3: (props) => <h3 {...props} />,
                        h4: (props) => <h4 {...props} />,
                        h5: (props) => <h5 {...props} />,
                        h6: (props) => <h6 {...props} />,
                        a: (props) => <a {...props} />,
                        strong: (props) => <strong {...props} />,
                        em: (props) => <em {...props} />,
                        u: (props) => <u {...props} />,
                        b: (props) => <b {...props} />,
                        i: (props) => <i {...props} />,
                      }}
                    </HtmlMapper>
                  )}
                </div>
              ) : (
                <Typography
                  className={
                    classes.feedText &&
                    clsx({ "blurry-text": !user?.companyId || !user?.id })
                  }
                >
                  {!user?.companyId || !user?.id ? (
                    <div>
                      {postTextShow(
                        item?.data?.text?.replace(/<[^>]*>?/gm, "")
                      )}{" "}
                    </div>
                  ) : (
                    <div className={classes.feedText}>
                      <Linkify>
                        {postTextShow(
                          item?.data?.text?.replace(/<[^>]*>?/gm, "")
                        )}{" "}
                      </Linkify>
                    </div>
                  )}
                </Typography>
              )}
            </Box>
            {item?.data?.images?.length > 0 && (
              <Box>
                <Carousel
                  arrows
                  renderDotsOutside
                  responsive={responsiveProducts}
                  slidesToSlide={1}
                >
                  {Array.isArray(item.data.images) &&
                    item?.data.images?.map((i, index) => {
                      return (
                        <>
                          {" "}
                          <img
                            onClick={() => {
                              biggerImage(i);
                            }}
                            style={{
                              width: 300,
                              borderRadius: 18,
                              objectFit: "cover",
                              height: 163,
                              cursor: "pointer",
                              marginLeft: 10,
                            }}
                            src={`${i?.fileUrl}?size=large`}
                          />
                        </>
                      );
                    })}
                </Carousel>

                <></>
              </Box>
            )}
            {item.data?.videos?.length > 0 && (
              <Box>
                <Carousel
                  arrows
                  renderDotsOutside
                  responsive={responsiveProducts}
                  slidesToSlide={1}
                >
                  {Array.isArray(item.data.videos) &&
                    item?.data.videos?.map((item) => {
                      return (
                        <video
                          onClick={() => {
                            biggerVideo;
                          }}
                          style={{
                            borderRadius: 20,
                            marginLeft: 10,
                            cursor: "pointer",
                          }}
                          width="300"
                          height="163"
                          controls
                          src={item?.fileUrl}
                        ></video>
                      );
                    })}
                </Carousel>
              </Box>
            )}
            {item.data?.files?.length > 0 && (
              <Box>
                <Typography>
                  {Array.isArray(item.data.files) &&
                    item?.data.files?.map((item) => {
                      let type = "pdf";
                      let { name = "pdf" } = item?.attributes;
                      if (name?.includes(".pdf")) type = "pdf";
                      else if (name?.includes(".xlsx")) type = "xls";
                      else if (name?.includes(".xls")) type = "xls";
                      else if (name?.includes(".doc")) type = "doc";
                      else if (name?.includes(".docx")) type = "doc";
                      return (
                        <Box>
                          <Typography className={classes.fileContent}>
                            <img
                              style={{
                                width: 20,
                                height: 20,
                                marginLeft: 10,
                              }}
                              src={`/static/svg/ic_file_${type}.svg`}
                              alt="ic_file_pdf"
                            />
                            {
                              <a
                                style={{
                                  fontSize: 16,
                                  margin: "20px 20px",
                                  textDecoration: "none",
                                }}
                                href={item?.fileUrl}
                                download
                              >
                                {" "}
                                {item.attributes.name}
                              </a>
                            }
                          </Typography>
                        </Box>
                      );
                    })}
                </Typography>
              </Box>
            )}
          </Grid>

          <PostActionItem
            onNavigateLogin={onNavigateLogin}
            keyword={keyword}
            setLikeCount={setLikeCount}
            setLikedUsers={setLikedUsers}
            setPostId={setPostId}
            setLikedUsersLoading={setLikedUsersLoading}
            openLikesModal={openLikesModal}
            setOpenLikesModal={setOpenLikesModal}
            // openLimitedCompanyModal={openLimitedCompanyModal}
            // setOpenLimitedCompanyModal={setOpenLimitedCompanyModal}
            isLimitedCompany={isLimitedCompany}
            user={item?.metadata?.user}
            globalFeedData={globalFeedData}
            setGlobalFeedData={setGlobalFeedData}
            post={item}
            postId={item._id}
          />
        </div>
      </div>
    );
  };

  const RenderOfferDemandItems = ({ item }) => {
    const { ref, inView, entry } = useInView({
      /* Optional options */
      threshold: 0,
    });

    if (inView) {
      onAddViewCount(item?._id);
    }

    const avatar = globalFeedData?.users?.find((user) => {
      return user?.userId === item?.postedUserId;
    });
    const endDate = item?.metadata?.endDate;
    const createdDate = item?.createdAt;
    const now = moment();
    const expireDate = moment(createdDate).add(
      expireDateVariables[endDate],
      "h"
    );

    const isExpired = item?.metadata?.endDate && now.isAfter(expireDate);

    return (
      <div
        ref={ref}
        style={{
          margin: "20px 0",
          background: "white!important",
          borderRadius: 20,
          height: "auto",
        }}
      >
        {" "}
        <div className={classes.feedItems}>
          <Box className={classes.InfoContent}>
            <Box className={classes.userItem}>
              {user?.id && !user?.companyId ? (
                <div onClick={() => setOpenCompanyNotRegistered(true)}>
                  <Avatar
                    style={{
                      borderRadius: "100px",
                      width: 45,
                      height: 45,
                      cursor: "pointer",
                    }}
                    src={
                      !user?.id || !user?.companyId
                        ? ""
                        : avatar?.avatarCustomUrl
                        ? avatar?.avatarCustomUrl
                        : item?.metadata?.user?.photo?.url
                        ? item?.metadata?.user?.photo?.url
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                  />
                </div>
              ) : (
                <Link
                  href={
                    !user?.id
                      ? "/login"
                      : user?.id && !user?.companyId
                      ? "/company-registration"
                      : `/u/${
                          item?.metadata?.user?.userSlug
                            ? item?.metadata?.user?.userSlug
                            : item?.metadata?.user?.id
                        }`
                  }
                >
                  <Avatar
                    style={{
                      borderRadius: "100px",
                      width: 45,
                      height: 45,
                      cursor: "pointer",
                    }}
                    src={
                      !user?.id
                        ? ""
                        : avatar?.avatarCustomUrl
                        ? avatar?.avatarCustomUrl
                        : item?.metadata?.user?.photo?.url
                        ? item?.metadata?.user?.photo?.url
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                  />
                </Link>
              )}

              <Typography
                onClick={() =>
                  !user?.id
                    ? router.push("/login")
                    : user?.id && !user?.companyId
                    ? setOpenCompanyNotRegistered(true)
                    : router.push(
                        `/u/${
                          item?.metadata?.user?.userSlug
                            ? item?.metadata?.user?.userSlug
                            : item?.metadata?.user?.id
                        }`
                      )
                }
                className={classes.companyText}
              >
                {!user?.id ? "Anonymous" : item?.metadata?.user?.fullName}
              </Typography>

              {!user?.id || !user?.companyId ? null : item?.data?.type ===
                "salePost" ? null : (
                <div
                  style={{
                    borderLeft: "2px solid  gray ",
                    opacity: 0.4,
                    height: 20,
                    marginLeft: 2,
                  }}
                ></div>
              )}
              <Typography
                onClick={() =>
                  !user?.id
                    ? router.push("/login")
                    : user?.id && !user?.companyId
                    ? setOpenCompanyNotRegistered(true)
                    : router.push(`/c/${item?.metadata?.user?.companySlug}`)
                }
                className={classes.userJobText}
              >
                {!user?.id || !user?.companyId
                  ? ""
                  : item?.data?.type === "salePost"
                  ? null
                  : item?.metadata?.user?.companyName}
              </Typography>
            </Box>
            <Box className={classes.messageActionIconsBox}>
              {isExpired ? (
                <div className={classes.expiredTag}>Expired</div>
              ) : null}
              {item.data.type === "offer" ? (
                <div
                  style={{
                    float: "right",
                    fontSize: 12,

                    borderRadius: 12,
                    backgroundColor: "#B1F59D",

                    width: 100,
                    height: 25,

                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  Selling
                  <img style={{ marginLeft: 5 }} />
                </div>
              ) : (
                <div
                  style={{
                    float: "right",
                    fontSize: 12,
                    borderRadius: 12,
                    color: "white",
                    backgroundColor: isExpired ? "#737373" : "#E77228",
                    width: 100,
                    height: 25,
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  Demand
                  <img style={{ marginLeft: 5 }} />
                </div>
              )}
              {item?.data?.type === "salePost" ? null : (
                <img
                  onClick={() => {
                    !user?.id
                      ? onNavigateLogin()
                      : user?.id && !user?.companyId
                      ? setOpenCompanyNotRegistered(true)
                      : dispatch(
                          setNewChatInfo({
                            fullName: item?.metadata?.user.fullName,
                            companyName: item?.metadata?.user?.companyName,
                            id: item?.metadata?.user?.id,
                            companyId: item?.metadata.user.companyId,
                            isBroadcast: true,
                          })
                        );
                    // : setOpenLimitedCompanyModal(true);
                  }}
                  style={{ cursor: "pointer" }}
                  src="/static/svg/Message_light.svg"
                />
              )}

              <Box>
                <DROPDOWN>
                  {user?.id && user?.companyId ? (
                    <Dropdown.Toggle id="dropdown-button-drop-down-centered">
                      <Typography id="basic-button">
                        <img
                          className={classes.dotIcon}
                          src="/static/svg/Frame 1980.svg"
                        />
                      </Typography>
                    </Dropdown.Toggle>
                  ) : user?.id && !user?.companyId ? (
                    <div
                      onClick={() => setOpenCompanyNotRegistered(true)}
                      id="dropdown-button-drop-down-centered"
                    >
                      <Typography id="basic-button">
                        <img
                          className={classes.dotIcon}
                          src="/static/svg/Frame 1980.svg"
                        />
                      </Typography>
                    </div>
                  ) : (
                    <div
                      onClick={onNavigateLogin}
                      id="dropdown-button-drop-down-centered"
                    >
                      <Typography id="basic-button">
                        <img
                          className={classes.dotIcon}
                          src="/static/svg/Frame 1980.svg"
                        />
                      </Typography>
                    </div>
                  )}
                  {
                    <Dropdown.Menu>
                      {user?.email === item.postedUserId ? (
                        <>
                          <Dropdown.Item
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              gap: 15,
                              alignItems: "center",
                            }}
                            onClick={() => {
                              setOpenDeleteModal(true);
                              setPostId(item?.postId);
                            }}
                          >
                            <img
                              style={{ width: 20, height: 20 }}
                              src="/static/svg/ic_delete.svg"
                            />

                            <Typography style={{ fontSize: 16 }}>
                              Delete post
                            </Typography>
                          </Dropdown.Item>
                          <Dropdown.Item
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              gap: 15,
                              alignItems: "center",
                            }}
                            onClick={() => {
                              var diff =
                                (Date.now() - new Date(item?.createdAt)) / 1000;
                              diff /= 60;
                              const dateDifference = Math.abs(Math.round(diff));

                              if (dateDifference < 3) {
                                item?.data?.type === "offer"
                                  ? setOpenPreviewOfferPopup(true)
                                  : setOpenPreviewDemandPopup(true);

                                setSelectedProduce([
                                  ...item?.metadata?.selectedProduces,
                                ]);

                                setPostId(item?.postId);
                                setEditedOfferDemandPostText(item?.data?.text);
                                setIsPressedOfferPostMakeOffer(false);
                                setIsUpdate(true);
                                setIsPressedOfferPostMakeOffer(false);
                                item?.data?.text &&
                                  editPreviewOfferEditorState(
                                    EditorState.createWithContent(
                                      ContentState.createFromBlockArray(
                                        convertFromHTML(item?.data?.text)
                                      )
                                    )
                                  );
                              } else {
                                setOpenNotEditablePopup(true);
                              }
                            }}
                          >
                            <img
                              style={{ width: 20, height: 20 }}
                              src="/static/svg/ic_edit.svg"
                            />

                            <Typography style={{ fontSize: 16 }}>
                              Edit post
                            </Typography>
                          </Dropdown.Item>
                        </>
                      ) : (
                        <Dropdown.Item
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: 15,
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setOpenReportModal(true);
                            setPostId(item?.postId);
                          }}
                        >
                          <img
                            style={{ width: 20, height: 20 }}
                            src="/static/svg/ic_files.svg"
                          />

                          <Typography style={{ fontSize: 16 }}>
                            Report post
                          </Typography>
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  }
                </DROPDOWN>
              </Box>
            </Box>
          </Box>
          <Box className="d-flex  position-relative">
            <Box className={classes.typeContent}>
              <Typography className={classes.timeText}>
                <ReactTimeAgo
                  style={{ marginLeft: 55, position: "absolute" }}
                  date={item?.createdAt}
                  locale="en-US"
                />
              </Typography>
            </Box>
            {user?.companyId !== item?.metadata?.user?.companyId &&
            item.data.type === "offer" ? (
              <Box
                onClick={() =>
                  !user?.id
                    ? onNavigateLogin()
                    : user?.id && !user?.companyId
                    ? setOpenCompanyNotRegistered(true)
                    : !isExpired &&
                      onPressMakeOfferToOfferPost(
                        item?.metadata?.selectedProduces
                      )
                }
                className={
                  isExpired ? classes.makeOfferInactive : classes.makeOffer
                }
              >
                Make an offer
              </Box>
            ) : null}
          </Box>

          <>
            <Grid
              style={{ marginTop: item.data.type === "offer" ? 50 : 0 }}
              className={isExpired ? classes.expiredFeedItems : null}
              item
            >
              {item.data.type === "offer" ? (
                <>
                  <Box className={classes.offerDemandFeedContent}>
                    {item.data.text && !item?.data?.richText && (
                      <Typography
                        style={{ marginLeft: 10 }}
                        className={
                          classes.feedText &&
                          clsx({ "blurry-text": !user?.companyId || !user?.id })
                        }
                      >
                        {postTextShow(item?.data?.text)}
                      </Typography>
                    )}
                    {item?.data?.richText && item.data.text && (
                      <div
                        className={clsx({
                          "blurry-text": !user?.companyId || !user?.id,
                        })}
                        style={{ whiteSpace: "pre-line" }}
                      >
                        {!user?.companyId || !user?.id ? (
                          <div>{item?.data?.richText}</div>
                        ) : (
                          <HtmlMapper html={item?.data?.richText}>
                            {{
                              p: (props) => <p {...props} />,
                              h1: (props) => <h1 {...props} />,
                              h2: (props) => <h2 {...props} />,
                              h3: (props) => <h3 {...props} />,
                              h4: (props) => <h4 {...props} />,
                              div: (props) => <div {...props} />,
                              h5: (props) => <h5 {...props} />,
                              h6: (props) => <h6 {...props} />,
                              a: (props) => <a {...props} />,
                              strong: (props) => <strong {...props} />,
                              em: (props) => <em {...props} />,
                              u: (props) => <u {...props} />,
                              b: (props) => <b {...props} />,
                              i: (props) => <i {...props} />,
                            }}
                          </HtmlMapper>
                        )}
                      </div>
                    )}
                  </Box>
                  <Box>
                    <Carousel
                      arrows
                      renderDotsOutside
                      responsive={responsiveProducts}
                      slidesToSlide={1}
                    >
                      {item?.metadata?.selectedProduces?.map((produce) => {
                        return (
                          <Box className={classes.feedContent}>
                            <OfferDemandPostItem
                              onNavigateLogin={onNavigateLogin}
                              isLimitedCompany={isLimitedCompany}
                              // setOpenLimitedCompanyModal={
                              // setOpenLimitedCompanyModal
                              // }
                              produce={produce}
                            />
                          </Box>
                        );
                      })}
                    </Carousel>
                  </Box>
                </>
              ) : (
                <Box
                  arrows
                  renderDotsOutside
                  responsive={responsiveProducts}
                  slidesToSlide={1}
                >
                  {item.data.text && (
                    <Typography
                      className={
                        classes.feedText &&
                        clsx({ "blurry-text": !user?.companyId || !user?.id })
                      }
                      style={{ margin: "25px 20px" }}
                    >
                      {postTextShow(item?.data?.text)}
                    </Typography>
                  )}
                  {item?.metadata?.selectedProduces?.map((produce, index) => {
                    return (
                      <Box className={classes.feedContent}>
                        <DemandPostItem
                          setIsPressedOfferPostMakeOffer={
                            setIsPressedOfferPostMakeOffer
                          }
                          onNavigateLogin={onNavigateLogin}
                          setRenderType={setRenderType}
                          demandCompany={item?.metadata?.user?.companyId}
                          setMakeOfferBuyerCompanyId={
                            setMakeOfferBuyerCompanyId
                          }
                          isExpired={isExpired}
                          setOpenOfferDemandPostDialog={
                            setOpenOfferDemandPostDialog
                          }
                          length={item?.metadata?.selectedProduces?.length}
                          index={index}
                          demand={produce}
                        />
                      </Box>
                    );
                  })}

                  {/*  
              {item.metadata.selectedProduces.length > 3 && (
                   <>
                      <hr/>
                <Box className={classes.MoreDemandContainer}>
                  <Typography className={classes.MoreDemandText}>
                    Load more demand...
                  </Typography>
                </Box>
                <hr/>
                   </>
              )} */}
                </Box>
              )}
            </Grid>
          </>
          <PostActionItem
            onNavigateLogin={onNavigateLogin}
            setLikeCount={setLikeCount}
            keyword={keyword}
            setLikedUsersLoading={setLikedUsersLoading}
            setPostId={setPostId}
            setLikedUsers={setLikedUsers}
            openLikesModal={openLikesModal}
            setOpenLikesModal={setOpenLikesModal}
            // openLimitedCompanyModal={openLimitedCompanyModal}
            // setOpenLimitedCompanyModal={setOpenLimitedCompanyModal}
            isLimitedCompany={isLimitedCompany}
            user={item?.metadata?.user}
            globalFeedData={globalFeedData}
            setGlobalFeedData={setGlobalFeedData}
            post={item}
            postId={item._id}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <InfiniteScroll
        dataLength={globalFeedData?.posts?.length || 1}
        next={nextPage}
        loader={<></>}
        hasMore={true}
      >
        {globalFeedData?.posts?.map((item, index) => {
          return item?.isDeleted !== true &&
            item?.metadata?.selectedProduces ? (
            <RenderOfferDemandItems item={item} />
          ) : (
            item.isDeleted !== true && <RenderTextPostItems item={item} />
          );
        })}
      </InfiniteScroll>

      {
        loading ? <LoadingIndicator loading /> : null
        // <>
        //   {!isLimitedCompany ? (
        //     !globalFeedData?.paging?.previous ? (
        //       <Box className={classes.pagination}>
        //         <Typography
        //           onClick={globalFeedData?.paging?.next && nextPage}
        //           className={classes.paginationText}
        //           style={{ fontSize: 22, color: "#69CDAE", cursor: "pointer" }}
        //         >
        //           Next page
        //         </Typography>
        //       </Box>
        //     ) : !globalFeedData?.paging?.next ? (
        //       <Box className={classes.pagination}>
        //         <Typography
        //           className={classes.paginationText}
        //           style={{ fontSize: 22, color: "#69CDAE", cursor: "pointer" }}
        //           onClick={globalFeedData?.paging?.previous && prevPage}
        //         >
        //           Previous page
        //         </Typography>
        //       </Box>
        //     ) : (
        //       <Box className={classes.pagination}>
        //         <Typography
        //           className={classes.paginationText}
        //           style={{ fontSize: 22, color: "#69CDAE", cursor: "pointer" }}
        //           onClick={globalFeedData?.paging?.previous && prevPage}
        //         >
        //           Previous page
        //         </Typography>
        //         <div
        //           style={{
        //             borderLeft: "2px solid  gray",
        //             opacity: 0.4,
        //             height: 20,
        //           }}
        //         ></div>
        //         <Typography
        //           onClick={globalFeedData?.paging?.next && nextPage}
        //           className={classes.paginationText}
        //           style={{ fontSize: 22, color: "#69CDAE", cursor: "pointer" }}
        //         >
        //           Next page
        //         </Typography>
        //       </Box>
        //     )
        //   ) : null}
        // </>
      }
      <ConsigmentsOfferPopUp
        setOpenPreviewOfferPopup={setOpenConsigmentsOfferPopUp}
        openPreviewOfferPopup={openConsigmentsOfferPopUp}
        text={pressedOfferText}
        companyId={pressedCompanyId}
      />
      {renderBiggerImageModal()}
      {renderDeleteModal()}
      {renderReportModal()}
      {renderBiggerVideoModal()}
      {/* {renderLimitedCompanyModal()} */}
      {renderLikesModal()}
      {renderNotEditableModal()}
      <CompanyNotRegisteredModal
        setOpenModal={setOpenCompanyNotRegistered}
        openModal={openCompanyNotRegistered}
      />
    </>
  );
};

export default FeedItem;
