import React from "react";
import Modal from "react-bootstrap/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { useRouter } from "next/router";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  customLikeModal: {
    borderRadius: "30px",
    border: "1px solid #E77228",
  },
  limitedModal: {
    "@media (max-width: 1000px)": {
      display: "none !important",
    },
  },
  cancel: {
    height: 40,
    cursor: "pointer",
    border: "1px solid #E77228",
    color: "#E77228",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
  },
  continue: {
    cursor: "pointer",
    height: 40,
    background: "#E77228",
    color: "white",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20,
  },
  infoText: {
    color: "#7C7C7C",
    fontWeight: 600,
  },
});

const CompanyNotVerifiedModal = ({ openModal, setOpenModal }) => {
  const classes = useStyles();

  const router = useRouter();
  return (
    <Modal
      contentClassName={classes.customLikeModal}
      className={classes.limitedModal}
      show={openModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <span className={classes.infoText}>
          Your company is waiting for approval on FruPro, you can not share any
          posts right now. Thank you for your patience.
        </span>

        <div
          style={{ width: "100%" }}
          className="d-flex flex-row gap-2 justify-content-center mt-4"
        >
          <div className={classes.cancel} onClick={() => setOpenModal(false)}>
            Go back to timeline
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CompanyNotVerifiedModal;
