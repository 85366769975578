import { Button, withStyles, makeStyles, FormLabel } from "@material-ui/core";
import React from "react";
import { Modal } from "react-bootstrap";
import { useRouter } from "next/router";

const useStyles = makeStyles({
  sliderBox: {
    marginLeft: 35,
  },
  shareButton: {
    width: "100%",
    backgroundColor: "#e77228",
    border: "none",
    color: "white",
    borderRadius: 20,
  },
  closeButton: {
    padding: 10,
    textTransform: "capitalize",
    color: "#E77228",
    border: "1px solid #e77228",
    width: "100%",
    maxHeight: 45,
    borderRadius: 20,
    "&:hover": {
      border: "1px solid #e77228",
      width: "100%",
      color: "#E77228",
      borderRadius: 20,
    },
  },
  openButton: {
    textTransform: "capitalize",
    maxHeight: 45,
    padding: 10,
    background: "#E77228",
    color: "white",
    border: "1px solid #e77228",
    width: "100%",
    borderRadius: 20,
    "&:hover": {
      backgroundColor: "#e77228",
      border: "1px solid #e77228",
      width: "100%",
      color: "white",
      borderRadius: 20,
    },
  },
  customLikeModal: {
    borderRadius: "30px",
    border: "1px solid #E77228",
  },
  buyOnFruPro: {
    fontWeight: 500,
    fontSize: 18,
  },
});

const OpenLoggedOrNotVerifyModal = ({ openModal, setOpenModal, user }) => {
  const classes = useStyles();
  const router = useRouter();

  const onNavigateProfile = () => {
    // localStorage.setItem("buyOn", true);
    router.push("/my-company-profile");
  };
  const onNavigateLogin = () => {
    router.push("/login");
  };

  return (
    <Modal
      contentClassName={classes.customLikeModal}
      show={openModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <span className={classes.buyOnFruPro}> Buy on FruPro</span>
      </Modal.Header>
      <Modal.Body>
        <span>
          {user?.id
            ? "All buyers on FruPro get paid within 2Days! Suppliers are required to verify a receiving Bank account.Your company must first share required verification details within the Company Profile and select “Verify as a Buyer”."
            : "All buyers on FruPro get paid within 2Days! Suppliers are required to verify a receiving Bank account."}
        </span>
      </Modal.Body>
      <div className="container p-3">
        <div className="row d-flex">
          <div className="col-6 ">
            <Button
              className={classes.closeButton}
              onClick={() => setOpenModal(false)}
            >
              Close
            </Button>
          </div>
          <div className="col-6 ">
            <Button
              className={classes.openButton}
              onClick={user?.id ? onNavigateProfile : onNavigateLogin}
            >
              {!user?.id ? "Signup / Login" : "Continue company verification"}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OpenLoggedOrNotVerifyModal;
