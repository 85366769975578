export const marks = [
 {
   value: 1,
   label: "1h",
 },
 {
   value: 2,
   label: "2h",
 },
 {
   value: 3,
   label: "3h",
 },
 {
   value: 4,
   label: "6h",
 },
 {
   value: 5,
   label: "12h",
 },
 {
   value: 6,
   label: "24h",
 },
 {
   value: 7,
   label: "2d",
 },
 {
   value: 8,
   label: "3d",
 },
 {
   value: 9,
   label: "4d",
 },
 {
   value: 10,
   label: "5d",
 },
 {
   value: 11,
   label: "6d",
 },
 {
   value: 12,
   label: "1weeks",
 },
 {
   value: 13,
   label: "2weeks",
 },
 {
   value: 14,
   label: "3weeks",
 },
 {
   value: 15,
   label: "1month",
 },
 {
   value: 16,
   label: "This post will not expire",
 },
];

export const marksSlider = [
 {
   value: 1,
 
 },
 {
   value: 2,
  
 },
 {
   value: 3,
  
 },
 {
   value: 4,
   
 },
 {
   value: 5,
  
 },
 {
   value: 6,
   
 },
 {
   value: 7,
 
 },
 {
   value: 8,
  
 },
 {
   value: 9,
   
 },
 {
   value: 10,
 
 },
 {
   value: 11,
   
 },
 {
   value: 12,
  
 },
 {
   value: 13,
  
 },
 {
   value: 14,
 
 },
 {
   value: 15,
   
 },
 {
   value: 16,
  
 },
];



export const expireDateVariables = {

  '1h': 1,

  '2h': 2,

  '3h': 3,

  '6h': 6,

  '12h': 12,

  '24h': 24,

  '2d': 48,

  '3d': 72,

  '4d': 96,

  '5d': 120,

  '6d': 144,

  '1weeks': 168,

  '2weeks': 336,

  '3weeks': 504,

  '1month': 672,

}

 