import {
  Button,
  withStyles,
  makeStyles,
  FormLabel,
  Box,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { uploadPremadeInvoice } from "@services/invoice";
import LoadingIndicator from "@components/LoadingIndicator";
import { useDropzone } from "react-dropzone";

const useStyles = makeStyles((theme) => ({
  sliderBox: {
    marginLeft: 35,
  },
  shareButton: {
    textTransform: "capitalize",
    width: "100%",
    backgroundColor: "#e77228",
    border: "none",
    color: "white",
    borderRadius: 20,
  },
  closeButton: {
    textTransform: "capitalize",
    backgroundColor: "white",
    border: "1px solid #e77228",
    width: "100%",
    color: "#e77228",
    borderRadius: 20,
  },
  customLikeModal: {
    borderRadius: "30px",
    border: "1px solid #E77228",
  },
  modalHeader: {
    color: "#E77228",
    fontSize: 24,
    fontWeight: 600,
  },
  yesNoBox: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20,
    marginLeft: 10,
    marginBottom: 20,
    background: "#F8F8F8",
    borderRadius: 20,
    width: 440,
    height: 50,
  },
  activeBox: {
    background: "#e77228",
    borderRadius: 20,
    color: "white",
    width: "220px",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
  },
  inactiveBox: {
    background: "#F8F8F8",
    borderRadius: 20,
    color: "#B8B8B8",
    cursor: "pointer",
    width: "220px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  customControlledField: {
    background: "transparent",
    height: "100%",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 35,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      marginLeft: 25,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",

      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `${theme.palette.initial.main} !important`,
    },
    "&::before": {
      borderColor: theme.palette.default.main,
    },
    "& .MuiAutocomplete-tag": {
      background: "#69CDAE",
      color: theme.palette.initial.main,
      "& .MuiChip-deleteIcon": {
        color: theme.palette.initial.main,
      },
    },
  },
  AddMoreImageContainer: {
    width: 135,
    height: 114,
    borderRadius: 10,
    border: "1px dashed #E77228",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  searchIcon: {
    top: 12,
    left: 20,
  },
  Input: {
    borderRadius: 20,
    width: 350,
    height: 45,
    padding: 15,
  },
  addNew: {
    color: "#797979",
    textDecoration: "underline",
    cursor: "pointer",
    paddingLeft: 15,
    marginTop: 10,
  },
}));
const SelectInvoiceModal = ({
  isAddNew,
  setOpenSelectInvoiceModal,
  openSelectInvoiceModal,
  setOpenSelectBuyerModal,
  setInvoiceRenderType,
  invoiceRenderType,
}) => {
  const classes = useStyles();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Yüklenen dosyalarla yapılacak işlemleri burada gerçekleştirin.
      const newFiles = acceptedFiles.concat(Array.from(selectedFiles));
      setSelectedFiles(newFiles);
    },
    [selectedFiles]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onSelectActive = (type) => {
    setInvoiceRenderType(type);
  };

  const onPressCancel = () => {
    setInvoiceRenderType("yes");
    setSelectedFiles([]);
    setOpenSelectInvoiceModal(false);
  };

  const onPressContinue = async () => {
    if (invoiceRenderType === "yes") {
      setIsLoading(true);
      let res = await uploadPremadeInvoice(selectedFiles[0]);
      if (res) {
        setSelectedFiles([]);
        setOpenSelectInvoiceModal(false);
        setOpenSelectBuyerModal(true);
        setInvoiceRenderType("yes");
      }
      setIsLoading(false);
    } else {
      setSelectedFiles([]);
      setOpenSelectInvoiceModal(false);
      setOpenSelectBuyerModal(true);
      setInvoiceRenderType("yes");
    }
  };

  const deleteFile = (fileName) => {
    const newFiles = Array.from(selectedFiles)?.filter((item) =>
      item?.fileUrl ? item.fileUrl !== fileName : item.name !== fileName
    );
    setSelectedFiles([...newFiles]);
  };

  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: 30, border: "1px solid #E77228" },
      }}
      open={openSelectInvoiceModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <DialogContent>
        <span className={classes.modalHeader}>{"Do you have an invoice?"}</span>

        {!isAddNew ? (
          <div className={classes.yesNoBox}>
            <div
              onClick={() => onSelectActive("yes")}
              className={
                invoiceRenderType === "yes"
                  ? classes.activeBox
                  : classes.inactiveBox
              }
            >
              YES
            </div>
            <div
              onClick={() => onSelectActive("no")}
              className={
                invoiceRenderType === "no"
                  ? classes.activeBox
                  : classes.inactiveBox
              }
            >
              NO
            </div>
          </div>
        ) : null}
        {invoiceRenderType === "yes" ? (
          <>
            <div className="row">
              <>
                {Array.from(selectedFiles).map((item) => {
                  let type = "pdf";
                  let { name = "pdf" } = item?.attributes || item;
                  if (name?.includes(".pdf")) type = "pdf";
                  else if (name?.includes(".xlsx")) type = "xls";
                  else if (name?.includes(".xls")) type = "xls";
                  else if (name?.includes(".doc")) type = "doc";
                  else if (name?.includes(".docx")) type = "doc";
                  return (
                    <div
                      style={{
                        border: "0.2px solid gray",
                        borderRadius: 20,
                        display: "flex",
                        padding: "20px 10px 10px 10px",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: 155,
                        width: 155,
                        maxHeight: 155,
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        marginBottom: 10,
                      }}
                    >
                      <p
                        style={{
                          maxWidth: 145,
                          whiteSpace: "pre-line",
                          fontSize: 13,
                          wordWrap: "break-word",
                          wordBreak: "break-word",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          boxOrient: "vertical",
                          lineClamp: 2,
                        }}
                      >
                        {item?.fileUrl ? item?.attributes?.name : item?.name}
                        ss
                      </p>
                      <img
                        style={{
                          width: 20,
                          height: 20,
                        }}
                        src={`/static/svg/ic_file_${type}.svg`}
                        alt="ic_file_pdf"
                      />
                      <div
                        onClick={() =>
                          deleteFile(item?.fileUrl ? item?.fileUrl : item.name)
                        }
                        style={{ position: "absolute", top: 5, right: 5 }}
                      >
                        <img
                          style={{
                            width: 30,
                            height: 30,
                            cursor: "pointer",
                          }}
                          src="/static/svg/Close_round_delete.svg"
                        />
                      </div>
                    </div>
                  );
                })}

                {selectedFiles?.length < 1 ? (
                  <Box>
                    <label>
                      <Box
                        {...getRootProps()}
                        className={classes.AddMoreImageContainer}
                      >
                        <img
                          src="/static/svg/Img_load-box_light.svg"
                          style={{
                            margin: "0 auto",
                            cursor: "pointer",
                            width: 36,
                            height: 36,
                          }}
                          className={classes.AddMoreImage}
                        />
                        <p style={{ opacity: 0.3, cursor: "pointer" }}>
                          Upload a file
                        </p>
                      </Box>
                      <input
                        {...getInputProps()}
                        type="file"
                        style={{ display: "none" }}
                        accept=".pdf,.doc,.docx,.xlsx"
                        name="file"
                        multiple
                        onChange={(e) => {
                          // Array.from(e.target?.files).forEach((item) => {
                          // setSelectedImages([...selectedImages, item]);
                          // });

                          const newFiles = Array.from(e.target.files).concat(
                            Array.from(selectedFiles)
                          );

                          setSelectedFiles([...newFiles]);
                        }}
                      />
                      <p style={{ opacity: 0.3, cursor: "pointer" }}>
                        {isDragActive
                          ? "Drop the files here"
                          : "Drag 'n' drop some files here, or click to select files"}
                      </p>
                    </label>
                  </Box>
                ) : null}
              </>
            </div>
          </>
        ) : null}
      </DialogContent>
      {isLoading&&<LoadingIndicator loadingText={"Invoice uploading, please wait..."} loading={isLoading} />}
      <div className="container pb-3">
        <div className="row">
          <div className="col-6">
            <Button onClick={onPressCancel} className={classes.closeButton}>
              Cancel
            </Button>
          </div>
          <div className="col-6">
            <Button
              disabled={
                (invoiceRenderType === "yes" && selectedFiles?.length < 1) ||
                isLoading
              }
              onClick={onPressContinue}
              className={classes.shareButton}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SelectInvoiceModal;
