import { SEARCH_PRODUCES_PARAMS } from "@constants";
import { handleAPIErrors } from "@utils/handleAPIErrors";
import {
  formatEmptyData,
  queryObjSerialize,
  sanitizeEmptyArray,
} from "@utils/index";
import { axiosInstance } from "services";
import toastr from "toastr";

export const getMarketProduces = async (queryParams) => {
  try {
    let result;
    const formatedQueryParams = formatEmptyData(queryParams);

    if (
      Object.keys(queryParams).some((item) =>
        SEARCH_PRODUCES_PARAMS.includes(item)
      )
    ) {
      result = await axiosInstance.post(
        `/search-produces?companyStatus=1&genericProductFilter=1&includes=company`,
        sanitizeEmptyArray(formatedQueryParams)
      );
    } else {
      let queryStrings = queryObjSerialize(queryParams);
      result = await axiosInstance.post(
        `/search-produces?${queryStrings}&companyStatus=1&genericProductFilter=1&includes=company`
      );
    }
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getListProduces = async (queryParams) => {
  try {
    let queryStrings = queryObjSerialize(queryParams);
    const result = await axiosInstance.get(
      `/produces?${queryStrings}&companyStatus=1&genericProductFilter=1`
    );

    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};

export const getCrawlerCountryStates = async (countryGeoNameId) => {
  try {
    const result = await axiosInstance.get(
      `/countries/${countryGeoNameId}/states?includes=cities`
    );
    if (result?.status === 200) return result?.data?.items;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getDetailProduce = async (produceId) => {
  try {
    const result = await axiosInstance.get(`/produces/${produceId}`, {
      params: { includes: "company" },
    });
    if (result?.status === 200) return result?.data;
  } catch (error) {
    handleAPIErrors(error);
    // console.error(error);
    // toastr.error(error.message || error);
    // throw error;
  }
};

export const getDailyProduces = async (queryParams) => {
  try {
    let queryString = queryObjSerialize(queryParams);
    const result = await axiosInstance.get(`/daily-produces?${queryString}`);
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getAutoCompleteProduces = async (queryParams) => {
  try {
    const result = await axiosInstance.post(
      "autocomplete-produces",
      sanitizeEmptyArray(queryParams)
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
    throw error;
  }
};

export const getTopProducesByCompany = async (
  companyId,
  top,
  status
) => {
  try {
 
    const result = await axiosInstance.get(
      `/companies/${companyId}/top-produces?top=${top}?status=${status}`
    );
    if (result?.status === 200) return result?.data;
  } catch (error) {
    console.error(error);
    toastr.error(error.message || error);
  }
};
