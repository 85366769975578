import { Button, withStyles, makeStyles, FormLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Dialog, DialogContent } from "@material-ui/core";
import { FruproControllerComboBox } from "@components";
import { getVerifiedBuyers } from "@services/info";
import { useSelector, useDispatch, Provider } from "react-redux";
import FruProControllerTextField from "@components/ControllerTextField";
import LoadingIndicator from "@components/LoadingIndicator";

const useStyles = makeStyles((theme) => ({
  sliderBox: {
    marginLeft: 35,
  },
  shareButton: {
    textTransform: "capitalize",
    width: "100%",
    backgroundColor: "#e77228",
    border: "none",
    color: "white",
    borderRadius: 20,
  },
  closeButton: {
    textTransform: "capitalize",
    backgroundColor: "white",
    border: "1px solid #e77228",
    width: "100%",
    color: "#e77228",
    borderRadius: 20,
  },
  customLikeModal: {
    borderRadius: "30px",
    border: "1px solid #E77228",
  },
  modalHeader: {
    color: "#E77228",
    fontSize: 24,
    fontWeight: 600,
  },
  yesNoBox: {
    display: "flex",
    flexDirection: "row",
    marginTop: 20,
    marginLeft: 10,
    marginBottom: 20,
    background: "#F8F8F8",
    borderRadius: 20,
    width: 440,
    height: 50,
  },
  activeBox: {
    background: "#e77228",
    borderRadius: 20,
    color: "white",
    width: "220px",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
  },
  inactiveBox: {
    background: "#F8F8F8",
    borderRadius: 20,
    color: "#B8B8B8",
    cursor: "pointer",
    width: "220px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  customControlledField: {
    background: "transparent",
    height: "100%",
    fontSize: 16,
    width: "100%",

    "& .MuiInputBase-root": {
      background: "#f7f7f7",
      minHeight: 50,
      fontSize: "1rem",
      borderRadius: 35,
      overflow: "hidden",
      padding: "0 15px",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
    "& .MuiInputBase-input": {
      paddingTop: 9,
      marginLeft: 25,
      paddingBottom: 9,
    },
    "& .MuiInputLabel-root": {
      transform: "unset",

      fontSize: 12,
      color: "black",
    },
    "& input:-webkit-autofill": {
      backgroundColor: `${theme.palette.initial.main} !important`,
    },
    "&::before": {
      borderColor: theme.palette.default.main,
    },
    "& .MuiAutocomplete-tag": {
      background: "#69CDAE",
      color: theme.palette.initial.main,
      "& .MuiChip-deleteIcon": {
        color: theme.palette.initial.main,
      },
    },
  },
  searchIcon: {
    top: 12,
    left: 20,
  },
  Input: {
    borderRadius: 20,
    width: 350,
    height: 45,
    padding: 15,
  },
  addNew: {
    color: "#797979",
    textDecoration: "underline",
    cursor: "pointer",
    paddingLeft: 15,
    marginTop: 10,
  },
}));
const SelectBuyerModal = ({
  watchCompanyName,
  handleSubmit,
  isAddNew,
  setIsAddNew,
  errors,
  watchEmail,
  watchSelectedBuyer,
  renderType,
  setRenderType,
  control,
  setValue,
  setSelectedProduce,
  setOpenPreviewOfferPopup,
  openSelectBuyerModal,
  setOpenOfferDemandPostDialog,
  setOpenSelectBuyerModal,
}) => {
  const classes = useStyles();

  const user = useSelector((state) => state?.auth?.user);

  const [loading, setLoading] = useState(false);
  const [buyers, setBuyers] = useState([]);

  const onSelectActive = (type) => {
    setRenderType(type);
  };

  const onPressAddNewBuyer = () => {
    setIsAddNew(true);
  };

  const onPressCancel = () => {
    setSelectedProduce([]);
    setOpenSelectBuyerModal(false);
    setOpenOfferDemandPostDialog(false);
    setValue("produces", []);
    setValue("companyName", "");
    setValue("email", "");
    setValue("selectedBuyer", {});
    setIsAddNew(false);
  };

  const onPressContinue = () => {
    setOpenOfferDemandPostDialog(true);
    setOpenSelectBuyerModal(false);
  };
  useEffect(() => {
    if (user?.id && user?.companyId) {
      const getBuyers = async () => {
        setLoading(true);
        const response = await getVerifiedBuyers();
        setLoading(false);

        if (response) {
          setBuyers(
            response?.items?.filter((item) => item?.id !== user?.companyId)
          );
        }
      };
      getBuyers();
    }
  }, [user?.id, user?.companyId]);

  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: 30, border: "1px solid #E77228" },
      }}
      open={openSelectBuyerModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {loading && (
        <LoadingIndicator
          loadingText={"Buyers loading, please wait..."}
          loading={loading}
        />
      )}
      <DialogContent>
        <span className={classes.modalHeader}>
          {isAddNew ? "Add new buyer" : "Do you have a buyer?"}
        </span>

        {!isAddNew ? (
          <div className={classes.yesNoBox}>
            <div
              onClick={() => onSelectActive("yes")}
              className={
                renderType === "yes" ? classes.activeBox : classes.inactiveBox
              }
            >
              YES
            </div>
            <div
              onClick={() => onSelectActive("no")}
              className={
                renderType === "no" ? classes.activeBox : classes.inactiveBox
              }
            >
              NO
            </div>
          </div>
        ) : null}
        {renderType === "yes" ? (
          <>
            {isAddNew ? (
              <div className="d-flex flex-column gap-3 pt-3 pb-2">
                <div className="col-6">
                  <FruProControllerTextField
                    InputProps={{
                      className: classes.Input,
                      disableUnderline: true,
                    }}
                    control={control}
                    name="companyName"
                    placeholder="Company Name"
                    label="Company Name"
                  />
                </div>
                <div className="col-6">
                  <FruProControllerTextField
                    InputProps={{
                      className: classes.Input,
                      disableUnderline: true,
                    }}
                    error={!!errors?.email}
                    helperText={errors?.email?.message}
                    control={control}
                    placeholder="Email"
                    name="email"
                    label="Email"
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-12 position-relative">
                    <FruproControllerComboBox
                      className={classes.customControlledField}
                      control={control}
                      InputLabelProps={{
                        style: { color: "#000000" },
                        shrink: true,
                      }}
                      multiple
                      name="selectedBuyer"
                      closeIcon={<></>}
                      options={buyers}
                      getOptionLabel={(option) => option?.companyName}
                      renderOption={(option, { selected }) => (
                        <>{option?.companyName}</>
                      )}
                      getOptionSelected={(option, value) =>
                        option?.id === value?.id
                      }
                      size={12}
                      placeholder="Search Buyer"
                      label={""}
                      disableUnderline={false}
                    />
                    <img
                      className={`position-absolute ${classes.searchIcon} `}
                      src="/static/svg/Search_light.svg"
                    />
                  </div>
                </div>
                <p onClick={onPressAddNewBuyer} className={classes.addNew}>
                  Add a new buyer
                </p>
              </>
            )}
          </>
        ) : null}
      </DialogContent>
      <div className="container pb-3">
        <div className="row">
          <div className="col-6">
            <Button onClick={onPressCancel} className={classes.closeButton}>
              Cancel
            </Button>
          </div>
          <div className="col-6">
            <Button
              disabled={
                renderType === "yes" && !isAddNew
                  ? !watchSelectedBuyer?.id
                  : renderType === "yes" && isAddNew
                  ? !watchCompanyName || !watchEmail
                  : false
              }
              onClick={onPressContinue}
              className={classes.shareButton}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SelectBuyerModal;
