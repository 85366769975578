import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";

import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import Gleap from "gleap";

const useStyles = makeStyles((theme) => ({
  navItem: {
    display: "flex",
    width: "100%",
    height: 55,
    alignItems: "center",
    minHeight: 36,
    color: "rgba(124, 124, 124, 1)",
    paddingLeft: 12,
    margin: "6px 0",
    "& img": {
      marginRight: 12,
    },
    "& .name": {
      fontSize: 15,
      fontWeight: 500,
    },
    "&:active, &:hover": {
      background: "rgba(231, 114, 40, 0.3)",
      color: "black",
      width: "100%",
      height: 55,
      borderRadius: 10,
      cursor: "pointer",
    },
    "&.disabled": {
      color: theme.palette.grey.label,
      background: "transparent",
    },
  },
}));

export default function NavItem({ item }) {
  const classes = useStyles();
  const router = useRouter();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(null);
  const [imageHovered, setImageHovered] = useState(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (
      router.pathname.includes(item.href) ||
      item.href?.includes(router.pathname) ||
      selected === item.href
    ) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [selected, router.pathname]);

  const getImgUrl = () => {
    if (imageHovered) {
      return imageHovered;
    } else if (isActive && item?.activeSrc) {
      return item.activeSrc;
    }

    return item.src;
  };

  const onClickNavItem = (e) => {
    if (item?.trigger) {
      Gleap.open();
    }else{
      router?.push(item?.href)
    }
  };



  return (
    <>
      <a style={{ textDecoration: "none" }} href={!item?.trigger && item?.href}>
        <li
          key={item.name}
          className={clsx(
            classes.navItem,
            item?.disabled ? "disabled" : null,
            isActive ? "active" : null
          )}
          onClick={onClickNavItem}
        >
          <img width="20" height="20" src={getImgUrl()} />
          <span className="name">{item.name}</span>
        </li>
      </a>
    </>
  );
}
